/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink  , NavLinkProps} from 'react-router-dom';
import clsx from 'clsx';
//import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@mui/styles';
import { List, ListItem, Button, colors, CustomTheme } from '@mui/material';
import {Pages} from 'types'

const useStyles = makeStyles<CustomTheme>(theme => createStyles({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette?.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette?.primary?.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette?.primary?.main
    }
  }
}));

const CustomRouterLink = forwardRef<HTMLDivElement , NavLinkProps>((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

interface PropsType<Pages>{
  pages:Pages[],
  className?:string,
  closeDrawer:()=>void

}

const SidebarNav = (props:PropsType<Pages>) => {
  const { pages, className,closeDrawer} = props;
  const classes = useStyles();

 

  return (
    <List
      className={clsx(classes.root, className)}
    >
      {pages.map(page => (
        <ListItem
          className={classes.item}
          disableGutters
          key={page.title}
        >
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            to={page.href}
            style={{justifyContent:'flex-start'}}
            onClick={closeDrawer}
          >
            <div className={classes.icon}>{page.icon}</div>
            {page.title}
          </Button>
        </ListItem>
      ))}
    </List>
  );
};


export default SidebarNav;
