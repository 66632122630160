//AppSync and Apollo libraries
import { ApolloProvider } from "@apollo/react-hooks";
import { ThemeProvider } from "@mui/material/styles";
import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import Amplify, { Auth, Storage } from "aws-amplify";
import { ConfirmSignIn, RequireNewPassword, SignIn, withAuthenticator } from "aws-amplify-react";
import { AuthOptions, createAuthLink } from "aws-appsync-auth-link";
import AppGlobalStyles from "global.styles";
import { createBrowserHistory } from "history";
import React, { Component } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import { default as AppSyncConfig } from "./aws-exports";
import { UserContextProvider } from "./components";
import Routes from "./Routes";
import theme from "./theme";

const url = AppSyncConfig.aws_appsync_graphqlEndpoint;
const region = AppSyncConfig.aws_appsync_region;

const auth: AuthOptions = {
  type: "AMAZON_COGNITO_USER_POOLS",
  jwtToken: async () => (await Auth.currentSession()).getAccessToken().getJwtToken()
};
const link = ApolloLink.from([createAuthLink({ url, region, auth }), createHttpLink({ uri: url })]);
const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

/* We eslablished connection to the "juliana" appSync API. So we will NOT manually connect the "development" appSync API anymore.
   commented on 05/Aug/2022

//API
const renAppConfig = {
  // ...
  'aws_appsync_graphqlEndpoint': 'https://eunoo7j2wjeatlotohf5ohnphe.appsync-api.us-east-2.amazonaws.com/graphql', // "development" appSync API
  'aws_appsync_region': 'us-east-2',
  'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS', // You have configured Auth with Amazon Cognito User Pool ID and Web Client Id
  // ...
}
//

Amplify.configure(renAppConfig);

//AUTH
Amplify.configure({
  Auth: {

      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: 'us-east-2:a968e01b-e820-4421-b838-a2320eb32694',
      
      // REQUIRED - Amazon Cognito Region
      region: 'us-east-2',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'us-east-2_hwckF9gQG',

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '5pbusuhd16avtn7rteidjigl41',
  }
});
//
*/

Amplify.configure(AppSyncConfig);
Storage.configure({ track: false, level: "private" });

const browserHistory = createBrowserHistory();

// Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
//   draw: chartjs.draw
// });
/*
validate.validators = {
  ...validate.validators,
  ...validators
};
*/


const customTheme= {button:
  {backgroundColor: theme?.palette?.primary.main,
  color: theme?.palette?.primary.contrastText}
};

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <UserContextProvider>
          <Router history={browserHistory}>
            <Routes />
            <AppGlobalStyles />
            <ToastContainer theme="colored" />
          </Router>
        </UserContextProvider>
      </ThemeProvider>
    );
  }
}

const AppWithAuth = withAuthenticator(App, false, [<SignIn />, <ConfirmSignIn />, <RequireNewPassword />], [], customTheme);

//eslint-disable-next-line
export default () => (
  <ApolloProvider client={client}>
    <AppWithAuth />
  </ApolloProvider>
);