
import React from 'react';
import { ResponsivePie } from '@nivo/pie'
//import clsx from 'clsx';
//import PropTypes from 'prop-types';
import { makeStyles, useTheme , createStyles } from '@mui/styles';

const useStyles = makeStyles(theme => createStyles({
    root: {
        width: "100%"
    },
    chartContainer: {
        position: 'relative'
    },
}));

const pieGraphSettings = {
    theme: {
    fontSize: '13px'
    },
    };
const StrengthMeter = props => {
    //eslint-disable-next-line
    const { className, limit, label, maxValue, animate } = props;
    const classes = useStyles();
    const theme = useTheme();

    const generateDataColorScheme = (limit,label,progress,maxValue) =>{
    
                return [{id:label,
                         //label: label + " Strength " + ": " + limit,
                         value:progress,
                         color: theme.palette.success.main
                         //color: progress <=30 ? theme.palette.error.main : [progress <=60 ? theme.palette.warning.main : theme.palette.success.main]

                        },
                        {id:"",
                            value:(maxValue-progress),
                            color: theme.palette.white
                           } ]
    
     }

    const [chartData, setChartData] = React.useState(generateDataColorScheme(limit,label))
    React.useEffect(() => {
        var initialVal = 0
        if(animate){
        const timer = setInterval(() => {
            if (initialVal < limit) {
                initialVal = initialVal + 1
                setChartData(generateDataColorScheme(limit,label,initialVal,maxValue))
            } else {
                clearInterval(timer);
            }
        }, 33);
        return () => {
            clearInterval(timer);
        };
        } else {
            setChartData(generateDataColorScheme(limit,label,limit,maxValue))
        }
   //eslint-disable-next-line
    }, [label, limit,animate]);
    
    return (
        <div className={classes.chartContainer} style={{height:props.height}}>
        <ResponsivePie
        data={chartData}
        //endAngle={Math.floor((progress/100) * 360)}
        colors = {chartData.map(c => c.color)}
        colorBy = "index"        
        margin={props.margin}
        innerRadius={0.6}
        enableRadialLabels={false}
        enableArcLinkLabels={false}
        radialLabelsLinkDiagonalLength={7}
        radialLabelsLinkHorizontalLength={7}
        tooltip={function(e){
            if(e.datum.id === ""){
                return null
            }
            //eslint-disable-next-line
            return "Signal" + ": " + e.datum.value + "%"
        
        }}
        borderWidth={1}
        //borderColor="#a9a9a9"
        borderColor="black"
        //padAngle={0.5}
        //cornerRadius={5}
        sliceLabel={function(e){
            if(e.id === ""){
                return null
            }
            return e.value +"%"}}
            legends= {props.legend}
        theme={pieGraphSettings.theme}
        //radialLabelsLinkStrokeWidth={3}
    />
{/*            <CircularProgressWithLabel variant="static" className = { progress <= 30? classes.circularProgressLow: [classes <= 60 ? classes.circularProgressMed : classes.circularProgressHigh]} value={progress} />
            <div className = { progress <= 30? classes.textProgressLow: [classes <= 60 ? classes.textProgressMed : classes.textProgressHigh]}>{progress}</div>
        </div>
        */}
    </div>

    );
};

StrengthMeter.defaultProps = {
    margin: { top: 30, right: 30, bottom: 90, left: 30 },
    legend: [
        {
            display:"false",
            anchor: 'bottom',
            direction: 'row',
            translateY: 56,
            translateX: 50,
            itemWidth: 100,
            itemHeight: 18,
            symbolSize: 18,
            symbolShape: 'circle',
            effects: [
                {
                    on: 'hover',
                }
            ],

            itemDirection:"top-to-bottom"
        }
    ],
    height: "300px",
    maxValue:100,
    animate: true
 };
export default StrengthMeter;
