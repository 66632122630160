import { makeStyles, createStyles } from "@mui/styles";
import { Button, Card, CardContent, CardHeader, Divider, Grid, Skeleton, Theme, Typography } from "@mui/material";
import React from "react";
import RelationshipCard from "../RelationshipCard";
import { RelationshipObject } from "types";

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      width: "100%"
    },
    contentWrapper: {
      textAlign: "center"
    },
    button: {
      color: theme.palette.info.main
    },
    prevWrapper: {
      textAlign: "left"
    },
    nextWrapper: {
      textAlign: "right"
    },
    nothingFoundText: {
      width: "100%",
      textAlign: "center",
      padding: "20px"
    },
    skeletonClass: {
      marginTop: "16px"
    }
  })
);

interface PropsTypes {
  cardData:RelationshipObject[]
  loading:boolean,
  errorMessage:string,
  cardTitle:string,
  handleChange:(action:"Next" | "Prev") => void,
  prevDisabled:boolean,
  nextDisabled:boolean,
}

function RelationshipWrapper(props:PropsTypes) {
  const { cardData, cardTitle, handleChange, prevDisabled, nextDisabled, loading, errorMessage } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card variant={"outlined"}>
        <CardHeader title={cardTitle} />
        <Divider />
        <CardContent className={classes.contentWrapper}>
          <Grid container spacing={2}>
            {loading ? (
              <Grid item xs={12}>
                <Skeleton variant="rectangular" width={"100%"} height={72} className={classes.skeletonClass} />
                <Skeleton variant="rectangular" width={"100%"} height={72} className={classes.skeletonClass} />
              </Grid>
            ) : (
              [
                !loading && cardData.length > 0 ? (
                  cardData.map(elem => {
                    return (
                      <Grid item xs={6} lg={4}>
                        <RelationshipCard relationshipName={elem.relationshipName ? elem.relationshipName : elem.relationshipURL} relationshipURL={elem.relationshipURL} relationshipStartDate={elem.relationshipStartDate} relationshipSource={elem.relationshipSource} />
                      </Grid>
                    );
                  })
                ) : (
                  <Typography variant="h4" className={classes.nothingFoundText}>
                    {errorMessage}
                  </Typography>
                )
              ]
            )}
            <Grid item xs={12}></Grid>
            <Grid item xs={6} className={classes.prevWrapper}>
              <Button variant="outlined" color="inherit" className={classes.button} disabled={prevDisabled} onClick={v => handleChange("Prev")}>
                Previous
              </Button>
            </Grid>
            <Grid item xs={6} className={classes.nextWrapper}>
              <Button variant="outlined" color="inherit" className={classes.button} disabled={nextDisabled} onClick={v => handleChange("Next")}>
                Next
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default RelationshipWrapper;
