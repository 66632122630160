/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPortfolio = /* GraphQL */ `
  mutation CreatePortfolio($input: CreatePortfolioInput!) {
    createPortfolio(input: $input) {
      result {
        companyname
        initialinvestment
        cost
        fmv
        lastround
        lastrounddate
        coinvestors {
          investor
          dealid
        }
        ceo {
          name
          linkedinUrl
        }
        companyurl
        uuid
      }
      count
      message
    }
  }
`;
export const updatePortfolio = /* GraphQL */ `
  mutation UpdatePortfolio($input: UpdatePortfolioInput!) {
    updatePortfolio(input: $input) {
      result {
        companyname
        initialinvestment
        cost
        fmv
        lastround
        lastrounddate
        coinvestors {
          investor
          dealid
        }
        ceo {
          name
          linkedinUrl
        }
        companyurl
        uuid
      }
      count
      message
    }
  }
`;
export const createCompanyFavorite = /* GraphQL */ `
  mutation CreateCompanyFavorite(
    $input: CreateCompanyFavoriteInput!
    $condition: ModelCompanyFavoriteConditionInput
  ) {
    createCompanyFavorite(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCompanyFavorite = /* GraphQL */ `
  mutation UpdateCompanyFavorite(
    $input: UpdateCompanyFavoriteInput!
    $condition: ModelCompanyFavoriteConditionInput
  ) {
    updateCompanyFavorite(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCompanyFavorite = /* GraphQL */ `
  mutation DeleteCompanyFavorite(
    $input: DeleteCompanyFavoriteInput!
    $condition: ModelCompanyFavoriteConditionInput
  ) {
    deleteCompanyFavorite(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSavedSearch = /* GraphQL */ `
  mutation CreateSavedSearch(
    $input: CreateSavedSearchInput!
    $condition: ModelSavedSearchConditionInput
  ) {
    createSavedSearch(input: $input, condition: $condition) {
      id
      name
      searchJSON
      lastViewed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSavedSearch = /* GraphQL */ `
  mutation UpdateSavedSearch(
    $input: UpdateSavedSearchInput!
    $condition: ModelSavedSearchConditionInput
  ) {
    updateSavedSearch(input: $input, condition: $condition) {
      id
      name
      searchJSON
      lastViewed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSavedSearch = /* GraphQL */ `
  mutation DeleteSavedSearch(
    $input: DeleteSavedSearchInput!
    $condition: ModelSavedSearchConditionInput
  ) {
    deleteSavedSearch(input: $input, condition: $condition) {
      id
      name
      searchJSON
      lastViewed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createWorkflow = /* GraphQL */ `
  mutation CreateWorkflow(
    $input: CreateWorkflowInput!
    $condition: ModelWorkflowConditionInput
  ) {
    createWorkflow(input: $input, condition: $condition) {
      id
      name
      chartJSON
      creationDate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateWorkflow = /* GraphQL */ `
  mutation UpdateWorkflow(
    $input: UpdateWorkflowInput!
    $condition: ModelWorkflowConditionInput
  ) {
    updateWorkflow(input: $input, condition: $condition) {
      id
      name
      chartJSON
      creationDate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteWorkflow = /* GraphQL */ `
  mutation DeleteWorkflow(
    $input: DeleteWorkflowInput!
    $condition: ModelWorkflowConditionInput
  ) {
    deleteWorkflow(input: $input, condition: $condition) {
      id
      name
      chartJSON
      creationDate
      createdAt
      updatedAt
      owner
    }
  }
`;
