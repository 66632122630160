import { Avatar, Theme, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import isEmpty from 'lodash/isEmpty';
import { useUserContextAPI } from "components/UserContext";
import React from "react";
import { Link as RouterLink } from "react-router-dom";


interface PropTypes {
  className?: string;
}

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minHeight: "fit-content"
    },
    avatar: {
      width: 60,
      height: 60
    },
    name: {
      marginTop: theme.spacing(1)
    },
    textColor: {
      color: "white",
      textAlign: "center"
    }
  })
);

const Profile = (props:PropTypes) => {
  const { className } = props;

  const classes = useStyles();
  const {
    values,
    avatar,
    // setAccountInfo,
    // setProfilePicture
  } = useUserContextAPI();

  return (
    <div className={clsx(classes.root, className)}>
      <Tooltip
        title={
          <div>
            <Typography
              className={`${classes.name} ${classes.textColor}`}
              variant="body1"
            >
              {(!isEmpty(values.firstName)?values.firstName:'') + " " + (!isEmpty(values.lastName)?values.lastName:'')}
            </Typography>
            <Typography variant="body2" className={classes.textColor}>
              {values.title}
            </Typography>
          </div>
        }
        aria-label="add"
        placement="right"
      >
        <Avatar
          alt="Person"
          className={classes.avatar}
          component={RouterLink}
          src={avatar}
          to="/account"
        />
      </Tooltip>
    </div>
  );
};

export default Profile;
