import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Link, Skeleton, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import gql from "graphql-tag";
import { companySearch } from "graphql/queries";
import {  SearchHelper } from "helpers";

const queryString = require("qs");

const useStyles = makeStyles(theme =>
  createStyles({
    linkColor: {
      color: theme.palette.info.main
    }
  })
);

// const transformSearchData = data => {
//   var outData = {};
//   outData["start"] = (parseInt(data.pageNum) - 1) * 25;
//   outData["NameContains"] = data["NameContains"] && data["NameContains"] != "" ? "*" + data["NameContains"] + "*" : "";
//   outData["Industries"] = data["Industries"];
//   outData["Location"] = data["Location"];
//   outData["LastUpdated"] = new Date(data["LastUpdated"]).getTime() / 1000;
//   outData["RecordCreated"] = data["RecordCreated"];
//   outData["totalFunding"] = data["totalFunding"];
//   outData["EmployeeCount"] = data["EmployeeCount"];
//   outData["Experience"] = data["Experience"].map(v => "*" + v + "*");
//   outData["Education"] = data["Education"].map(v => "*" + v + "*");
//   outData["DepartmentStrengthList"] = data["DepartmentStrengthList"].map(v => {
//     return { Seniority: ParseDepartmentandSeniority.getSeniorityFromDisplay(v.Seniority), Department: ParseDepartmentandSeniority.getIntFromDepartment(v.Department), SignalRange: v.SignalRange };
//   });
//   outData["DepartmentCountList"] = data["DepartmentCountList"].map(v => {
//     return { Seniority: ParseDepartmentandSeniority.getSeniorityFromDisplay(v.Seniority), Department: ParseDepartmentandSeniority.getIntFromDepartment(v.Department), EmployeeRange: v.EmployeeRange };
//   });
//   outData["DepartmentDeltaList"] = data["DepartmentDeltaList"].map(v => {
//     return { Seniority: ParseDepartmentandSeniority.getSeniorityFromDisplay(v.Seniority), Department: ParseDepartmentandSeniority.getIntFromDepartment(v.Department), SignalRange: [parseInt(v.SignalRange[0]) + parseInt(v.Average), parseInt(v.SignalRange[1]) + parseInt(v.Average)] };
//   });
//   outData["EmployeeTags"] = data["EmployeeTags"].map(v => {
//     return { Seniority: ParseDepartmentandSeniority.getSeniorityFromDisplay(v.Seniority), Department: ParseDepartmentandSeniority.getIntFromDepartment(v.Department), Tag: v.Tag, Type: v.Type };
//   });
//   outData["SortType"] = data["sortType"];
//   outData["onlyCount"] = true;
//   return outData;
// };

const handleSearchInput = locationString => {
  const searchInput = queryString.parse(locationString, { arrayFormat: "indices", strictNullHandling: true, ignoreQueryPrefix: true });
  if (searchInput.pageNum) searchInput.pageNum = parseInt(searchInput.pageNum);
  if (searchInput.DepartmentStrengthList) searchInput.pageNum = parseInt(searchInput.pageNum);
  // const defaultInput = {
  //   NameContains: "",
  //   Industries: [],
  //   EmployeeCount: [0, 100],
  //   Location: "",
  //   LastUpdated: null,
  //   Experience: [],
  //   Education: [],
  //   DepartmentStrengthList: [],
  //   DepartmentCountList: [],
  //   DepartmentDeltaList: [],
  //   EmployeeTags: [],
  //   pageNum: 1,
  //   sortType: "Score",
  //   RecordCreated: null
  // };
  if (searchInput.page) searchInput.page = parseInt(searchInput.page);
  searchInput.IncludeEmptyFounded = searchInput.IncludeEmptyFounded === "true";
  searchInput.isB2B = searchInput.isB2B === "true";
  searchInput.investorsActivity = searchInput.investorsActivity === "true";
  searchInput.advisorActivity = searchInput.advisorActivity === "true";

  return { ...SearchHelper.getDefaultInput(), ...searchInput };
};

const NewResultsText = props => {
  const classes = useStyles();
  const { className, searchString, lastViewed, ...rest } = props;
  var searchData = handleSearchInput(searchString);
  searchData["RecordCreated"] = new Date(parseInt(lastViewed) * 1000).toJSON().split("T");
  const {  loading, data } = useQuery(gql(companySearch), {
    variables: SearchHelper.transformSearchData(searchData, true)
  });
  
  return (
    <div>
      {!loading ? (
        <Link className={clsx(classes.linkColor, className)} {...rest}>
          {" "}
          {data.companySearch.recordCount} New Results
        </Link>
      ) : (
        <Typography {...rest}>
          <Skeleton />
        </Typography>
      )}
    </div>
  );
};

export default NewResultsText;
