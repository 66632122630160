import React from "react";
import { makeStyles, createStyles } from "@mui/styles";

import { Topbar } from "./components";

interface PropType {
  children: React.ReactNode;
  className?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingTop: 64,
      height: "100%"
    },
    content: {
      height: "100%"
    }
  })
);

const Minimal = (props:PropType) => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Topbar />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

export default Minimal;
