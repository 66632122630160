import { FormControl, Grid, MenuItem, Select, SelectChangeEvent, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { GoogleDocs } from "types";

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    width: "100%"
  },
  ratingCard: {
    textAlign: "center"
  },
  opaqueReveal: {
    background: "grey",
    height: "500px",
    textAlign: "center"
  },
  revealText: {
    marginTop: "230px",
    color: "white",
    fontSize: "20px"
  },
  iconStyle: {
    color: "white",
    marginBottom: "5px"
  },
  selectCard: {
    marginLeft: "24px",
    paddingLeft: "8px",
    paddingRight: "8px"
  },
  codaRoot: {
    width: "100%",
    height: "1000px",
    borderWidth: "0px"
  }
}));

interface PropType {
    data:GoogleDocs[]
}

function CompanyFiles(props:PropType) {
  const { data } = props;

  const classes = useStyles();

  const [state, setState] = React.useState({
    companyDriveId: data[0].driveid
  });

  const handleChange = (event:SelectChangeEvent) => {
    if (event.target.name === "companyDriveId") {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={8}>
          <Typography gutterBottom  variant="h4" component="h2">
            Company Files:
          </Typography>
        </Grid>

        <Grid item xs={6} md={2} className={classes.gridRight}>
          <FormControl className={classes.formControl}>
            <Select value={state.companyDriveId} onChange={handleChange} name="companyDriveId" className={classes.selectCard}>
              {data.map(elem => {
                return <MenuItem value={elem.driveid}>{elem.foldertitle}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          {/* eslint-disable-next-line */}
          <iframe sandbox="allow-scripts allow-same-origin allow-popups" className={classes.codaRoot} src={"https://drive.google.com/embeddedfolderview?id=" + state.companyDriveId + "#grid"}></iframe>
        </Grid>
      </Grid>
    </div>
  );
}

export default CompanyFiles;
