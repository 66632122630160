import { Grid, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import {  CompanyTableColumns, ObjectLiteral } from "types";

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary
    },
    boldHeading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 500
    },
    fullWidth: {
      width: "100%"
    }
  })
);

interface PropType<T> {
  data: T;
  headers: CompanyTableColumns<T>[];
  maxWidth?: number | string;
  boldHeader?: boolean;
}

const Header = <T extends ObjectLiteral>(props: PropType<T>) => {
  const classes = useStyles();
  //const leaf = (obj, path) => (path.split('.').reduce((value,el) => value[el], obj))

  const { data, headers, maxWidth, boldHeader } = props;
  const bodyWidth = !maxWidth ? 5 : 9;
  return (
    <div className={classes.fullWidth}>
      {headers
        .filter(record => {
          if (data[record.id] && data[record.id] !=="-" && data[record.id] !== "") {
            return true;
          }
          return false;
        })
        .map(header => {
          const value = data[header.id];
          return (
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography className={boldHeader ? classes.boldHeading : classes.secondaryHeading}>{header.name}</Typography>
              </Grid>
              <Grid item xs={bodyWidth}>
                <Typography>{header.format ? header.format(value) : value}</Typography>
              </Grid>
              {!maxWidth && ( <Grid item xs={4}> </Grid>)}
            </Grid>
          );
        })}
    </div>
  );
};

export default Header;
