/* eslint-disable */
import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React , {HTMLAttributes}from "react";
const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    topWrapper: {
      width: "100%",
      textAlign: "right"
    },
    gridNoMargin: {
      marginBottom: "0px",
      paddingBottom: "0px"
    },
    buttonSuccessStyle: {
      margin: theme.spacing(5),
      backgroundColor: theme.palette.success.main
    },
    linkText: {
      fontSize: theme.typography.pxToRem(15),
      marginBottom: "6px",
      color: theme.palette.info.main,
      display: "block"
    },
    wrapper: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      position: "relative"
    },
    buttonProgress: {
      color: theme.palette.success.main
    },
    contactInfo: {
      textAlign: "left",
      padding: "16px",
      marginTop: "8px"
    },
    resultText: {
      fontSize: "15px"
    },
    errorText: {
      fontSize: "15px",
      color: theme.palette.error.main,
      marginTop: "6px"
    },
    networkButton: {
      marginRight: theme.spacing(1)
    }
  })
);
const columns = [
  {
    id: "email",
    name: "Email Address"
  },
  {
    id: "social",
    name: "Social Links"
  }
];

interface PropTypes extends HTMLAttributes<HTMLDivElement>{
    className?:string,
    links:Object,
    linkedInName:string
}


const ContactInfoButton = (props:PropTypes) => {
  /*  const classes = useStyles();
    const { linkedInName, links, ...rest } = props

    const [getContactInfo, { error, loading, data }] = useLazyQuery(gql(contactInfo), {
        variables: {
            "LinkedInName": linkedInName
        },
    });
/*
    const data = {
        ContactInfo:{
        emails:[
            {email:"test@gmail.com", smtp_valid:"valid"},
            {email:"test@gmail.com", smtp_valid:"valid"},
            {email:"test@gmail.com", smtp_valid:"valid"},
        ],
        links:{
            "facebook2": "facebook.com",
            "twitter3": "twitter.com",
            "facebook4": "facebook2.com",
            "twitter6": "twitter3.com",
            "facebook6": "facebook4.com",
            "twitter3": "twitter5.com",
        }
    }
    }
    const loading = false

    const onClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        getContactInfo()

    }
    const handleEmailData = data => {
        var emails = []
        if (links.Emails) {
            for (const email of links.Emails) {
                emails.push(email)
            }
        }
        if(data.ContactInfo && data.ContactInfo.current_work_email){
            emails.push(data.ContactInfo.current_work_email)

        }
        if(data.ContactInfo && data.ContactInfo.current_personal_email){
            emails.push(data.ContactInfo.current_personal_email)

        }

        if(data.ContactInfo && data.ContactInfo.emails){
            for (const email of data.ContactInfo.emails){
                if(email.smtp_valid == 'valid'){
                    emails.push(email.email)
                } else if(email.score && parseInt(email.score) > 40){
                    emails.push(email.email)
                }
        
            }
        }
        emails = [...new Set(emails)]
        return emails.map(email => <Link className={classes.linkText} href={"mailto: " + email}>{email}</Link>)
    }
    const handleSocialData = data => {
        var socials = [
        ]
        if (links.SocialLinks) {
            for (const social of links.SocialLinks) {
                socials.push(social)
            }
        }
        if (links.Websites) {
            for (const social of links.Websites) {
                socials.push(social)
            }
        }
        if(data.ContactInfo && data.ContactInfo.links){
            for (const social of Object.values(data.ContactInfo.links)){
                if(social != "ContactLinks"){
                socials.push(social)
            }

            }
        }
        socials = [...new Set(socials)]
    return socials.map(social => <Link className={classes.linkText} href={social}>{social}</Link>)
}
var emailData = data ? handleEmailData(data) : []

var socialData = data ? handleSocialData(data) : []

return (
    <div className={classes.topWrapper}>

        <Grid container spacing={0} className={classes.gridNoMargin}>
            <Grid item xs={12}>
                <div className={classes.wrapper}>
                <Button
                        variant="contained"
                        color="inherit"
                        startIcon={<GroupAddIcon/>}
                        className={classes.networkButton}
                    >
                        Track Talent

        </Button>
                    <Button
                        variant="contained"
                        color="inherit"
                        startIcon={loading ? <CircularProgress size={20} className={classes.buttonProgress}/> : [ data ? <DoneIcon color="inherit" className={classes.buttonProgress} /> : <CloudDownloadIcon/>]}

                        disabled={loading || data}
                        onClick={onClick}
                    >
                        Get Contact Info

        </Button>
    
                {(error || (data && data.ContactInfo.message == "Search Failed")) && <Typography className={classes.errorText}>Oops, something went wrong. Please try again.</Typography>}
                </div>
                
            </Grid>
            {data && (emailData.length > 0 || socialData.length > 0)&& <Grid item xs={6} md={8}>

            </Grid>}
            {data && (emailData.length > 0 || socialData.length > 0)&& <Grid item xs={6} md={4}>
                <div className={classes.topWrapper}>
                    <Paper className={classes.contactInfo} elevation={2}>
                        <Details boldHeader={true} data={{ email: emailData, social: socialData }} headers={columns} maxWidth={true}></Details>

                    </Paper>
                </div>
            </Grid>}

        </Grid>
    </div>);*/
  return <div></div>;
};

export default ContactInfoButton;
