/* eslint-disable */
import { useQuery } from '@apollo/react-hooks';
import { Divider, Grid, Paper, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import {Theme} from '@mui/material/styles';
import gql from 'graphql-tag';
import { investorSearch } from 'graphql/queries';
import React, { useState } from 'react';
import { SearchBlock } from "./components";
import SideBarData from "./SideBarData";

const useStyles = makeStyles((theme:Theme) => createStyles({
  root: {
    width: '100%',
  },
  header: {
    padding: theme.spacing(2)
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  details: {
    alignItems: 'center',
    width: '100%',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));


type SearchProps = {
  searchData: any,
  setSearchData: any,
  onChange: Function,
  
}

const SearchSideBar = (props: SearchProps) => {
  const classes = useStyles();
  const { searchData, setSearchData, onChange} = props;
  const [investorString, setInvestorString] = useState("")
  const [leadInvestorString, setLeadInvestorString] = useState("")
  const { error: iError, loading: iLoading, data: iData } = useQuery(gql(investorSearch), {
    variables: {
      "InvestorName": investorString
    },
  });

  const { error: leadIError, loading: leadILoading, data: leadIData } = useQuery(gql(investorSearch), {
    variables: {
      "InvestorName": leadInvestorString
    },
  });
  var sideBarItems = JSON.parse(JSON.stringify(SideBarData.columns_basic))
  sideBarItems.push(
    {
      id: "LeadInvestorName",
      label: "Lead Investors",
      data: (leadIData?.InvestorSearch?.investorNames || []).map((elem: any) => {
        return elem.investor
      }),
      type: "MULTI",
      isMultiple: true,
      autoCompleteValue: leadInvestorString,
      onAutoCompleteChange: setLeadInvestorString

    },
    {
      id: "InvestorName",
      label: "Investors",
      data: (iData?.InvestorSearch?.investorNames || []).map((elem: any) => {
        return elem.investor
      }),
      type: "MULTI",
      isMultiple: true,
      autoCompleteValue: investorString,
      onAutoCompleteChange: setInvestorString

    }
  )

  return (
    <Paper className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.header} variant="h4" component="h2">
            Filter Search
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <SearchBlock onChange={onChange} isOpen={true} items={sideBarItems} title={"Overview"} values={searchData} />
        </Grid>
      </Grid>
    </Paper>
  );

}

export default SearchSideBar