import { Avatar, Card, CardActionArea, CardHeader, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { RelationshipObject } from "types";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: "100%"
    },
    avatar: {
      height: "auto",
      width: "100%",
      maxWidth: "100px"
    },
    iconWrapper: {
      width: "100%",
      textAlign: "right"
    },
    cardContentStyle: {
      paddingBottom: "0px !important",
      paddingTop: "0px !important"
    },
    smallAvatar: {
      height: "40px",
      width: "40px"
    }
  })
);

interface PropType extends RelationshipObject{}

function RelationshipCard(props:PropType) {
  const { relationshipName, relationshipURL, relationshipStartDate, relationshipSource} = props;

  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={2}>
      <CardActionArea href={relationshipSource??"/#"}>
        <CardHeader
          avatar={
            <Avatar alt="No Image" src={"//logo.clearbit.com/" + relationshipURL}>
              {<img className={classes.smallAvatar} src="/images/default-corporate-image.jpg" alt="default-corporate" />}
            </Avatar>
          }
          title={
            <Typography variant="h5" component="h2">
              {relationshipName}
            </Typography>
          }
          subheader={
            <div>
              <Typography variant="body2" color="textSecondary" component="p">
                {relationshipStartDate ? "Start Date: " + relationshipStartDate : ""}
              </Typography>
            </div>
          }
        />
      </CardActionArea>
    </Card>
  );
}

export default RelationshipCard;
