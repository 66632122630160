import { AutocompleteGetTagProps, useAutocomplete, AutocompleteChangeReason, AutocompleteInputChangeReason, AutocompleteChangeDetails } from "@mui/base/AutocompleteUnstyled";
import { CircularProgress, FormHelperText } from "@mui/material";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import * as React from "react";

const Root = styled("div")(
  ({ theme }) => `
  color: ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"};
  font-size: 14px;
  width:100%;
`
);

const Label = styled("label")`
  padding: 0 0 4px;
  line-height: 1.5;
  font-weight: 600;
  display: block;
`;

const InputWrapper = styled("div")(
  ({ theme }) => `
  width: 100%;
  border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  border-radius: 4px;
  padding: 3px;
  padding-bottom:0;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
  }

  &.focused {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    color: ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"};
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

// interface TagProps extends ReturnType<AutocompleteGetTagProps> {
//   label: string;
// }

const Listbox = styled("ul")(
  ({ theme }) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 999;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

interface AutoCompleteTextProps<T> {
  id?:string,
  label?: string;
  options: T[];
  defaultValue?: T[],
  value?:T[],
  renderTag: (option: T, index: number, getTagProps: AutocompleteGetTagProps) => React.ReactNode;
  renderDisplayListItem: (option: T, index: number, selected?: boolean) => React.ReactNode;
  getOptionLabel: (option: T) => string;
  onChange?: (event: React.SyntheticEvent<Element, Event>, value: T[], reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<T>) => void;
  onInputChange?: (event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => void;
  loading?: boolean;
  error?: boolean;
  helperText?: string;
}

export default function AutoCompleteInputFromHook<T>({id, value:inheritVal, error, helperText, options, renderTag, renderDisplayListItem, getOptionLabel, onChange, label, defaultValue, onInputChange, loading }: AutoCompleteTextProps<T>) {
  if (!Array.isArray(options)) {
    options = [];
  }

  const { getRootProps, getInputLabelProps, getInputProps, getTagProps, getListboxProps, getOptionProps, groupedOptions, value, focused, setAnchorEl } = useAutocomplete({
    id: id??"customized-hook-demo",
    defaultValue,
    value:inheritVal,
    multiple: true,
    options,
    getOptionLabel,
    onChange,
    onInputChange
  });

  return (
    <Root>
      <div {...getRootProps()}>
        <Label {...getInputLabelProps()} style={{ color: error ? "red" : "inherit" }}>
          {label}
        </Label>
        <InputWrapper ref={setAnchorEl} className={focused ? "focused" : ""}>
          {Array.isArray(value) && value.map((option, index) => renderTag(option, index, getTagProps))}
          <input {...getInputProps()} />
        </InputWrapper>
        {helperText && <FormHelperText style={{ color: error ? "red" : "inherit" }}>{helperText}</FormHelperText>}
      </div>
      {loading && (
        <Listbox {...getListboxProps()}>
          <CircularProgress size={20} />
        </Listbox>
      )}
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {(groupedOptions as T[]).map((option, index) => (
            <li key={`listItem-${index}`} {...getOptionProps({ option, index })}>
              {renderDisplayListItem(option, index, defaultValue?.includes(option))}
            </li>
          ))}
        </Listbox>
      ) : null}
    </Root>
  );
}
