/* eslint-disable */
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { Avatar, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from "@mui/styles";
import ShowMoreText from 'components/ShowMoreText';
import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import {Theme} from '@mui/material/styles';

const useStyles = makeStyles((theme:Theme) => createStyles({
    titleHeading: {
        fontSize: theme.typography.pxToRem(15),
        marginBottom: "8px"
    },
    iconTitleHeading: {
        color: theme.palette.text.secondary,
        marginBottom: "8px"
    },
    textIconWrapper: {
        display: "inline-block",
        marginRight: "30px"
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15)
    },
    fullWidth: {
        "width": "100%"
    },
    paperRoot: {
        width: "100%",
        marginBottom: theme.spacing(2),
        textAlign: "center"

    },
    linkText: {
        fontSize: theme.typography.pxToRem(14),
        paddingRight: "5px",
        color: theme.palette.info.main
    },
    chipStyle: {
        fontSize: theme.typography.pxToRem(14),
        marginRight: "5px",
        marginBottom: "5px",
    },
    largeAvatar: {
        width: "150px",
        height: "150px",
        display: "inline-block"
    },
    iconClass: {
        paddingRight: "5px",
        color: theme.palette.text.secondary
    },
    displayRight: {
        textAlign: "center"
    },
    tagSeperator:{
        marginTop:"8px"
    },
    placeholder:{
        height:"150px"
    },
    placeholdertext:{
        width:"100%",
        textAlign:"center",
        paddingTop:"60px",
        fontSize:"14px"
    }
}));

const EmployeeHeader = (props:any) => {
    const { data } = props;
    const classes = useStyles();

    // const generateLink = (searchString:any) =>{
    //     const SearchLink = (props:any) => <ReactLink to={{
    //         pathname: "/search",
    //         search: searchString
    //     }} {...props} />
    //     return SearchLink
    // }
    /*
    var employeeTags = parsedTags.length > 0 ? <div className={classes.tagSeperator}><ShowMore isInline={true} data={parsedTags.map((value) => {
        return (
            <Chip
                label={value.display}
                className={classes.chipStyle}
                component={generateLink(value.queryString)}
                clickable
            /> 
        )
    })} trimIndex={4}></ShowMore> </div> : ""*/

    return (
        <div className={classes.fullWidth}>
            <Grid container spacing={2}>
                <Grid item xs={3} md={2} className={classes.displayRight}>
                    <Avatar alt="No Image" className={classes.largeAvatar} src={data.profilepicture && data.profilepicture !== "-" ? data.profilepicture : "/images/avatars/NoProfile.png" } >
                    {<img className={classes.largeAvatar} src="/images/avatars/NoProfile.png" alt="NoProfile" />}
                    </Avatar>
                </Grid>

                <Grid item xs={9} md={6} >
                {data.personname && data.personname !== "-" ?  <Typography gutterBottom variant="h4" component="h2">
                        {data.personname}
                    </Typography> : ""
                }
                    {data.liheadline && data.liheadline !== "-" ? <Typography className={classes.titleHeading}>
                        {data.liheadline}
            </Typography> : ""}
                    <Typography className={classes.iconTitleHeading}>
                    {data.profilelocation && data.profilelocation !== "-" ? <div className={classes.textIconWrapper}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <LocationCityIcon className={classes.iconClass} />
                                {data.profilelocation}
                            </div>
                        </div> : "" }
                    </Typography>
                    <Typography className={classes.secondaryHeading}>

                    {data.fulltext && data.fulltext !== "-" ? <ShowMoreText data={data.fulltext} trimIndex={200} ></ShowMoreText> : ""}
                    </Typography>
                    {/*employeeTags*/}
                </Grid>
                <Grid item xs={12} md={4}>
            <Card>
            <CardHeader
                title="Profile Signal Score"
            />
            <Divider />
            <CardContent>
                <div className={classes.placeholder}>
                <Typography className={classes.placeholdertext}>Coming soon...</Typography>
                </div>
               {/* <StrengthMeter height="150px" margin={{ top: 10, right: 10, bottom: 10, left: 10 }} legend={[]} limit={(1)} label="Coming soon..."></StrengthMeter> */}
            </CardContent>
            </Card>
                </Grid>
                <Grid item md={2}>
                </Grid>
                {/*<Grid item xs={12} md={10}>
                {employeeTags}
              </Grid>   */}           
            </Grid>
        </div>
    );

};

export default EmployeeHeader;
