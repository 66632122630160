import React, { useState } from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { Grid, Link, Theme } from "@mui/material";

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      width: "100%"
    },
    linkText: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.info.main,
      marginLeft: "5px"
    }
  })
);

interface PropTypes {
  data: string;
  trimIndex: number;
  isInline?: boolean;
}

function ShowMoreText(props: PropTypes) {
  const { data, trimIndex } = props;
  const classes = useStyles();
  const [shown, setShown] = useState(false);
  const shortData = data.length > trimIndex ? data.substring(0, trimIndex) : data;

  const handleClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setShown(!shown);
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {!shown ? shortData : data}
          
          {data.length > trimIndex && (
            <Link href="#" className={classes.linkText} onClick={handleClick} variant="body2">
              {shown ? "Show Less" : "Show More"}
            </Link>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default ShowMoreText;
