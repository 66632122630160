import { useQuery } from "@apollo/react-hooks";
import { Grid, Paper, Skeleton, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import gql from "graphql-tag";
import { personUUIDQuery } from "graphql/queries";
import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { EmployeeData } from "types";
import Awards from "./components/Awards";
import Certifications from "./components/Certifications";
import ContactInfoButton from "./components/ContactInfoButton";
import Education from "./components/Education";
import EmployeeHeader from "./components/EmployeeHeader";
import Jobs from "./components/Jobs";
import Languages from "./components/Languages";
import Patents from "./components/Patents";
import Projects from "./components/Projects";
import Publications from "./components/Publications";
import Skills from "./components/Skills";
import Volunteer from "./components/Volunteer";

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      width: "100%"
    },
    content: {
      marginBottom: theme.spacing(2)
    },
    rootSpinner: {
      left: "50%",
      position: "fixed"
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`
    },
    topWrapper: {
      width: "100%",
      textAlign: "right"
    },
    skeletonStyle: {
      margin: theme.spacing(5)
    },
    bottomSkeleton: {
      margin: theme.spacing(5)
    }
  })
);

const Employee = () => {
  const classes = useStyles();
  const { employeeID } = useParams<{ employeeID: string }>();
  // const { params: { companyID, employeeID } } = match;

  const { loading, data } = useQuery<{ PersonUUIDQuery: EmployeeData }>(gql(personUUIDQuery), {
    variables: {
      LinkedInUUID: employeeID
    }
  });
  if ((!loading && data === undefined) || (!loading && data?.PersonUUIDQuery.employeeuuid == null)) {
    return <Redirect to="/not-found" />;
  }
  return (
    <div className={classes.root}>
      {loading ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div>
              <Skeleton variant="text" className={classes.skeletonStyle} />
              <Skeleton variant="circular" width={150} height={150} className={classes.skeletonStyle} />
              <Skeleton variant="rectangular" height={300} className={classes.skeletonStyle} />
            </div>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {data?.PersonUUIDQuery.linkedinurl && <ContactInfoButton links={{}} linkedInName={data?.PersonUUIDQuery.linkedinurl}></ContactInfoButton>}
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.root}>{!loading && data?.PersonUUIDQuery && <EmployeeHeader data={data.PersonUUIDQuery}></EmployeeHeader>}</Paper>
          </Grid>
          
          {!loading && data?.PersonUUIDQuery.jobs && data?.PersonUUIDQuery.jobs.length > 0 && (
            <Grid item xs={8}>
              <Paper className={classes.root}>
                <Jobs data={data?.PersonUUIDQuery}></Jobs>
              </Paper>
            </Grid>
          )}

          {!loading && data?.PersonUUIDQuery.ed && data?.PersonUUIDQuery.ed.length > 0 && (
            <Grid item xs={8}>
              <Paper className={classes.root}>
                <Education data={data?.PersonUUIDQuery}></Education>
              </Paper>
            </Grid>
          )}

          {!loading && data?.PersonUUIDQuery.volunteer && data?.PersonUUIDQuery.volunteer.length > 0 && (
            <Grid item xs={8}>
              <Paper className={classes.root}>
                <Volunteer data={data?.PersonUUIDQuery}></Volunteer>
              </Paper>
            </Grid>
          )}

          {!loading && data?.PersonUUIDQuery.certifications && data?.PersonUUIDQuery.certifications.length > 0 && (
            <Grid item xs={8}>
              <Paper className={classes.root}>{!loading && <Certifications data={data?.PersonUUIDQuery}></Certifications>}</Paper>
            </Grid>
          )}

          {!loading && data?.PersonUUIDQuery.awards && data?.PersonUUIDQuery.awards.length > 0 && (
            <Grid item xs={8}>
              <Paper className={classes.root}>{!loading && <Awards data={data?.PersonUUIDQuery}></Awards>}</Paper>
            </Grid>
          )}

          {!loading && data?.PersonUUIDQuery.languages && data?.PersonUUIDQuery.languages.length > 0 && (
            <Grid item xs={8}>
              <Paper className={classes.root}>{!loading && <Languages data={data?.PersonUUIDQuery}></Languages>}</Paper>
            </Grid>
          )}

          {!loading && data?.PersonUUIDQuery.patents && data?.PersonUUIDQuery.patents.length > 0 && (
            <Grid item xs={8}>
              <Paper className={classes.root}>{!loading && <Patents data={data?.PersonUUIDQuery}></Patents>}</Paper>
            </Grid>
          )}

          {!loading && data?.PersonUUIDQuery.projects && data?.PersonUUIDQuery.projects.length > 0 && (
            <Grid item xs={8}>
              <Paper className={classes.root}>{!loading && <Projects data={data?.PersonUUIDQuery}></Projects>}</Paper>
            </Grid>
          )}

          {!loading && data?.PersonUUIDQuery.publications && data?.PersonUUIDQuery.publications.length > 0 && (
            <Grid item xs={8}>
              <Paper className={classes.root}>{!loading && <Publications data={data?.PersonUUIDQuery}></Publications>}</Paper>
            </Grid>
          )}

          {!loading && data?.PersonUUIDQuery.skills && data?.PersonUUIDQuery.skills.length > 0 && (
            <Grid item xs={8}>
              <Paper className={classes.root}>{!loading && <Skills data={data?.PersonUUIDQuery}></Skills>}</Paper>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};

export default Employee;
