import AssessmentTwoToneIcon from "@mui/icons-material/AssessmentTwoTone";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import { Box, Card, Divider, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridEventListener, GridRowModesModel } from "@mui/x-data-grid";
import React from "react";

interface WeeklyProps<T> {
  title?: string;
  columns: GridColDef[];
  rows: T[];
  icon?: string;
  loading?: boolean;
  totalCount: number;
  fetchDataForPage?: Function;
  headingAction?: React.ReactNode;
  onRowEditCommit?: (newRow: any) => void;
  onRowEditStop?: GridEventListener<"rowEditStop">;
  processRowUpdate?: (newRow: any, oldRow: any) => void;
  onProcessRowUpdateError?: (error: any) => void;
}

export const WeeklyLeads = <T extends object>({ title, columns, rows, icon, loading, fetchDataForPage, totalCount, headingAction, onRowEditCommit, onRowEditStop, onProcessRowUpdateError, processRowUpdate }: WeeklyProps<T>) => {
  const [pageState, setPageState] = React.useState<{
    isLoading: boolean;
    data: T[];
    total: number;
    page: number;
    pageSize: number;
  }>({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10
  });

  React.useEffect(() => {
    setPageState(old => ({
      ...old,
      data: [...rows]
    }));
  }, [rows]);

  React.useEffect(() => {
    setPageState(old => ({
      ...old,
      total: totalCount
    }));
  }, [totalCount]);

  React.useEffect(() => {
    setPageState(old => ({
      ...old,
      isLoading: Boolean(loading)
    }));
  }, [loading]);

  React.useEffect(() => {
    if (fetchDataForPage) {
      const { page, pageSize } = pageState;

      fetchDataForPage({
        variables: {
          method: "GET",
          limit: pageSize,
          offset: page === 1 ? 0 : (page - 1) * pageSize
        }
      });
    }
    //eslint-disable-next-line
  }, [pageState.page, pageState.pageSize]);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  return (
    <Box
      sx={{
        marginBottom: "30px"
        // width: columns.reduce((lw, d) => lw + (d.width ?? 0), columns.length + 10)
      }}
      component={Card}
      elevation={1}
    >
      <Stack direction={"row"} alignItems="center" spacing={1} sx={{ padding: "7px 8px" }} justifyContent="space-between">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {icon === "portfolio" ? <NotificationImportantIcon fontSize="large" /> : <AssessmentTwoToneIcon fontSize="large" />}
          <Typography variant="h5">{title}</Typography>
        </Box>
        {headingAction ? <Box>{headingAction}</Box> : null}
      </Stack>
      <Divider />
      <Box sx={{ height: pageState.data.length > 0 ? 700 : 200}}>
        <DataGrid
          getRowHeight={() => "auto"}
          density="standard"
          rows={pageState.data}
          rowCount={pageState.total}
          columns={columns}
          loading={pageState.isLoading}
          rowsPerPageOptions={[10, 20, 50, 100]}
          pagination
          // onRowEditCommit={handleRowEditCommit}
          // onRowEditStop={onRowEditStop}
          page={pageState.page - 1}
          experimentalFeatures={{ newEditingApi: true }}
          // rowModesModel={{ 1: { mode: GridRowModes.Edit } }}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={onProcessRowUpdateError}
          editMode="row"
          pageSize={pageState.pageSize}
          rowModesModel={rowModesModel}
          onRowModesModelChange={newModel => setRowModesModel(newModel)}
          paginationMode="server"
          // components={{
          //   Toolbar: GridToolbar,
          // }}
          onPageChange={newPage => {
            setPageState(old => ({ ...old, page: newPage + 1 }));
          }}
          onPageSizeChange={newPageSize => setPageState(old => ({ ...old, pageSize: newPageSize }))}
          sx={{
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "15px" },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": { py: "22px" }
          }}
        />
      </Box>
    </Box>
  );
};
