import { makeStyles  , createStyles } from '@mui/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Theme} from '@mui/material/styles';
import {SelectChangeEvent} from '@mui/material';
import { Avatar, Card, CardActionArea, CardContent, CardHeader, Grid, IconButton, Link, Tooltip, Typography } from '@mui/material';
import ShowMoreText from 'components/ShowMoreText';
import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
//import { type } from 'os';

const useStyles = makeStyles((theme:Theme) => createStyles({
    rootCell: {
        maxHeight: "500px"
    },
    root: {
        width: "100%",
    },
    iconWrapper: {
        width: "100%",
        textAlign: "right"
    },
    cardHeader: {
        alignItems: "end",
        paddingBottom: "5px"
    },
    cardContentStyle: {
        paddingBottom: "0px !important",
        paddingTop: "0px !important",

    },
    avatar: {
        width: "75px",
        height: "75px",
        display: "inline-block"
    },
    indentRow: {
        paddingLeft: "91px"
    },
    typeSize: {
        fontSize: "15px"
    },
    textSpacingWrap: {
        whiteSpace: "pre-wrap"
    },
    displayRight:{
        textAlign:"center"
    },
    displayRightArrow:{
        textAlign:"center",
        marginTop:"13px"
    },

    chipStyle: {
        fontSize: theme.typography.pxToRem(14),
        marginRight: "5px",
        marginBottom: "5px",
        color: theme.palette.info.main,
        borderColor: theme.palette.info.main
        //backgroundColor: theme.palette.info.main,
        //color: theme.palette.white
    },
    chipTopWrapper: {
        marginTop: "5px"
    },
    pchip: {
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        textAlign: "center"
    },
    largeAvatar: {
        width: "50px",
        height: "50px",
        display: "inline-block"
    },
}));

type investorimagesType = {
    Chloe:string,
    Roseanne:string,
    Renata:string,
    Susan:string,
    [key: string]: string
}

var investor_images:investorimagesType= {
    "Chloe": "https://images.squarespace-cdn.com/content/v1/5f0daeebae325e52eef4cd1a/1596809181083-43YJM5DLA99UECZECKI9/ke17ZwdGBToddI8pDm48kDs6OgM01FuhQBqjgXla4ql7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QHyNOqBUUEtDDsRWrJLTmDJyaVitQ06bkWUY0OMxkmJUILIYtQaXPDtzxwPwitM3syB85qExdRa99CxJxkDNY/Artboard%2B1%2Bcopy%2B3.jpg?format=1500w",
    "Roseanne": "https://images.squarespace-cdn.com/content/v1/5f0daeebae325e52eef4cd1a/1596745975287-Q4W15WAW9Z46P2MMFOVU/ke17ZwdGBToddI8pDm48kKWebp3cCkqjElJlDI8NzLx7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UWIN3MUu6ucv27MaZKEhDtOTNKodSb7ZWWjWTtIRiDQ8p4g8dkz8m9jd1c_lmLz9eg/Artboard+1+copy.jpg?format=1500w",
    "Renata": "https://images.squarespace-cdn.com/content/v1/5f0daeebae325e52eef4cd1a/1596742772761-A7424F5Q2IJWKPY32M3Y/ke17ZwdGBToddI8pDm48kKWebp3cCkqjElJlDI8NzLx7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UWIN3MUu6ucv27MaZKEhDtOTNKodSb7ZWWjWTtIRiDQ8p4g8dkz8m9jd1c_lmLz9eg/Artboard+1.jpg?format=1500w",
    "Susan": "https://images.squarespace-cdn.com/content/v1/5f0daeebae325e52eef4cd1a/1596742964922-P4WJJW70M7GC33BKTTNU/ke17ZwdGBToddI8pDm48kKWebp3cCkqjElJlDI8NzLx7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UWIN3MUu6ucv27MaZKEhDtOTNKodSb7ZWWjWTtIRiDQ8p4g8dkz8m9jd1c_lmLz9eg/Artboard+1+copy+2.jpg?format=1500w",
}
type companyCardProps = {
companyID:string,
companyImage:string,
companyIndustries:any,
companyName:string,
description:string,
introPointCompany:string,
introPointID:string,
introPointInternal:string,
introPointPicture:string,
introPointTitle:string,
seriesmerged:string,
totalfunding:string,
}
const CompanyCard = (props:companyCardProps) => {
    const { companyName, companyImage, description, companyID,  seriesmerged, totalfunding, introPointInternal, introPointCompany, introPointTitle, introPointPicture } = props;
    const classes = useStyles();
    const onCardClick = (event:SelectChangeEvent) => {
        event.preventDefault()
        event.stopPropagation();
    }
    const CustomLink = (props:any) => <ReactLink to={"/companies/" + companyID} {...props} />;
    // const CustomPersonLink = (props:any) => <ReactLink to={"/employees/" + introPointID} {...props} />;

    return (
        <Card className={classes.root} elevation={2} square={true}>
            <CardActionArea component={CustomLink}>

                <CardHeader className={classes.cardHeader} avatar={<Avatar className={classes.avatar} src={companyImage} >
                    <img className={classes.avatar} src="/images/default-corporate-image.jpg" alt='corporate'/>
                </Avatar>
                } title={<Typography variant="h5" component="h2">
                    <Link color="inherit"
                    >{companyName}</Link>
                </Typography>} subheader={<div>
                    {(seriesmerged && seriesmerged !== "") && <Typography variant="body2" color="textSecondary" component="p">
                        {"Next Series : " + seriesmerged}
                    </Typography>}

                    {(totalfunding) && <Typography variant="body2" color="textSecondary" component="p" className={classes.textSpacingWrap}>
                        Total Funding  :   $  {totalfunding}   million
                    </Typography>}
                </div>} />

                <Grid container spacing={1}>
                    <Grid item xs={12} className={classes.indentRow}>
                        <CardContent className={classes.cardContentStyle}>

                            {description  !== "-" ? <Typography><ShowMoreText data={description} trimIndex={300}></ShowMoreText> </Typography> : ""}
                        </CardContent>
                    </Grid>
                    { introPointInternal && introPointCompany ? <Grid item xs={2} >
                       </Grid> : ""}
                    { introPointInternal && introPointCompany ? <Grid item xs={2} className={classes.displayRight}>
                        <CardContent className={classes.cardContentStyle}>

                    <Tooltip title={introPointInternal} arrow>
                            <Avatar alt="No Image" className={classes.largeAvatar} src={investor_images[introPointInternal] ? investor_images[introPointInternal] : "/images/avatars/NoProfile.png"} >
                                {<img className={classes.largeAvatar} src="/images/avatars/NoProfile.png" alt='no-profile'/>}
                            </Avatar>
                            </Tooltip>
                        </CardContent>

                    </Grid> : ""}
                    { introPointInternal && introPointCompany ? <Grid item xs={2} className={classes.displayRightArrow}>
                        <CardContent className={classes.cardContentStyle}>

                            <ArrowForwardIcon/>

                        </CardContent>

                    </Grid> : ""}
                    { introPointInternal && introPointCompany ? <Grid item xs={4} className={classes.displayRight}>
                        <CardContent className={classes.cardContentStyle}>
                            <Avatar alt="No Image" className={classes.largeAvatar} src={introPointPicture ? introPointPicture : "/images/avatars/NoProfile.png"} >
                                {<img className={classes.largeAvatar} src="/images/avatars/NoProfile.png" alt='no-profile'/>}
                            </Avatar>
                        <Typography variant="body2" color="textSecondary" component="p" >{introPointCompany}</Typography>
                        <Typography variant="body2" color="textSecondary" component="p" >{introPointTitle}</Typography>
                        </CardContent>

                    </Grid> : ""}

                    <Grid item xs={6} >

                        {/* <FavoriteButton isFavorite={isFavorite} userID={userID} id={companyID}></FavoriteButton> */}
                    </Grid>
                    <Grid item xs={6} >
                        <div className={classes.iconWrapper}>
                            <IconButton onClick={(e:any)=>onCardClick(e)}>

                                <NavigateNextIcon />
                            </IconButton>
                        </div>
                    </Grid>

                </Grid>

            </CardActionArea>

        </Card>
    );
};
export default CompanyCard;
