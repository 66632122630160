/* eslint-disable */
import { useQuery } from "@apollo/react-hooks";
import { Card, CardContent, CardHeader, Divider, Grid, Rating, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import gql from "graphql-tag";
import { growthDataQuery } from "graphql/queries";
import { HandleGrowth } from "helpers";
import React from "react";
import { CompanyData, GrowthDataType } from "types";
import GrowthIndexGraph from "../GrowthIndexGraph";
import GrowthTable from "../GrowthTable";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: "100%"
    },
    ratingCard: {
      textAlign: "center"
    },
    opaqueReveal: {
      background: "grey",
      height: "500px",
      textAlign: "center"
    },
    revealText: {
      marginTop: "230px",
      color: "white",
      fontSize: "20px"
    },
    iconStyle: {
      color: "white",
      marginBottom: "5px"
    },
    selectCard: {
      marginLeft: "24px",
      paddingLeft: "8px",
      paddingRight: "8px"
    }
  })
);

// const formatHeadcount = (headCounts: any, industry: any) => {
//   var monthStart = new Date();
//   monthStart.setDate(1);
//   monthStart.setHours(0);
//   monthStart.setMinutes(0);
//   monthStart.setSeconds(0);
//   monthStart.setMilliseconds(0);
//   for (const headCount of headCounts) {
//     if (headCount.industryname == industry) {
//       const outList:any = [];
//       const trimList = headCount.headcountdata.slice(0, 12);
//       for (var i = 0; i < trimList.length; i++) {
//         const dateOld = new Date(monthStart.getTime());
//         dateOld.setMonth(dateOld.getMonth() - i);
//         outList.push({
//           x: dateOld.toISOString().replace(/T.*/, ""),
//           y: trimList[i]
//         });
//       }
//       outList.reverse();
//       return [
//         {
//           id: industry,
//           data: outList
//         }
//       ];
//     }
//   }
// };

const aggTraffic = (data: any) => {
  var outObj:any = [];
  var monthStart = new Date();
  monthStart.setDate(1);
  monthStart.setHours(0);
  monthStart.setMinutes(0);
  monthStart.setSeconds(0);
  monthStart.setMilliseconds(0);
  var semLength = data.semtraffic ? data.semtraffic.length : 0;
  var swLength = data.swtraffic ? data.swtraffic.length : 0;
  var ahstraffic = data.ahstraffic ? data.ahstraffic.length : 0;

  const maxLength = Math.min(Math.max(semLength, swLength, ahstraffic), 12);
  if (data.semtraffic) {
    const outList:any = [];
    for (var i = 0; i < maxLength; i++) {
      const dateOld = new Date(monthStart.getTime());
      dateOld.setMonth(dateOld.getMonth() - i);
      const timeVaue: any = data.semtraffic.length > i ? parseFloat(data.semtraffic[i]) : null;

      outList.push({
        x: dateOld.toISOString().replace(/T.*/, ""),
        y: Math.round(timeVaue)
      });
      
    }
    outList.reverse();
    outObj.push({ id: "Web Traffic 1", data: outList });
  }
  if (data.swtraffic) {
    const outList:any = [];
    for (var i = 0; i < maxLength; i++) {
      const dateOld = new Date(monthStart.getTime());
      dateOld.setMonth(dateOld.getMonth() - i);
      const timeVaue: any = data.swtraffic.length > i ? parseFloat(data.swtraffic[i]) : null;

      outList.push({
        x: dateOld.toISOString().replace(/T.*/, ""),
        y: Math.round(timeVaue)
      });
    }
    outList.reverse();
    outObj.push({ id: "Web Traffic 2", data: outList });
  }
  if (data.ahstraffic) {
    const outList:any = [];
    for (var i = 0; i < maxLength; i++) {
      const dateOld = new Date(monthStart.getTime());
      dateOld.setMonth(dateOld.getMonth() - i);
      const timeVaue: any = data.ahstraffic.length > i ? parseFloat(data.ahstraffic[i]) : null;
      outList.push({
        x: dateOld.toISOString().replace(/T.*/, ""),
        y: Math.round(timeVaue)
      });
    }
    outList.reverse();
    outObj.push({ id: "Web Traffic 3", data: outList });
  }
  return outObj;
};

interface GrowthOverviewProps {
  data: CompanyData;
}

function GrowthOverview(props: GrowthOverviewProps) {
  const { data } = props;

  const [state, setState] = React.useState({
    headcountType: "All"
  });

  const { error: pageError, loading: pageLoading, data: pageData } = useQuery<{GrowthDataQuery:GrowthDataType}>(gql(growthDataQuery), {
    variables: {
      CompanyUUID: data.uuid,
      QueryType: "pages"
    }
  });

  const pageDataOut = pageData && pageData.GrowthDataQuery.pages ? HandleGrowth.parsePageType(pageData.GrowthDataQuery.pages) : null;

  const classes = useStyles();

  const handleChange = (event: any) => {
    if (event.target.name === "headcountType") {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };
  const traffic = data ? aggTraffic(data) : null;
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h4" component="h2">
            Growth Overview
          </Typography>
        </Grid>

        {data && data.growthpercentile6evm !== null && (
          <Grid item xs={6}>
            <GrowthTable data={[data]} headers={HandleGrowth.webPercentileHeader} parentName={"Renegade Web Index"} />
          </Grid>
        )}

        {data && data.reviewgrowthpercentile3 != null && (
          <Grid item xs={6}>
            <GrowthTable data={[data]} headers={HandleGrowth.reviewPercentileHeader} parentName={"Review Growth Percentile"} />
          </Grid>
        )}
        {data && data.cihcg != null && (
          <Grid item xs={6}>
            <GrowthTable data={[data]} headers={HandleGrowth.cIHCGPercentileHeader} parentName={"Investment Invariant Team Growth"} />
          </Grid>
        )}
        {data && data.cihcg && data.reviewgrowthpercentile3 && data.webgrowthpercentile6 && <Grid item xs={6}></Grid>}

        {data.g2totalreviewscore && (
          <Grid item xs={6}>
            <Card>
              <CardHeader title="G2 Rating" />
              <Divider />
              <CardContent className={classes.ratingCard}>
                <Rating name="read-only" value={data.g2totalreviewscore / 2} readOnly precision={0.25} max={5} size="large" />
              </CardContent>
            </Card>
          </Grid>
        )}

        {data.g2totalreviewcount && (
          <Grid item xs={6}>
            <Card>
              <CardHeader title="G2 Review Count" />
              <Divider />
              <CardContent className={classes.ratingCard}>
                <Typography variant="h2">{data.g2totalreviewcount + " Reviews"}</Typography>
              </CardContent>
            </Card>
          </Grid>
        )}

        {data.captotalreviewscore && (
          <Grid item xs={6}>
            <Card>
              <CardHeader title="Capterra Rating" />
              <Divider />
              <CardContent className={classes.ratingCard}>
                <Rating name="read-only" value={data.captotalreviewscore} readOnly precision={0.25} max={5} size="large" />
              </CardContent>
            </Card>
          </Grid>
        )}

        {data.captotalreviewcount && (
          <Grid item xs={6}>
            <Card>
              <CardHeader title="Capterra Review Count" />
              <Divider />
              <CardContent className={classes.ratingCard}>
                <Typography variant="h2">{data.captotalreviewcount + " Reviews"}</Typography>
              </CardContent>
            </Card>
          </Grid>
        )}

        {data.tptotalreviewscore && (
          <Grid item xs={6}>
            <Card>
              <CardHeader title="TrustPilot Rating" />
              <Divider />
              <CardContent className={classes.ratingCard}>
                <Rating name="read-only" value={data.tptotalreviewscore} readOnly precision={0.25} max={10} size="large" />
              </CardContent>
            </Card>
          </Grid>
        )}

        {data.tptotalreviewcount && (
          <Grid item xs={6}>
            <Card>
              <CardHeader title="TrustPilot Review Count" />
              <Divider />
              <CardContent className={classes.ratingCard}>
                <Typography variant="h2">{data.tptotalreviewcount + " Reviews"}</Typography>
              </CardContent>
            </Card>
          </Grid>
        )}

        {traffic && Object.keys(traffic).length !== 0 && (
          <Grid item xs={12}>
            <GrowthIndexGraph inputData={traffic} title={"Web Traffic Growth"} legendY={"Visitor Count"} />
          </Grid>
        )}

        {data && data.webev3m && (
          <Grid item xs={12}>
            <GrowthTable data={[data]} headers={HandleGrowth.webEvolutionHeader} parentName={"Website Update Frequency"} />
          </Grid>
        )}

        {data && data.evolutionpercentile3 != null && (
          <Grid item xs={12}>
            <GrowthTable data={[data]} headers={HandleGrowth.webEvolutionPercentileHeader} parentName={"Website Update Frequency Percentile"} />
          </Grid>
        )}

        {pageDataOut && (
          <Grid item xs={12}>
            <GrowthTable data={pageDataOut} headers={HandleGrowth.pageTypeHeaders} parentName={"Website Pages"} />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default GrowthOverview;
