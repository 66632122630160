import { makeStyles  , createStyles} from '@mui/styles';
import { FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import React, { useState } from 'react';
import LeadsListBody from "../LeadsListBody";
import {Theme} from '@mui/material/styles';

const useStyles = makeStyles((theme:Theme) => createStyles({
    root: {
        width: '100%',

    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    mainHeading: {
        textAlign: "center",
        width: "100%",
        minWidth: 650,
        paddingBottom: "4px"
    },
    noMargin: {
        marginTop: "0px !important",
        paddingTop: "0px !important"
    },

    skeletonStyle: {
        width: "100%",
        height: "250px"
    },
    hidingGrid: {
        padding: "0px !important"
    },
    selectRight:{
        textAlign:"right"
    }
}));

type leadsListProps={
    data:[],
    loading:any,
    type:string,
    
}

const LeadsList = (props:leadsListProps) => {
    const classes = useStyles();
    const { data, loading, type } = props;
   const [leadsSource, setSource] = useState("All");

   const dataOut = type === "InNetwork" && leadsSource !== "All" ? data.filter((value :any)=>{
       return value.investor === leadsSource
   }) : data
    return (
        <div className={classes.root}>
            <Grid container spacing={2} className={classes.noMargin}>

                <Grid item xs={12} sm={12} md={10} lg={8} className={classes.noMargin}>
                    <Typography variant="h3" component="h2" className={classes.mainHeading}>
                        {type === "InNetwork" ? "Renegade In Network Leads" : "Renegade Out of Network Leads"}
                    </Typography>
                </Grid>
                {type === "InNetwork" ? <Grid item xs={10} sm={10} md={8} lg={6} className={classes.noMargin}></Grid> : ""}
                {type === "InNetwork" ? <Grid item xs={2} className={classes.selectRight}>
                    <FormControl>
                        <Select
                            value={leadsSource}
                            onChange={(event) => setSource(event.target.value)}
                            name="formId"
                            
                        >
                            <MenuItem value={"All"}>All</MenuItem>
                            <MenuItem value={"Renata"}>Renata</MenuItem>
                            <MenuItem value={"Roseanne"}>Roseanne</MenuItem>
                            <MenuItem value={"Susan"}>Susan</MenuItem>
                            <MenuItem value={"Chloe"}>Chloe</MenuItem>

                        </Select>
                    </FormControl>
                </Grid> : ""}


                <Grid item xs={12} sm={12} md={10} lg={8} className={classes.noMargin}>

                    <LeadsListBody loading={loading} data={dataOut} page={1}  onChange={()=>{}}></LeadsListBody>
                </Grid>

            </Grid>
        </div>
    );

};

export default LeadsList;
