import { FormControl, Grid, MenuItem, Select, Typography, Theme, SelectChangeEvent } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { GoogleSheets } from "types";

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      width: "100%"
    },
    ratingCard: {
      textAlign: "center"
    },
    opaqueReveal: {
      background: "grey",
      height: "500px",
      textAlign: "center"
    },
    revealText: {
      marginTop: "230px",
      color: "white",
      fontSize: "20px"
    },
    iconStyle: {
      color: "white",
      marginBottom: "5px"
    },
    selectCard: {
      marginLeft: "24px",
      paddingLeft: "8px",
      paddingRight: "8px"
    },
    codaRoot: {
      width: "100%",
      height: "1000px",
      borderWidth: "0px"
    }
  })
);

interface PropType {
  data: GoogleSheets[];
}

function CompanySheets(props: PropType) {
  const { data } = props;

  const classes = useStyles();

  const [state, setState] = React.useState({
    companySheetId: data[0].sheetid
  });

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.name === "companySheetId") {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={8}>
          <Typography gutterBottom variant="h4" component="h2">
            Company Financials:
          </Typography>
        </Grid>

        <Grid item xs={6} md={2} className={classes.gridRight}>
          <FormControl className={classes.formControl}>
            <Select value={state.companySheetId} onChange={handleChange} name="companySheetId" className={classes.selectCard}>
              {data.map(elem => {
                return <MenuItem value={elem.sheetid}>{elem.sheettitle}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          {/* eslint-disable-next-line */}
          <iframe sandbox="allow-scripts allow-same-origin allow-popups" className={classes.codaRoot} src={"https://docs.google.com/spreadsheets/d/e/" + state.companySheetId + "/pubhtml?widget=true&amp;headers=false"}></iframe>
          {/*<iframe className={classes.codaRoot} src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRsknbhIlKYF22HsHKpo008t8HjT7TJ8rZUlW6XyMCB3RrGHkEXnVKZihY2tzLCPT7faETBDX-3lvWK/pubhtml?widget=true&amp;headers=false"></iframe>*/}
        </Grid>
      </Grid>
    </div>
  );
}

export default CompanySheets;
