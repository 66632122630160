import SaveIcon from '@mui/icons-material/Save';
import { Autocomplete, Button, Chip, Divider, Slider, TextField, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useState } from 'react';



const useStyles = makeStyles((theme) => createStyles({
    root: {
        width: '100%',
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    details: {
        alignItems: 'center',
        width: '100%',
    },
    textField: {
        width: '100%',
        marginTop: '16px'
    },
    textFieldSmall: {
        width: '100%',
        marginTop: '8px'
    },
    labelRoot: {
        fontSize: '19px'
    },
    labelRootExternal: {
        transform: "translate(0, 1.5px) scale(0.75)",
        transformOrigin: "top left",
        fontSize: "19px",
        marginTop: '16px'
    },
    labelRootSmall: {
        fontSize: '17px'
    },
    labelRootExternalSmall: {
        transform: "translate(0, 1.5px) scale(0.75)",
        transformOrigin: "top left",
        fontSize: "17px",
        marginTop: '8px',
        textAlign: "left"
    },
    expansionPanelStyle: {
        root: {
            boxShadow: "none"
        }
    },
    indentItems: {
        marginLeft: "40px",
        marginRight: "40px",
        textAlign: "center"
    },
    divider: {
        backgroundColor: "#494A4E",
        marginTop: "8px",
        marginBottom: "8px"
    },
    buttonStyle: {
        //marginBottom: "8px",
        marginTop:"8px"
    },
    chipStyle: {
        fontSize: theme.typography.pxToRem(13),
        marginRight: "5px",
        marginBottom: "5px",
        height:"auto",
        padding:"5px"
    },
    pchip: {
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        textAlign: "center"
      },
}));
const marks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 25,
        label: '25',
    },
    {
        value: 50,
        label: '50',
    },
    {
        value: 75,
        label: '75',
    },
    {
        value: 100,
        label: '100',
    },
];
const marksSmall = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 5,
        label: '5',
    },
    {
        value: 10,
        label: '10',
    },
    {
        value: 15,
        label: '15',
    },
    {
        value: 20,
        label: '20',
    },
];
const MultipartItem = props => {
    const classes = useStyles();
    const { id, label, Select1, Select2, Select3, Slide, generateTags, generateOutput, value, onValueChange} = props;
    const [errorStates, setErrorStates] = useState({
        Select1: false,
        Select2: false,
        Select3: false
    })

    const [itemState, setItemState] = useState({
        Select1: "",
        Select2: "",
        Select3: "",
        Slide: Slide && Slide.isSmall ? [0, 20] : [0, 100]
    })
    const tags = value.map(val => {
        const chipText = generateTags(val)
        return (
            <Chip
            label={
                <section>
                    <div className={classes.pchip}> {chipText}</div>
                </section>
            }
            className={classes.chipStyle}
            onDelete={e=> onDelete(JSON.stringify(val))}
        />
        )
    })
    const onDelete = (item) =>{
        onValueChange(id, value.filter((v) => JSON.stringify(v) !== item))
    }
    const onChange = (id, newValue) => {
        setItemState(prevState => ({
            ...prevState,
            [id]: newValue
        }));
    }
    const onSubmit = e => {
        var newErrorStates = {}
        var newValue = []
        var hasError = false
        if (itemState["Select1"] === "" || itemState["Select1"] == null) {
            newErrorStates["Select1"] = true
            hasError = true
        } else {
            newValue.push(itemState["Select1"])
            newErrorStates["Select1"] = false
        }

        if (itemState["Select2"] === "" || itemState["Select2"] == null) {
            newErrorStates["Select2"] = true
            hasError = true
        } else {
            newValue.push(itemState["Select2"])
            newErrorStates["Select2"] = false
        }
        if(Select3){
            if (itemState["Select3"] === "" || itemState["Select3"] == null) {
                newErrorStates["Select3"] = true
                hasError = true
            } else {
                newValue.push(itemState["Select3"])
                newErrorStates["Select3"] = false
            }
    }
    if(Slide){
        newValue.push(itemState["Slide"])
    }
        if (!hasError) {
            onValueChange(id, value.concat([generateOutput(newValue)]).filter((v,i,a)=>a.findIndex(t=>(JSON.stringify(t) === JSON.stringify(v)))===i))
            setItemState({
                Select1: "",
                Select2: "",
                Select3: "",
                Slide: Slide && Slide.isSmall ? [0, 20] : [0, 100]
            })
        }
        setErrorStates(prevState => ({
            ...prevState,
            ...newErrorStates
        }));
    }
    return (
        <div className={classes.root}>
            <Typography className={classes.labelRootExternal} gutterBottom>
                {label}
            </Typography>
            {tags}
            
            <Divider className={classes.divider} />
            <div className={classes.indentItems}>
                <Autocomplete
                    options={Select1.data}
                    onChange={(e, v) => onChange("Select1", v)}
                    value={itemState["Select1"]}
                    getOptionLabel={(option) => option}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={classes.textFieldSmall}
                            variant="standard"
                            label={Select1.label}
                            error={errorStates["Select1"]}
                            InputLabelProps={{
                                shrink: true,
                                classes: {
                                    root: classes.labelRootSmall
                                }
                            }}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                            }}
                        />
                    )}
                />
                <Autocomplete
                    options={Select2.data}
                    onChange={(e, v) => onChange("Select2", v)}
                    value={itemState["Select2"]}
                    getOptionLabel={(option) => option}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={classes.textFieldSmall}
                            variant="standard"
                            label={Select2.label}
                            error={errorStates["Select2"]}
                            InputLabelProps={{
                                shrink: true,
                                classes: {
                                    root: classes.labelRootSmall
                                }
                            }}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                            }}
                        />
                    )}
                />
                {Select3 ?
                    <Autocomplete
                        freeSolo={Select3.freeSolo ? true : false}
                        autoSelect
                        options={Select3.data}
                        onChange={(e, v) => onChange("Select3", v)}
                        value={itemState["Select3"]}
                        getOptionLabel={(option) => option}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className={classes.textFieldSmall}
                                variant="standard"
                                label={Select3.label}
                                error={errorStates["Select3"]}
                                InputLabelProps={{
                                    shrink: true,
                                    classes: {
                                        root: classes.labelRootSmall
                                    }
                                }}
                                limitTags={10}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password',
                                }}
                            />
                        )}
                    /> : ""
                }
                {Slide ? 
                <Typography className={classes.labelRootExternalSmall} gutterBottom>
                    {Slide.label}
                </Typography> : ""
                }
                {Slide ? 
                <Slider
                    value={itemState["Slide"]}
                    onChange={(e, v) => onChange("Slide", v)}
                    valueLabelDisplay="auto"
                    marks={Slide.isSmall ? marksSmall : marks}
                    max={Slide.isSmall ? 20 : 100}

                /> : ""
                }
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.buttonStyle}
                    startIcon={<SaveIcon />}
                    onClick={(e) => onSubmit(e)}>
                    Add Item
      </Button>
            </div>
        </div>
    )
}

export default MultipartItem