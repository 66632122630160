import React from "react";
import { Skeleton } from "@mui/lab";
import { Grid, Pagination, Paper, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { CompanyCard } from "components";
import { CompanyData, CountObject } from "types";

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      width: "100%"
    },

    noMargin: {
      marginTop: "0px !important",
      paddingTop: "0px !important"
    },
    paginationCenter: {
      display: "table",
      margin: "0 auto",
      paddingTop: "20px",
      paddingBottom: "20px"
    },
    searchSize: {
      width: "100%",
      textAlign: "right",
      paddingRight: "20px",
      paddingTop: "10px",
      paddingBottom: "10px"
    },
    sortByText: {
      width: "fit-content",
      display: "inline-block",
      padding: "10px"
    },
    sortSelect: {
      marginLeft: "10px"
    },
    skeletonStyle: {
      width: "100%",
      height: "250px"
    },
    nothingFoundText: {
      width: "100%",
      textAlign: "center",
      padding: "20px"
    }
  })
);

// const generateTags = (data) =>{
//   const outTags = []
//   if(data){

//   }
//   return outTags
// }

interface PropType {
  loading: boolean;
  data: {
    company: CompanyData[];
    count:CountObject[]
  };
  page: number;
  onChange: (type:string, page: number) => void;
}

const CompanyListBody = (props:PropType) => {
  const classes = useStyles();

  const { loading, data, page,  onChange } = props;

  return (
    <div className={classes.root}>
      <Grid container className={classes.noMargin}>
        {!loading && (
          <Grid item xs={12}>
            <Paper elevation={2} square={true}>
              <Grid container>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <Typography className={classes.searchSize} variant="body1" color="textSecondary" component="p">
                  {"Showing " + (data.count[0].count < 500 ? data.count[0].count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "500+") + " results"}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}

        {!loading ? (
          [
            data.company && data.company.length > 0 ? (
              data.company.map(company => {
                return (
                  <Grid item xs={12}>
                    <CompanyCard
                      companyName={company.namefinal}
                      companyImage={"//logo.clearbit.com/" + company.websitefinal}
                      description={company.finaldescription}
                      companyID={company.uuid}
                      seriesmerged={company.seriesmerged}
                      totalfunding={company.totalfunding}
                    />
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12}>
                <Paper elevation={2} square={true}>
                  <Typography variant="h4" className={classes.nothingFoundText}>
                    No Companies Found
                  </Typography>
                </Paper>
              </Grid>
            )
          ]
        ) : (
          <Grid item xs={12}>
            <Skeleton variant="text" height={65} />
            <Skeleton variant="circular" width={75} height={75} />
            <Skeleton variant="rectangular" width={"100%"} height={125} />
            <Skeleton variant="circular" width={75} height={75} />
            <Skeleton variant="rectangular" width={"auto"} height={125} />
            <Skeleton variant="text" height={65} />
          </Grid>
        )}

        <Grid item xs={12}>
          {!loading && (
            <Paper elevation={2} square={true}>
              <Pagination className={classes.paginationCenter} color="primary" count={Math.max(Math.min(Math.ceil(data.count[0].count / 25), 20), 1)} page={page} size="large" onChange={(e, v) => onChange("pageNum", v)} />
            </Paper>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default CompanyListBody;
