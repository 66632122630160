import React from "react";
import { Redirect, Switch } from "react-router-dom";

import { RouteWithLayout } from "./components";
import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts";
import { Company as CompanyView, Dashboard as DashboardView, Employee as EmployeeView, ErrorPage as ErrorPageView, InvestmentForms as InvestmentFormsView, NotFound as NotFoundView, Search as SearchView, Settings as SettingsView, WeeklyLeads as WeeklyLeadsView } from "./views";

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <RouteWithLayout component={DashboardView} exact layout={MainLayout} path="/dashboard" />
      <RouteWithLayout component={NotFoundView} exact layout={MinimalLayout} path="/not-found" />
      <RouteWithLayout component={SettingsView} exact layout={MainLayout} path="/account" />
      <RouteWithLayout component={SearchView} exact layout={MainLayout} path="/search" />

      <RouteWithLayout component={InvestmentFormsView} exact layout={MainLayout} path="/investmentforms" />
      <RouteWithLayout component={WeeklyLeadsView} exact layout={MainLayout} path="/leads" />
      <RouteWithLayout component={ErrorPageView} exact layout={MainLayout} path="/something-went-wrong" />
      <RouteWithLayout path="/companies/:id" component={CompanyView} layout={MainLayout} />
      {/* New page layout */}

      <RouteWithLayout path="/employees/:employeeID" component={EmployeeView} layout={MainLayout} />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
