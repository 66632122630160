import {Link, Theme} from '@mui/material';
import { makeStyles , createStyles } from '@mui/styles';
import ShowMore from 'components/ShowMore';
import React from 'react';
import { EmployeeData } from 'types';
import ExpandingSection from '../ExpandingSection';
import ExperienceCard from '../ExperienceCard';

const useStyles = makeStyles<Theme>(theme => createStyles({
    titleHeading: {
        fontSize: theme.typography.pxToRem(15),
        marginBottom: "8px"
    },
    iconTitleHeading: {
        color: theme.palette.text.secondary,
        marginBottom: "8px"
    },
    textIconWrapper: {
        display: "inline-block",
        marginRight: "30px"
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15)
    },
    fullWidth: {
        "width": "100%"
    },
    paperRoot: {
        width: "100%",
        marginBottom: theme.spacing(2),
        textAlign: "center"

    },
    linkText: {
        fontSize: theme.typography.pxToRem(14),
        paddingRight: "5px",
        color: theme.palette.info.main
    },
    chipStyle: {
        fontSize: theme.typography.pxToRem(14),
        marginRight: "5px",
        marginBottom: "5px",
    },
    largeAvatar: {
        width: "150px",
        height: "150px",
        display: "inline-block"
    },
    iconClass: {
        paddingRight: "5px",
        color: theme.palette.text.secondary
    },
    displayRight: {
        textAlign: "right"
    }
}));

interface PropTypes {
    data:EmployeeData
}

const Certifications = (props:PropTypes) => {
    const { data } = props;
    const classes = useStyles();
    const certificationData = data["certifications"].map(cert => {
        var date = cert.certificatestart && cert.certificatestart !== "-" && cert.certificateend && cert.certificateend !== "-" ? " - " : ""
        if (cert.certificatestart && cert.certificatestart !== "-") {
            date = cert.certificatestart + date
        }
        if (cert.certificateend && cert.certificateend !== "-") {
            date += cert.certificateend
        }
        return (

            <ExperienceCard data={{
                header: cert.certificate,
                subHeader: cert.issuerlink && cert.issuerlink !== "-" ? <Link color="inherit" href={cert.issuerlink}>{cert.issuer}</Link> :cert.issuer,
                subTitle1: date,
                image: cert.issuerlogo && cert.issuerlogo !== "-" ? cert.issuerlogo : "/images/default-corporate-image.jpg"
            }} />)
    })

    return (
        <div className={classes.fullWidth}>
            <ExpandingSection title="Certifications" itemIndex={"Certificate"} data={data["certifications"]} fullBody={<ShowMore data={certificationData} trimIndex={4} />} />
        </div>
    );

};

export default Certifications;
