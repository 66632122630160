import { AppBar, Theme, Toolbar } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface PropType {
  className?:string
}

const useStyles = makeStyles<Theme>(() => createStyles({
  root: {
    boxShadow: 'none',
    backgroundColor:"#FFF"
  },
  imageClass:{
    height: "34px"
  }
}));

const Topbar = (props:PropType) => {
  const { className } = props;

  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      position="fixed"
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/logos/header.png"
            className={classes.imageClass}
          />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};



export default Topbar;
