import { Card, CardContent, CardHeader, Divider, Theme } from "@mui/material";
import { makeStyles, createStyles, useTheme } from "@mui/styles";
import { ResponsiveLine } from "@nivo/line";
import clsx from "clsx";
import React from "react";
import { ObjectLiteral } from "types";

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {},
    chartContainer: {
      position: "relative",
      height: "500px"
    },
    stats: {
      marginTop: theme.spacing(2),
      display: "flex",
      justifyContent: "center"
    },
    pageInfo: {
      textAlign: "center",
      padding: theme.spacing(1)
    }
  })
);

const lineGraphSettings = {
  theme: {
    fontSize: 13
  }
};
const colors: string[] = ["#66ccff", "#ff952c"];

interface PropTypes<T> {
  className?: string;
  inputData: T[];
  title?: React.ReactNode;
  legendY?: string;
}

const GrowthIndexGraph = <T extends ObjectLiteral>(props: PropTypes<T>) => {
  const { className, inputData, title, legendY, ...rest } = props;
  const classes = useStyles();
  //eslint-disable-next-line
  const theme = useTheme();
  var resInput:any = [];
  var colorIndex = 0;
  for (const elem of inputData) {
    resInput.push({
      id: elem.id,
      color: colors[colorIndex],
      data: elem.data
    });
    colorIndex += 1;
  }
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={title} />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <ResponsiveLine
            data={resInput}
            margin={{ top: 40, right: 60, bottom: 100, left: 80 }}
            xScale={{ type: "point" }}
            yScale={{ type: "linear", stacked: false, reverse: false }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              //orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 30,
              legendOffset: 45,
              legendPosition: "middle",
            }}
            axisLeft={{
              //orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: legendY ? legendY : "Signal Score",
              legendOffset: -75,
              legendPosition: "middle",
            }}
            colors={colors.map(c => c)}
            lineWidth={2}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabel="y"
            pointLabelYOffset={-12}
            enableSlices="x"
            crosshairType="top-left"
            useMesh={true}
            theme={lineGraphSettings.theme}
            legends={[
              {
                anchor: "bottom",
                direction: "column",
                justify: false,
                translateX: 0,
                translateY: 100,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 75,
                itemHeight: 24,
                itemOpacity: 0.75,
                symbolSize: 20,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default GrowthIndexGraph;
