import React from 'react';
//import { Interface } from 'readline';
import { UserContextType} from 'types';
import UserContext from '.';

// const profileDetails = {
//     firstName: '',
//     lastName: '',
//     title: '',
//     avatar: '/images/avatars/'
//   };


  export default React.createContext<UserContextType>({
    values:{
      firstName:"",
      lastName:"",
      title:"",
      userName:""
    },
    avatar:"",
    setAccountInfo:() => {},
    setProfilePicture:() => {}
  });

export const useUserContextAPI = () =>{
  const {values , avatar , setAccountInfo , setProfilePicture} = React.useContext(UserContext)
  return {values , avatar, setAccountInfo , setProfilePicture} 
}