import { makeStyles, createStyles } from "@mui/styles";
import { Chip, Divider, Grid, Typography, Theme } from "@mui/material";
import ShowMore from "components/ShowMore";
import ShowMoreText from "components/ShowMoreText";
import React from "react";

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      width: "100%",
      paddingTop: "30px"
    },
    header: {
      fontSize: theme.typography.pxToRem(18),
      marginBottom: "2px",
      fontWeight: 800
    },
    subHeader: {
      fontSize: theme.typography.pxToRem(15),
      marginBottom: "2px"
    },
    descriptionText: {
      fontSize: theme.typography.pxToRem(15),
      marginBottom: "10px"
    },
    muteSubHeader: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
      marginBottom: "2px"
    },
    largeImage: {
      width: "75px",
      height: "75px",
      display: "inline-block"
    },
    displayRight: {
      textAlign: "right"
    },
    chipStyle: {
      fontSize: theme.typography.pxToRem(14),
      marginRight: "5px",
      marginBottom: "5px"
    }
  })
);

interface PropTypes {
  data: {
    image?: string;
    header?: React.ReactNode;
    subHeader?: React.ReactNode;
    subTitle1?: string;
    subTitle2?: string;
    description?: string;
    tags?: string[];
    onTagClick?: Function;
    link?:string
  };
}

const ExperienceCard = (props: PropTypes) => {
  const classes = useStyles();

  const { data} = props;
  var xsSize = 9;
  var mdSize = 10;
  var xlSize = 11;

  if (!data.image || data.image === "-") {
    xsSize = 12;
    mdSize = 12;
    xlSize = 12;
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {data.image && (
          <Grid item xs={3} md={2} xl={1} className={classes.displayRight}>
            <img
              className={classes.largeImage}
              src={data.image}
              alt = "default-corporate"
              onError={e => {
                e.currentTarget.src = "/images/default-corporate-image.jpg"; // some replacement image
              }}
            ></img>
          </Grid>
        )}

        <Grid item xs={xsSize} md={mdSize} xl={xlSize}>
          {data.header && data.header !== "-" && <Typography className={classes.header}> {data.header} </Typography>}
          {data.subHeader && data.subHeader !== "-" && <Typography className={classes.subHeader}> {data.subHeader} </Typography>}
          {data.subTitle1 && data.subTitle1 !== "-" && <Typography className={classes.muteSubHeader}> {data.subTitle1} </Typography>}
          {data.subTitle2 && data.subTitle2 !== "-" && <Typography className={classes.muteSubHeader}> {data.subTitle2} </Typography>}

          {data.description && data.description !== "-" && (
            <Typography className={classes.descriptionText}>
              <ShowMoreText data={data.description} trimIndex={300} />
            </Typography>
          )}

          {data.tags && data.tags.length > 0 && (
            <ShowMore
              isInline={true}
              data={data.tags.map(value => {
                return data.onTagClick ? (
                  <Chip
                    label={value}
                    className={classes.chipStyle}
                    clickable
                    component={data.onTagClick(value)}
                    //onClick={e=>data.onTagClick(e,value)}
                  />
                ) : (
                  <Chip label={value} className={classes.chipStyle} />
                );
              })}
              trimIndex={8}
            />
          )}

        </Grid>
      </Grid>
      <Divider />
    </div>
  );
};

export default ExperienceCard;
