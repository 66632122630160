import React from "react";
import { Route, Redirect } from "react-router-dom";
import ErrorBoundary from "components/ErrorBoundary";
import {} from "aws-amplify";

interface PropTypes {
  component: typeof React.Component;
  layout: typeof React.Component;
  path: string;
  currentUserEmail: string;
}

const wlEmailsData = process.env.REACT_APP_ADMIN_EMAILS;
const wlEmailsArray = wlEmailsData?.trim().split(" ");

const RouteWithAccessControl = (props: PropTypes) => {
  const { layout: Layout, component: Component, path, currentUserEmail, ...rest } = props;

  if (!wlEmailsArray?.includes(currentUserEmail)) return <Redirect to={"/dashboard"} />;

  return (
    <Route
      path={path}
      {...rest}
      render={matchProps => (
        <ErrorBoundary>
          <Layout>
            <Component {...matchProps} />
          </Layout>
        </ErrorBoundary>
      )}
    />
  );
};

export default RouteWithAccessControl;
