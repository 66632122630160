import { Link, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import ShowMore from "components/ShowMore";
import React from "react";
import ExperienceCard from "../ExperienceCard";
import {EmployeeData } from "types"

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    titleHeading: {
      fontSize: theme.typography.pxToRem(15),
      marginBottom: "8px"
    },
    iconTitleHeading: {
      color: theme.palette.text.secondary,
      marginBottom: "8px"
    },
    textIconWrapper: {
      display: "inline-block",
      marginRight: "30px"
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15)
    },
    fullWidth: {
      width: "100%"
    },
    paperRoot: {
      width: "100%",
      marginBottom: theme.spacing(2),
      textAlign: "center"
    },
    linkText: {
      fontSize: theme.typography.pxToRem(14),
      paddingRight: "5px",
      color: theme.palette.info.main
    },
    chipStyle: {
      fontSize: theme.typography.pxToRem(14),
      marginRight: "5px",
      marginBottom: "5px"
    },
    largeAvatar: {
      width: "150px",
      height: "150px",
      display: "inline-block"
    },
    iconClass: {
      paddingRight: "5px",
      color: theme.palette.text.secondary
    },
    displayRight: {
      textAlign: "right"
    }
  })
);
interface PropTypes {
    className?:string,
    data:EmployeeData
}

const Jobs = (props:PropTypes) => {
  const { data } = props;
  const classes = useStyles();
  const sortedVols = data["volunteer"];
  sortedVols.sort(function(a, b) {
    return parseInt(b.startepoch) - parseInt(a.startepoch);
  });
  return (
    <div className={classes.fullWidth}>
      <Typography gutterBottom  variant="h4" component="h2">
        Volunteer
      </Typography>
      <ShowMore
        data={sortedVols.map(vol => {
          var date = vol.startdate && vol.enddate ? " - " : "";
          if (vol.startdate) {
            date = vol.startdate + date;
          }
          if (vol.enddate) {
            date += vol.enddate;
          }
          return (
            <ExperienceCard
              data={{
                header: vol.volunteerrole,
                subHeader:
                  vol.companylink && vol.companylink !== "-" ? (
                    <Link color="inherit" href={vol.companylink}>
                      {vol.company}
                    </Link>
                  ) : (
                    vol.company
                  ),
                subTitle1: date,
                subTitle2: vol.cause,
                description: vol.description,
                link: vol.companylink,
                image: vol.imagelink && vol.imagelink !== "-" ? vol.imagelink : "/images/default-corporate-image.jpg"
              }}
            />
          );
        })}
        trimIndex={4}
      />
    </div>
  );
};

export default Jobs;
