import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import ShowMore from "components/ShowMore";
import React from "react";
import { EmployeeData } from "types";
import ExpandingSection from "../ExpandingSection";
import ExperienceCard from "../ExperienceCard";

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    titleHeading: {
      fontSize: theme.typography.pxToRem(15),
      marginBottom: "8px"
    },
    iconTitleHeading: {
      color: theme.palette.text.secondary,
      marginBottom: "8px"
    },
    textIconWrapper: {
      display: "inline-block",
      marginRight: "30px"
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15)
    },
    fullWidth: {
      width: "100%"
    },
    paperRoot: {
      width: "100%",
      marginBottom: theme.spacing(2),
      textAlign: "center"
    },
    linkText: {
      fontSize: theme.typography.pxToRem(14),
      paddingRight: "5px",
      color: theme.palette.info.main
    },
    chipStyle: {
      fontSize: theme.typography.pxToRem(14),
      marginRight: "5px",
      marginBottom: "5px"
    },
    largeAvatar: {
      width: "150px",
      height: "150px",
      display: "inline-block"
    },
    iconClass: {
      paddingRight: "5px",
      color: theme.palette.text.secondary
    },
    displayRight: {
      textAlign: "right"
    }
  })
);

interface PropTypes {
  data: EmployeeData;
}

const Languages = (props: PropTypes) => {
  const { data } = props;
  const classes = useStyles();
  const languageData = (
    <ShowMore
      data={data["languages"].map(language => {
        return (
          <ExperienceCard
            data={{
              header: language.profilelanguage,
              description: language.proficiency
            }}
          />
        );
      })}
      trimIndex={3}
    />
  );
  return (
    <div className={classes.fullWidth}>
      <ExpandingSection title="Languages" itemIndex={"profilelanguage"} data={data["languages"]} fullBody={languageData} />
    </div>
  );
};

export default Languages;
