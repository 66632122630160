import { Avatar, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { UserContext } from 'components';
//import { useUserContextAPI } from 'components/UserContext';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles<Theme>(theme => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1),
  }
}));

interface PropsType {
  className?:string,
  closeDrawer:()=>void
}

const Profile = (props:PropsType) => {
  const { className, closeDrawer } = props;

  const classes = useStyles();
  const {values, avatar } = React.useContext<any>(UserContext)


  return (
    <div
      className={clsx(classes.root, className)}
    >
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src={avatar??""}
        to="/account"
        onClick={closeDrawer}
      />
      
      <Typography
        className={classes.name}
        variant="body1"
        gutterBottom
      >
        {!isEmpty(values.firstName)  ?(values?.firstName + " " + values?.lastName):''}
      </Typography>
      <Typography variant="body2">{values.title}</Typography>
    </div>
  );
};

export default Profile;
