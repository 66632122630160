import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Card, CardContent, CardHeader, Divider, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, Select, Switch, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Details, RepeatTables } from "components";
import { HandleScorecard } from "helpers";
import React from "react";
import { CompanyData } from "types";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: "100%"
    },
    ratingCard: {
      textAlign: "center"
    },
    largeAvatar: {
      width: "50px",
      height: "50px",
      display: "inline-block"
    },
    displayRight: {
      textAlign: "center"
    },
    noSidePadding: {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
      marginLeft: "0px !important",
      marginRight: "0px !important",
      marginBottom: "0px !important",
      paddingBottom: "0px !important"
    },
    nothingFoundText: {
      width: "100%",
      textAlign: "center",
      padding: "20px"
    },
    gridRight: {
      textAlign: "right",
      paddingLeft: "8px"
    },
    selectCard: {
      minWidth: "110px"
    }
  })
);

var investor_images: any = {
  "Chloe Breider":
    "https://images.squarespace-cdn.com/content/v1/5f0daeebae325e52eef4cd1a/1596809181083-43YJM5DLA99UECZECKI9/ke17ZwdGBToddI8pDm48kDs6OgM01FuhQBqjgXla4ql7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QHyNOqBUUEtDDsRWrJLTmDJyaVitQ06bkWUY0OMxkmJUILIYtQaXPDtzxwPwitM3syB85qExdRa99CxJxkDNY/Artboard%2B1%2Bcopy%2B3.jpg?format=1500w",
  "Roseanne Wincek":
    "https://images.squarespace-cdn.com/content/v1/5f0daeebae325e52eef4cd1a/1596745975287-Q4W15WAW9Z46P2MMFOVU/ke17ZwdGBToddI8pDm48kKWebp3cCkqjElJlDI8NzLx7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UWIN3MUu6ucv27MaZKEhDtOTNKodSb7ZWWjWTtIRiDQ8p4g8dkz8m9jd1c_lmLz9eg/Artboard+1+copy.jpg?format=1500w",
  "Renata Quintini":
    "https://images.squarespace-cdn.com/content/v1/5f0daeebae325e52eef4cd1a/1596742772761-A7424F5Q2IJWKPY32M3Y/ke17ZwdGBToddI8pDm48kKWebp3cCkqjElJlDI8NzLx7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UWIN3MUu6ucv27MaZKEhDtOTNKodSb7ZWWjWTtIRiDQ8p4g8dkz8m9jd1c_lmLz9eg/Artboard+1.jpg?format=1500w",
  "Susan Alban":
    "https://images.squarespace-cdn.com/content/v1/5f0daeebae325e52eef4cd1a/1596742964922-P4WJJW70M7GC33BKTTNU/ke17ZwdGBToddI8pDm48kKWebp3cCkqjElJlDI8NzLx7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UWIN3MUu6ucv27MaZKEhDtOTNKodSb7ZWWjWTtIRiDQ8p4g8dkz8m9jd1c_lmLz9eg/Artboard+1+copy+2.jpg?format=1500w"
};
type investorFeedbackProps = {
  data: CompanyData;
};

function InvestorFeedback(props: investorFeedbackProps) {
  const { data } = props;
  const classes = useStyles();
  const [state, setState] = React.useState({
    debriefview: false,
    scorecardType: "ScorecardA"
  });

  const handleChange = (event: any) => {
    if (event.target.name === "scorecardType") {
      setState({ ...state, [event.target.name]: event.target.value });
    } else if (event.target.id === "debriefview") {
      setState({ ...state, [event.target.id]: event.target.checked });
    }
  };

  var scorecards = state.debriefview ? HandleScorecard.traigeDebriefCards(data, state.scorecardType) : HandleScorecard.traigeScoreCards(data, state.scorecardType);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Typography gutterBottom variant="h4" component="h2">
            Team Scorecards:
          </Typography>
        </Grid>

        <Grid item xs={6} md={2} className={classes.gridRight}>
          <FormControl>
            <Select value={state.scorecardType} onChange={handleChange} name="scorecardType" className={classes.selectCard}>
              <MenuItem value={"ScorecardA"}>Scorecard A</MenuItem>
              <MenuItem value={"ScorecardB"}>Scorecard B</MenuItem>
              <MenuItem value={"ScorecardC"}>Scorecard C</MenuItem>
              <MenuItem value={"ScorecardPartner"}>Portfolio Review Scorecard</MenuItem>

              <MenuItem value={"Original"}>Original</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={2}>
          <FormGroup row>
            <FormControlLabel control={<Switch checked={state.debriefview} onChange={handleChange} id="debriefview" />} label="Toggle Debrief" />
          </FormGroup>
        </Grid>
        {scorecards ? (
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Scorecards" />
              <Divider />
              <CardContent className={classes.noSidePadding}>
                <div className={classes.root}>
                  {!state.debriefview ? (
                    scorecards.data.map((scorecard:any) => {
                      return (
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content" id="panel1c-header">
                            <Grid container spacing={2}>
                              <Grid item xs={2} lg={1} className={classes.displayRight}>
                                <Avatar alt="No Image" className={classes.largeAvatar} src={investor_images[scorecard.author] ? investor_images[scorecard.author] : "/images/avatars/NoProfile.png"}>
                                  {<img className={classes.largeAvatar} src="/images/avatars/NoProfile.png" alt="NoProfile" />}
                                </Avatar>
                              </Grid>

                              <Grid item xs={10} lg={11}>
                                <Typography gutterBottom>{"Author: " + scorecard.author}</Typography>
                                {/* <Typography gutterBottom>{"Created: " + (scorecard?.dateofmeeting ? scorecard.dateofmeeting : scorecard.meetingdate)}</Typography> */}
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                           {scorecards?.headers &&  <Details data={scorecard} headers={scorecards?.headers} /> }
                          </AccordionDetails>
                          <Divider />
                        </Accordion>
                      );
                    })
                  ) : (
                    <RepeatTables data={scorecards.data} columns={scorecards.headers} />
                  )}
                  :
                </div>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.nothingFoundText}>
              No Scorecards Found
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default InvestorFeedback;
