import { makeStyles, createStyles } from "@mui/styles";
import { Divider, Grid, Link, Paper, Typography, Theme, TableCell } from "@mui/material";
import { Details, TableBody, TableHeader } from "components";
import groupBy from "lodash/groupBy";
import React from "react";
import { CompanyData, CompanyTableColumns, FundingRound } from "types";

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      width: "100%"
    },
    heading: {
      paddingLeft: "12px"
    },
    headingText: {
      fontSize: theme.typography.pxToRem(16)
    },
    paper: {
      width: "100%",
      padding: "3px",
      paddingBottom: "20px"
    },
    iconWrapper: {
      display: "flex",
      alignItems: "center",
      marginTop: "15px"
    },
    divider: {
      margin: theme.spacing(2, 0)
    },
    profileText: {
      //marginLeft: "8px"
    },
    linkText: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.info.main,
      display: "inline-block"
    },
    fundingWrapper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      textAlign: "center"
    },
    fundingWrapperText: {
      width: "100%",
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: "3px",
      paddingTop: theme.spacing(1),
      textAlign: "center"
    },
    paperSecond: {
      margin: theme.spacing(2, 0)
    },
    gridNoTopSpace: {
      paddingTop: "0px !important",
      paddingBottom: "0px !important"
    }
  })
);

const columnsFunding: CompanyTableColumns<FundingRound>[] = [
  {
    id: "dealdate",
    label: "Deal Date",
    minWidth: 110,
    format: value => {
      var trimIndex = value.indexOf(" ");
      return value.slice(0, trimIndex);
    }
  },
  {
    id: "series",
    label: "Series",
    minWidth: 110,
    align: "center"
  },
  {
    id: "leadinvestors",
    label: "Lead Investors",
    minWidth: 110,
    format: values => typeof values === "object" && values.join(", ")
  },
  {
    id: "investors",
    label: "Investors",
    minWidth: 110,
    format: values => typeof values === "object" && values.join(", ")
  },
  {
    id: "dealsize",
    label: "Deal Size",
    minWidth: 110,
    align: "center",
    format: value => (value ? "$" + value + " million" : "")
  },
  {
    id: "postmoneyvaluation",
    label: "Post Money Valuation",
    minWidth: 110,
    align: "center",
    format: value => (value ? "$" + value + " million" : "")
  }
];

// const renderArray = (data) => {
//     return (<Grid container>
//         {data.map((item) => {
//             return (<Grid item xs={12}>
//                 {item}
//             </Grid>
//             )
//         })
//         }
//     </Grid>
//     )
// }

interface PropType {
  data: CompanyData;
}

function CompanyBackground(props: PropType) {
  const { data } = props;
  const classes = useStyles();

  const buildCompanyColumns = <T extends CompanyData>(data: T): [T, CompanyTableColumns<T>[]] => {
    const companyColumns: CompanyTableColumns<T>[] = [];

    if (data.seriesmerged) {
      companyColumns.push({
        id: "seriesmerged",
        name: "Next Series"
      });
    }
    if (data.totalfunding) {
      companyColumns.push({
        id: "totalfunding",
        name: "Total Funding",
        format: value => "$" + value + " million"
      });
    }
    if (data.websitefinal) {
      companyColumns.push({
        id: "websitefinal",
        name: "Website",
        format: value => (
          <Link color="inherit" variant="body1" className={classes.linkText} href={"https://" + value}>
            {"https://" + value}
          </Link>
        )
      });
    }
    if (data.linkedinfinal) {
      companyColumns.push({
        id: "linkedinfinal",
        name: "LinkedIn",
        format: value => (
          <Link color="inherit" variant="body1" className={classes.linkText} href={"https://" + value}>
            {"https://" + value}
          </Link>
        )
      });
    }
    if (data.facebook) {
      companyColumns.push({
        id: "facebook",
        name: "Facebook",
        format: link => (
          <Link color="inherit" variant="body1" className={classes.linkText} href={(link as unknown) as string}>
            {link}
          </Link>
        )
      });
    }
    if (data.twitter) {
      companyColumns.push({
        id: "twitter",
        name: "Twitter",
        format: link => (
          <Link color="inherit" variant="body1" className={classes.linkText} href={(link as unknown) as string}>
            {" "}
            {link}
          </Link>
        )
      });
    }
    if (data.cburl) {
      companyColumns.push({
        id: "cburl",
        name: "Crunchbase",
        format: link => (
          <Link color="inherit" variant="body1" className={classes.linkText} href={(link as unknown) as string}>
            {link}
          </Link>
        )
      });
    }
    if (data.finaldescription) {
      companyColumns.push({
        id: "finaldescription",
        name: "About"
      });
    }
    return [data, companyColumns];
  };

  const companyData = buildCompanyColumns<CompanyData>(data);

  var sortedFunding = data.fundingroundspb ?? [];

  sortedFunding.sort(function(a, b) {
    const dateA: number = new Date(a.dealdate).getTime();
    const dateB: number = new Date(b.dealdate).getTime();

    return dateB - dateA;
  });

  const leadInvestors = groupBy(data.leadinvestors, function(n) {
    return n.dealid;
  });
  const investors = groupBy(data.investors, function(n) {
    return n.dealid;
  });
  for (const elem of sortedFunding) {
    elem.leadinvestors = leadInvestors && leadInvestors[elem.dealid] ? leadInvestors[elem.dealid].map(value => value.investor) : [];
    elem.investors = investors && investors[elem.dealid] ? investors[elem.dealid].map(value => value.investor) : [];
    elem.investors = elem.investors.filter(value => {
      if (elem.leadinvestors.indexOf(value) >= 0) {
        return false;
      }
      return true;
    });
  }
  
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography className={classes.heading}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.headingText}>
                <div className={classes.iconWrapper}>
                  {/*<WorkIcon height="21px" color="primary" />*/}
                  <Typography gutterBottom variant="h4" component="h2">
                    Company Overview:
                  </Typography>
                  {/*<p className={classes.profileText}>Company Overview</p>*/}
                </div>
              </div>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              <Details data={companyData[0]} headers={companyData[1]} />
            </Grid>
          </Grid>
        </Typography>
      </Paper>
      {data.fundingroundspb && (
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.gridNoTopSpace}>
            <Paper className={classes.fundingWrapperText}>
              <div className={classes.headingText}>
                <div className={classes.iconWrapper}>
                  <Typography gutterBottom variant="h4" component="h2" className={classes.heading}>
                    Funding Overview:
                  </Typography>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} className={classes.gridNoTopSpace}>
            <Paper className={classes.fundingWrapper}>
              <TableHeader
                columns={columnsFunding}
                renderColumns={column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      width: column.width ? column.width : ""
                    }}
                  >
                    {column.label}
                  </TableCell>
                )}
                body={
                  <TableBody
                    data={sortedFunding}
                    columns={columnsFunding}
                    renderRecords={(d, column) => (
                      <TableCell key={column.id} align={column.align}>
                        {column.format ? column.format(d[column.id]) : d[column.id]}
                      </TableCell>
                    )}
                  />
                }
              />
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default CompanyBackground;
