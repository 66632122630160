import React from 'react';

import clsx from 'clsx';
import { makeStyles , createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles<Theme>(theme => createStyles({
  root: {
    padding: theme.spacing(4)
  }
}));

interface PropTypes {
  className?:string,

}


const Footer = (props:PropTypes) => {
  const { className } = props;

  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
    >
    </div>
  );
};


export default Footer;
