import { Grid, Typography , Theme} from '@mui/material';
import { createStyles } from '@mui/styles';
import { withStyles } from '@mui/styles';
import React from 'react';

const styles = createStyles((theme:Theme) =>({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 150,
    textAlign: 'center'
  }
}));

interface PropTypes {
  children:React.ReactNode
  classes:any
}
interface StatType {
  hasError:boolean

}

class ErrorBoundary extends React.Component<PropTypes , StatType> {
    constructor(props: PropTypes | Readonly<PropTypes>) {
      super(props);
      this.state = { hasError: false };

    }
  
    static getDerivedStateFromError() {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch() {
      // You can also log the error to an error reporting service
      //logErrorToMyService(error, errorInfo);
      this.setState({
        hasError: true,
      })
    }
  
    render() {
      const { classes } = this.props;
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return     <div className={classes.root}>
        <Grid
          container
          spacing={4}
          sx={{
            justifyContent:"center"
          }}
        >
          <Grid
            item
            lg={6}
            xs={12}
          >
            <div className={classes.content}>
              <Typography variant="h1">
                Oops! Something went wrong.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
        ;
      }

      return this.props.children; 
    }
  }

export default withStyles(styles)(ErrorBoundary)

