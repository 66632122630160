import { Grid, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      padding: theme.spacing(4)
    },
    content: {
      paddingTop: 150,
      textAlign: "center"
    }
  })
);

const ErrorPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
        sx={{
          justifyContent: "center"
        }}
      >
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <Typography variant="h1">Oops! Something went wrong.</Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ErrorPage;
