import { useMutation } from '@apollo/react-hooks';
import DeleteIcon from '@mui/icons-material/Delete';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import gql from 'graphql-tag';
import { deleteSavedSearch } from 'graphql/mutations';
import { listSavedSearchs } from 'graphql/queries';
import React from 'react';
const useStyles = makeStyles(() => createStyles({
    iconStyle: {
        height: "21px",
        marginBottom: "4px",
        marginTop: "4px"
    }
}));

const DeleteItemButton = (props:any) => {
    const {id, searchString, lastViewed, onViewSearch} = props
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();
    const onError = (error:any) => {
        console.log(error)
    }

    const updateCacheRemove = (cache:any) => {
        const existingSaved = cache.readQuery({
            query: gql(listSavedSearchs), variables: {
                "limit": 1000,
            }
        });
        const oldItems = existingSaved.listSavedSearchs.items
        const nextToken = existingSaved.listSavedSearchs.nextToken

        const newItems = oldItems.filter((t:any) => t.id !== id);
        cache.writeQuery({
            query: gql(listSavedSearchs),
            variables: {
                "limit": 1000,
            },
            data: { listSavedSearchs: { 
                __typename:"ModelSavedSearchConnection",
                items: newItems,
                nextToken:nextToken
            } }
        });
    
    };

    const [removeSavedSearch] = useMutation(gql(deleteSavedSearch), {
        update: updateCacheRemove,
        onError: onError,
        //optimisticResponse: true
    });

    const handleClick = (event:any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const onDelete =(e:any) =>{
        e.preventDefault();
        e.stopPropagation();
        removeSavedSearch({
            variables: {
                input: {
                    id: id
                }
            },
        });
    }
    return (
        <div>
            <IconButton onClick={handleClick}>
                <SettingsIcon />
            </IconButton>
            <Menu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem>

                    <ListItemIcon onClick={e=> onViewSearch(e,id,searchString,lastViewed)}
                     //</MenuItem>component={onViewSearchLink(searchString,lastViewed)}
                     >
                        <NewReleasesIcon className={classes.iconStyle} />
                    </ListItemIcon>

                    <ListItemText primary="View New Results" />
                </MenuItem>

                <MenuItem>

                    <ListItemIcon onClick={e=> onViewSearch(e,id,searchString,0)}  
                    //component={onViewSearchLink(searchString,0)}
                    >
                        <SearchIcon className={classes.iconStyle} />
                    </ListItemIcon>

                    <ListItemText primary="View Search" />
                </MenuItem>

                <MenuItem onClick={onDelete}>
                    <ListItemIcon >
                        <DeleteIcon className={classes.iconStyle} />
                    </ListItemIcon>

                    <ListItemText primary="Delete Search" />
                </MenuItem>
            </Menu>
        </div>
    );
}

export default DeleteItemButton