import React, { useState } from "react";
//import PropTypes from "prop-types";
import { Theme, useMediaQuery } from "@mui/material";
import { createStyles, makeStyles, useTheme } from "@mui/styles";
import clsx from "clsx";

import { Sidebar, Topbar } from "./components";
import SidebarMini from "./components/SidebarMini";

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      paddingTop: 56,
      height: "100%",
      [theme.breakpoints.up("sm")]: {
        paddingTop: 64
      }
    },
    shiftContentSmall: {
      paddingLeft: 80
    },
    shiftContent: {
      paddingLeft: 80
    },
    content: {
      height: "100%"
    }
  })
);

interface PropsType {
  children: React.ReactNode;
}

const Main = (props: PropsType) => {
  const { children } = props;

  const classes = useStyles();
  const theme = useTheme<Theme>();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState<boolean>(false);

  //const [hover, setHover] = useState<boolean>(false);
  const handleSidebarOpen = () => {
    setOpenSidebar(!openSidebar);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(!openSidebar);
  };
  /*
  const handleMouseOver = () => {
    setHover(true)
  }

  const handleMouseLeave = () => {
    setHover(false)
  }
  */
  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
        //[classes.shiftContentSmall]: !hover && isDesktop
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <div
      //onMouseOver={handleMouseOver}
      //onMouseLeave={handleMouseLeave}
      >
        {!isDesktop ? (
          <Sidebar
            onClose={handleSidebarClose}
            open={shouldOpenSidebar}
            variant={isDesktop ? "persistent" : "temporary"}
            handleSidebarOpen={handleSidebarOpen}
          />
        ) : (
          <SidebarMini
            onClose={handleSidebarClose}
            open={shouldOpenSidebar}
            variant="persistent"
          />
        )}
      </div>
      <main className={classes.content}>
        {children}
        {/* <Footer /> */}
      </main>
    </div>
  );
};

export default Main;
