import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
//import { useHistory } from "react-router-dom";
import ExperienceCard from "../ExperienceCard";
import { EmployeeData } from "types";

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    titleHeading: {
      fontSize: theme.typography.pxToRem(15),
      marginBottom: "8px"
    },
    iconTitleHeading: {
      color: theme.palette.text.secondary,
      marginBottom: "8px"
    },
    textIconWrapper: {
      display: "inline-block",
      marginRight: "30px"
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15)
    },
    fullWidth: {
      width: "100%"
    },
    paperRoot: {
      width: "100%",
      marginBottom: theme.spacing(2),
      textAlign: "center"
    },
    linkText: {
      fontSize: theme.typography.pxToRem(14),
      paddingRight: "5px",
      color: theme.palette.info.main
    },
    chipStyle: {
      fontSize: theme.typography.pxToRem(14),
      marginRight: "5px",
      marginBottom: "5px"
    },
    largeAvatar: {
      width: "150px",
      height: "150px",
      display: "inline-block"
    },
    iconClass: {
      paddingRight: "5px",
      color: theme.palette.text.secondary
    },
    displayRight: {
      textAlign: "right"
    }
  })
);

interface PropTypes {
  className?: string;
  data: EmployeeData;
}

const Skills = (props: PropTypes) => {
  const { data } = props;
  const classes = useStyles();
 // const history = useHistory();
  const tags = data.skills.map(value => {
    return value.skill;
  });

  return (
    <div className={classes.fullWidth}>
      <Typography variant="h4" component="h2">
        {"Skills (" + data.skills.length + ")"}
      </Typography>
      <ExperienceCard
        data={{
          tags: tags
        }}
      />
    </div>
  );
};

export default Skills;
