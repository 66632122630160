import {TabPanel} from "components"
import {makeStyles , createStyles} from '@mui/styles'
import { Tabs  , Tab  } from '@mui/material';
import React from 'react';
import CompanyBackground from '../CompanyBackground';
import CompanyFiles from "../CompanyFiles";
import CompanySheets from "../CompanySheets";
import DealBrief from "../DealBrief";
import EmployeeOverview from '../EmployeeOverview';
// import GrowthOverview from "../GrowthOverview";
import InvestorFeedback from "../InvestorFeedback";
import PartershipOverview from "../PartnershipOverview";
import TeamAnalysis from "../TeamAnalysis";
import {Theme} from '@mui/material/styles';
import GrowthOverview from "../GrowthOverview";

function a11yProps(index:any) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles((theme:Theme) => createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      height: "100%",
      minHeight:"600px"
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
      minWidth:"250px",
      backgroundColor: theme.palette.background.paper,

    },
    indicator: {
        backgroundColor: theme.palette.primary.main,
      },
      tabBody:{
        width: "100%"
      }
  }));
  type tabBarProps = {
    data:any,
    value:number,
    handleChange:any,
    seniority:any,
    offsetEmployee:any,
    currentEmployees:any
  }

const TabBar = (props:tabBarProps) => {
    const classes = useStyles();
    const { data, value,handleChange} = props;

    const tabPanel:any = []
    const tabs:any = []
    var index = 1

    const handleChangeTab = (obj:any) =>{
      obj["pathValue"] = value
      handleChange(obj)
    }

      tabs.push(<Tab label="Team Overview" {...a11yProps(2)} />)
      tabPanel.push(<TabPanel className = {classes.tabBody} value={value} index={index}>
        <EmployeeOverview data={data} handleChange={handleChangeTab} seniority='' offsetEmployee={0} currentEmployees={0}></EmployeeOverview>
        </TabPanel>)
        index++
    
        tabs.push(<Tab label="Growth Analysis" {...a11yProps(3)} />)
        tabPanel.push(<TabPanel className = {classes.tabBody} value={value} index={index}>
                  <GrowthOverview data={data}/>
         </TabPanel>)
          index++

          tabs.push(<Tab label="Team Analysis" {...a11yProps(3)} />)
          tabPanel.push(<TabPanel className = {classes.tabBody} value={value} index={index}>
                    <TeamAnalysis data={data}/>
           </TabPanel>)
            index++

          tabs.push(<Tab label="Partnerships" {...a11yProps(3)} />)
          tabPanel.push(<TabPanel className = {classes.tabBody} value={value} index={index}>
                    <PartershipOverview data={data}/>
           </TabPanel>)
            index++

        tabs.push(<Tab label="Scorecards" {...a11yProps(3)} />)
        tabPanel.push(<TabPanel className = {classes.tabBody} value={value} index={index}>
                  <InvestorFeedback data={data}/>
         </TabPanel>)
          index++

          if(data.codadocs && data.codadocs.length > 0){
          tabs.push(<Tab label="Coda Documents" {...a11yProps(3)} />)
          tabPanel.push(<TabPanel className = {classes.tabBody} value={value} index={index}>
                    <DealBrief data={data.codadocs}/>
           </TabPanel>)
            index++
          }

          if(data.googledocs && data.googledocs.length > 0){
            tabs.push(<Tab label="Company Files" {...a11yProps(3)} />)
            tabPanel.push(<TabPanel className = {classes.tabBody} value={value} index={index}>
                      <CompanyFiles data={data.googledocs}/>
             </TabPanel>)
              index++
            }

            if(data.googlesheets && data.googlesheets.length > 0){
              tabs.push(<Tab label="Company Financials" {...a11yProps(3)} />)
              tabPanel.push(<TabPanel className = {classes.tabBody} value={value} index={index}>
                        <CompanySheets data={data.googlesheets}/>
               </TabPanel>)
                index++
              }
      

  /*
      tabs.push(<Tab label="Growth Metrics" {...a11yProps(3)} />)
      tabPanel.push(<TabPanel className = {classes.tabBody} value={value} index={index}>
          <GrowthAnalytics data={data}/>
       </TabPanel>)
        index++
    
    */

    return (
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={(e,newValue) => handleChange({"pathValue":newValue})}
          aria-label="Vertical tabs example"
          className={classes.tabs}
          indicatorColor="primary"
            
        >
          <Tab label="Company Overview" {...a11yProps(0)} />
          {tabs}
        </Tabs>
        <TabPanel className = {classes.tabBody} value={value} index={0}>
        <CompanyBackground data={data}></CompanyBackground>
        </TabPanel>
        {tabPanel}
      </div>
    );
  }

  export default TabBar