import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import CheckIcon from "@mui/icons-material/Check";
import { Button, Chip, DialogContentText, FormHelperText, Grid, Select, SelectChangeEvent, TextField } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import gql from "graphql-tag";
import { updatePortfolio, createPortfolio } from "graphql/mutations";
import { searchInverstor } from "graphql/queries";
import { debounce, isEmpty } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Investors, portfolioData } from "types";
import AutoCompleteInputFromHook from "views/KanbanView/CompanyCard/AutoCompleteInput";

//Last round select options ENUM
//!! Need to fetch from API.
const LAST_ROUND_OPTIONS = ["Seed", "Series A", "Series A-1", "Series A-2", "Series A-3", "Series B", "Series C", "Series D", "Series E", "Series F"];

// Initial state for add new portfolio form.
//string | number | Investors[]|dayjs.Dayjs,
interface ValObj<T> {
  value: T;
  error: boolean;
  errorMessage: string;
}
interface PortfolioFromState {
  companyname: ValObj<string>;
  companyurl: ValObj<string>;
  initialinvestment: ValObj<string>;
  cost: ValObj<string>;
  fmv: ValObj<string>;
  lastround: ValObj<string>;
  coinvestors: ValObj<Investors[]>;
  ceo: ValObj<string>;
  linkedinUrl: ValObj<string>;
}

/**
 * @description This function is for validating from details.
 */

const validatePortfolioForm = (data: PortfolioFromState, setState: React.Dispatch<React.SetStateAction<PortfolioFromState>>): boolean => {
  let isValid = true;
  const { companyname, coinvestors, initialinvestment, cost, fmv, lastround, companyurl } = data;

  if (isEmpty(companyname.value)) {
    isValid = false;
    data.companyname.error = true;
  }
  if (isEmpty(companyurl.value)) {
    isValid = false;
    data.companyurl.error = true;
  }
  if (isEmpty(cost.value)) {
    isValid = false;
    data.cost.error = true;
  }
  if (isEmpty(fmv.value)) {
    isValid = false;
    data.fmv.error = true;
  }
  if (isEmpty(initialinvestment.value)) {
    isValid = false;
    data.initialinvestment.error = true;
  }
  if (!moment(initialinvestment.value).isValid()) {
    isValid = false;
    data.initialinvestment.error = true;
    data.initialinvestment.errorMessage = "Please enter a valid date to the field.";
  }
  if (isEmpty(coinvestors.value)) {
    isValid = false;
    data.coinvestors.error = true;
  }
  if (isEmpty(lastround.value)) {
    isValid = false;
    data.lastround.error = true;
  }

  setState({ ...data });

  return isValid;
};

const INITIAL_STATE: PortfolioFromState = {
  companyname: {
    value: "",
    error: false,
    errorMessage: "Enter the name of the company"
  },
  companyurl: {
    value: "",
    error: false,
    errorMessage: "Website of the company."
  },
  initialinvestment: {
    value: dayjs(new Date()).toISOString(),
    error: false,
    errorMessage: "Date of initial statement"
  },
  cost: {
    value: "",
    error: false,
    errorMessage: "Enter amount in million $."
  },
  fmv: {
    value: "",
    error: false,
    errorMessage: "Enter amount in million$"
  },
  lastround: {
    value: "",
    error: false,
    errorMessage: "Last round date."
  },
  coinvestors: {
    value: [],
    error: false,
    errorMessage: "Name of investors separated by comma(,)"
  },

  ceo: {
    value: "",
    error: false,
    errorMessage: "Enter the name of the ceo."
  },
  linkedinUrl: {
    value: "",
    error: false,
    errorMessage: "Linkedin url of the ceo's profile."
  }
};

interface CompProps {
  onClose: Function;
  onResult?: (data: portfolioData[], count: number) => void;
  mode: "add" | "edit";
  editData?: portfolioData;
}

/**
 * @description This component is modal form for adding new value to the DB.
 */
const AddEdiPortfolioFrom = ({ onClose, onResult, mode, editData }: CompProps) => {
  // State for the controlling form
  const [data, setPortfolioData] = useState<PortfolioFromState>(JSON.parse(JSON.stringify(INITIAL_STATE)));
  const [update, { data: updateResponse, loading: updateLoading, error: updateError }] = useMutation<{ updatePortfolio: { result: portfolioData[]; message: string; count: number } }>(gql(updatePortfolio));
  const [create, { data: createResponse, loading: createLoading, error: createError }] = useMutation<{ createPortfolio: { result: portfolioData[]; message: string; count: number } }>(gql(createPortfolio));
  // const [getUpdateQuery, { data: response, error, loading }] = useLazyQuery<{ portfolioListQuery: { count: number; result: portfolioData[]; message: string } }>(gql(portfolioListQuery));

  // ready form based on mode
  React.useEffect(() => {
    // if form is open for editing
    if (mode === "edit" && editData) {
      const { companyname, ceo, cost, fmv, coinvestors, companyurl, initialinvestment, lastround } = editData;
      setPortfolioData(prevData => {
        prevData.companyname.value = companyname;
        prevData.ceo.value = ceo?.name ?? "";
        prevData.linkedinUrl.value = ceo?.linkedinUrl ?? "";
        prevData.cost.value = "" + cost ?? "";
        prevData.fmv.value = "" + fmv ?? "";
        prevData.companyurl.value = companyurl ?? "";
        prevData.coinvestors.value = coinvestors ?? [];
        prevData.initialinvestment.value = dayjs(new Date(initialinvestment)).toISOString();
        prevData.lastround.value = lastround ?? "";
        return { ...prevData };
      });
    }

    if (mode === "edit" && !editData) {
      setPortfolioData(JSON.parse(JSON.stringify(INITIAL_STATE)));
      if (onClose) onClose();
      toast.warning("Edit operation for this record couldn't be completed due to lack of unique identifier.");
    }

    if (mode === "add")
      // reset the state
      setPortfolioData(JSON.parse(JSON.stringify(INITIAL_STATE)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = event.target;
    if (type === "number") {
      setPortfolioData(_data => {
        ///@ts-ignore
        if (!value) _data[name].value = "";
        //@ts-ignore
        else if (value.match(/^-?\d*(\.\d+)?$/) && parseFloat(value) >= 0) _data[name].value = value;
        return { ..._data };
      });
    } else {
      setPortfolioData(_data => {
        //@ts-ignore
        _data[name].value = value;
        return { ..._data };
      });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validatePortfolioForm(data, setPortfolioData)) {
      const { companyname, initialinvestment, cost, fmv, lastround, coinvestors, ceo, companyurl, linkedinUrl } = data;
      if (mode === "edit")
        update({
          variables: {
            input: {
              companyname: companyname.value,
              initialinvestment: initialinvestment.value,
              cost: cost.value,
              fmv: fmv.value,
              lastround: lastround.value,
              coinvestors: coinvestors.value.map(d => ({ investor: d.investor, dealid: d.dealid })),
              ceo: { name: ceo.value, linkedinUrl: linkedinUrl.value },
              companyurl: companyurl.value,
              uuid: editData?.uuid ?? ""
            }
          }
        });
      if (mode === "add") {
        create({
          variables: {
            input: {
              companyname: companyname.value,
              initialinvestment: initialinvestment.value,
              cost: cost.value,
              fmv: fmv.value,
              lastround: lastround.value,
              coinvestors: coinvestors.value.map(d => ({ investor: d.investor, dealid: d.dealid })),
              ceo: { name: ceo.value, linkedinUrl: linkedinUrl.value },
              companyurl: companyurl.value
            }
          }
        });
      }
    }
  };

  React.useEffect(() => {
    if (updateResponse) {
      const { result, message, count } = updateResponse.updatePortfolio;
      if (onResult && result) onResult(result, count);
      if (message) toast.info(message);
      if (onClose) onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateResponse]);

  // handler create api response
  React.useEffect(() => {
    if (createResponse) {
      const { result, message, count } = createResponse.createPortfolio;
      if (onResult && result) onResult(result, count);
      if (message) toast.info(message);
      if (onClose) onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createResponse]);

  // States For Handling Investors Search
  const [investorOptions, setInvestorsOptions] = React.useState<Investors[] | []>([]);
  const [searchInvestors, { data: investorsSearchResult, loading: investorsLoading }] = useLazyQuery<{ searchInverstor: { investor: Investors[] } }>(gql(searchInverstor));

  // debouncing for searchTerm
  const handleInvestorsSearch = (queryText: string) => {
    searchInvestors({ variables: { search_term: queryText } });
  };

  //eslint-disable-next-line
  const debouncedQuery = React.useCallback(
    debounce((queryText: string) => handleInvestorsSearch(queryText), 1000),
    [investorOptions]
  );

  React.useEffect(() => {
    if (updateError) toast.error(updateError.message);
    if (createError) toast.error(createError.message);
  }, [updateError, createError]);

  //handler select menu change
  /**
   * @description This event handler is for the select menu specifically.
   */
  const handleSelectMenuChange = (event: SelectChangeEvent) => {
    setPortfolioData(_data => {
      _data["lastround"].value = event.target.value as string;
      return { ..._data };
    });
  };

  // When we have search text reasult;
  React.useEffect(() => {
    if (investorsSearchResult?.searchInverstor) {
      const data = investorsSearchResult.searchInverstor.investor;
      setInvestorsOptions(data);
    }
  }, [investorsSearchResult]);

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <DialogContentText>To add new record to the portfolio add all the required details.</DialogContentText>
        <Grid container spacing={1} sx={{ marginTop: 2 }}>
          <Grid item md={6}>
            <TextField
              value={data.companyname.value}
              required
              InputProps={{
                readOnly: mode === "edit"
              }}
              disabled={mode === "edit"}
              helperText={data.companyname.errorMessage}
              error={data.companyname.error}
              label="Name of company"
              name="companyname"
              fullWidth
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              value={data.companyurl.value}
              required
              helperText={data.companyurl.errorMessage}
              error={data.companyurl.error}
              label="Website of company"
              InputProps={{
                readOnly: mode === "edit"
              }}
              disabled={mode === "edit"}
              name="companyurl"
              fullWidth
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Initial Investment date"
                value={dayjs(new Date(data.initialinvestment.value))}
                onChange={newValue => {
                  // console.log(newValue)
                  if (newValue?.isValid()) {
                    setPortfolioData(_data => {
                      _data["initialinvestment"].value = newValue!.toISOString();
                      return { ..._data };
                    });
                  } else {
                    setPortfolioData(_data => {
                      _data["initialinvestment"].error = true;
                      _data["initialinvestment"].errorMessage = "Please Enter a valid date.";
                      return { ..._data };
                    });
                  }
                }}
                renderInput={params => <TextField required fullWidth helperText={data.initialinvestment.errorMessage} error={data.initialinvestment.error} variant="outlined" {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={6}>
            <TextField type={"number"} value={data.cost.value} required helperText={data.cost.errorMessage} error={data.cost.error} label="Cost( in $ million)" name="cost" fullWidth variant="outlined" onChange={handleChange} />
          </Grid>
          <Grid item md={6}>
            <TextField type={"number"} value={data.fmv.value} required helperText={data.fmv.errorMessage} error={data.cost.error} label="FMV(in $ million)" name="fmv" fullWidth variant="outlined" onChange={handleChange} />
          </Grid>
          <Grid item md={6}>
            <FormControl fullWidth required error={data.lastround.error}>
              <InputLabel id="last-round-menu-select-menu">Last Round</InputLabel>
              <Select labelId="last-round-menu-select-menu" id="demo-simple-select" value={data.lastround.value} label="Last Round" name="lastround" onChange={handleSelectMenuChange}>
                {LAST_ROUND_OPTIONS.map((item, index) => (
                  <MenuItem value={item} key={`last-round-menu-select-options-${index + 1}`}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{data.lastround.errorMessage}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <AutoCompleteInputFromHook
              id={`${mode}-co-investors-service`}
              loading={investorsLoading}
              error={data.coinvestors.error}
              helperText={data.coinvestors.errorMessage}
              label="Co-Investors"
              value={data.coinvestors.value}
              onInputChange={(event, newInputValue) => {
                newInputValue.length > 0 && debouncedQuery(newInputValue);
              }}
              options={investorOptions}
              getOptionLabel={Option => Option.investor}
              onChange={(event, value, reason, details) => {
                setPortfolioData(_data => {
                  _data["coinvestors"].value = value;
                  return { ..._data };
                });
              }}
              renderTag={(option, index, getTagProps) => (
                <Chip
                  //something
                  label={option.investor}
                  variant="outlined"
                  size="small"
                  sx={{ marginRight: "3px", marginBottom: "3px" }}
                  {...getTagProps({ index })}
                />
              )}
              renderDisplayListItem={(option, index, selected) => (
                <>
                  <span>{option.investor}</span>
                  <CheckIcon style={{ display: "inline-block" }} />
                </>
              )}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              value={data.ceo.value}
              helperText={data.ceo.errorMessage}
              error={data.ceo.error}
              //required
              label="Company CEO"
              name="ceo"
              fullWidth
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              value={data.linkedinUrl.value}
              //required
              helperText={data.linkedinUrl.errorMessage}
              error={data.linkedinUrl.error}
              label="CEO's Linkedin url"
              name="linkedinUrl"
              fullWidth
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (onClose) onClose();
          }}
        >
          Cancel
        </Button>
        <Button type="submit" disabled={updateLoading || createLoading}>
          Submit
        </Button>
      </DialogActions>
    </form>
  );
};
export default AddEdiPortfolioFrom;
