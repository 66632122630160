import { Link, Typography } from "@mui/material";
import { makeStyles , createStyles } from '@mui/styles';
import ShowMore from 'components/ShowMore';
import React from 'react';
import ExperienceCard from '../ExperienceCard';

const useStyles = makeStyles(theme => createStyles({
    titleHeading: {
        fontSize: theme.typography.pxToRem(15),
        marginBottom: "8px"
    },
    iconTitleHeading: {
        color: theme.palette.text.secondary,
        marginBottom: "8px"
    },
    textIconWrapper: {
        display: "inline-block",
        marginRight: "30px"
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15)
    },
    fullWidth: {
        "width": "100%"
    },
    paperRoot: {
        width: "100%",
        marginBottom: theme.spacing(2),
        textAlign: "center"

    },
    linkText: {
        fontSize: theme.typography.pxToRem(14),
        paddingRight: "5px",
        color: theme.palette.info.main
    },
    chipStyle: {
        fontSize: theme.typography.pxToRem(14),
        marginRight: "5px",
        marginBottom: "5px",
    },
    largeAvatar: {
        width: "150px",
        height: "150px",
        display: "inline-block"
    },
    iconClass: {
        paddingRight: "5px",
        color: theme.palette.text.secondary
    },
    displayRight: {
        textAlign: "right"
    }
}));

const Education = props => {
    const { data } = props;
    const classes = useStyles();
    const sortedEds = data["ed"]
    sortedEds.sort(function(a, b) {
        return parseInt(b.startepoch) - parseInt(a.startepoch);
    });
    return (
        <div className={classes.fullWidth}>
                    <Typography gutterBottom gutterTop variant="h4" component="h2">
                        Education1
            </Typography>
            <ShowMore data=
                    {sortedEds.map(ed => {
                        
                        var date = ""
                        if (ed.startdate) {
                            date = ed.startdate
                        }
                        if (ed.enddate && (ed.enddate !== "Present" || date !== "")) {
                            if(date !== ""){
                                date += " - "
                            }
                                date += ed.enddate
               
                        }

                        var subHeader = ""
                        if(ed.schooldegree){
                            subHeader = ed.schooldegree 

                        }
                        if (ed.subject){
                            if(subHeader !== ""){
                                subHeader += " "
                            }
                            subHeader += ed.subject
                        }
                        return (

                            <ExperienceCard data={{
                                header: ed.schoollink && ed.schoollink !== "-" ? <Link color="inherit" href={ed.schoollink}>{ed.school}</Link> : ed.school,
                                subHeader: subHeader,
                                subTitle1: date,
                                image: ed.imagelink && ed.imagelink !== "-" ? ed.imagelink : "/images/default-corporate-image.jpg",
                                tags: ed.schooltags
                            }} />)
                    })} trimIndex={4}/>
        </div>
    );

};

export default Education;
