/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const processListQuery = /* GraphQL */ `
  query ProcessListQuery {
    processListQuery
  }
`;
export const getPortfolioList = /* GraphQL */ `
  query GetPortfolioList($limit: Int, $offset: Int) {
    getPortfolioList(limit: $limit, offset: $offset) {
      result {
        companyname
        initialinvestment
        cost
        fmv
        lastround
        lastrounddate
        coinvestors {
          investor
          dealid
        }
        ceo {
          name
          linkedinUrl
        }
        companyurl
        uuid
      }
      count
      message
    }
  }
`;
export const personUUIDQuery = /* GraphQL */ `
  query PersonUUIDQuery($LinkedInUUID: String) {
    PersonUUIDQuery(LinkedInUUID: $LinkedInUUID) {
      employeeuuid
      backgroundstrength
      ed {
        schooldegree
        edstrength
        endepoch
        imagelink
        school
        schoolintuuid
        schoollink
        startepoch
        enddate
        startdate
        subject
      }
      experience
      fulltext
      personname
      liheadline
      linkedinurl
      profilelocation
      profilepicture
      salesnavlink
      shortdescription
      skills {
        skill
      }
      volunteer {
        cause
        company
        companylink
        companylinksales
        description
        duration
        enddate
        endepoch
        imagelink
        volunteerrole
        startdate
        startepoch
        companyintuuid
      }
      interests {
        followercount
        grouplink
        imagelink
        interestname
      }
      jobs {
        company
        companylink
        companylinksales
        department
        description
        duration
        enddate
        endepoch
        imagelink
        location
        rank
        companyrole
        rolestrength
        seniority
        startdate
        startepoch
        companyintuuid
        iscurrent
      }
      awards {
        award
        description1
        description2
      }
      certifications {
        certificate
        certificateend
        certificateendepoch
        certificatestart
        certificatestartepoch
        issuer
        issuerlink
        issuerlogo
        link
        issuerintuuid
      }
      languages {
        profilelanguage
        proficiency
      }
      patents {
        description
        filingdate
        filingdateepoch
        patentlink
        patentnum
        title
      }
      projects {
        description
        projectend
        projectendepoch
        projectlink
        projectstart
        projectstartepoch
        projecttitle
      }
      publications {
        description
        publicationdate
        publicationdateepoch
        publicationlink
        publisher
        title
      }
    }
  }
`;
export const allEmployeeQuery = /* GraphQL */ `
  query AllEmployeeQuery(
    $CompanyUUID: String
    $Seniority: String
    $Offset: Int
    $onlyCurrent: Boolean
  ) {
    AllEmployeeQuery(
      CompanyUUID: $CompanyUUID
      Seniority: $Seniority
      Offset: $Offset
      onlyCurrent: $onlyCurrent
    ) {
      employeeuuid
      backgroundstrength
      personname
      liheadline
      linkedinurl
      profilelocation
      profilepicture
      seniority
      department
      enddate
      endepoch
      companyrole
      imagelink
      startdate
      startepoch
      iscurrent
    }
  }
`;
export const companyUUIDQuery = /* GraphQL */ `
  query CompanyUUIDQuery($CompanyUUID: String) {
    CompanyUUIDQuery(CompanyUUID: $CompanyUUID) {
      uuid
      websitefinal
      hashkey
      seriesmerged
      namefinal
      cburl
      pburl
      cid
      facebook
      twitter
      listringuuid
      liintuuid
      totalfunding
      acquisitionstatus
      finaldescription
      locationfinal
      linkedinfinal
      trafficgrowth6
      trafficgrowth3
      trafficpercentile6
      trafficpercentile3
      facebooklikes
      facebookfollows
      twitterfollows
      twitterfollowers
      capscore
      g2score
      reviewgrowthpercentile6
      reviewgrowthpercentile3
      semtraffic
      ahstraffic
      swtraffic
      tptotalreviewcount
      tptotalreviewscore
      tpreviewscount3
      tpreviewsscore3
      tpreviewscount6
      tpreviewsscore6
      g2totalreviewcount
      g2totalreviewscore
      g2reviewscount3
      g2reviewsscore3
      g2reviewscount6
      g2reviewsscore6
      captotalreviewcount
      captotalreviewscore
      capreviewscount3
      capreviewsscore3
      capreviewscount6
      capreviewsscore6
      g2growthpercentile6
      capgrowthpercentile6
      tpgrowthpercentile6
      g2growthpercentile3
      capgrowthpercentile3
      tpgrowthpercentile3
      scorecardsa {
        scorecardid
        companyuuid
        author
        companyurl
        meetingdate
        completedate
        advorpass
        reasonpass
        reasonadv
        adadr
        passadr
        dealsourceli
        dealsourcef
        sourcedby
        dealstamp
        completionstamp
      }
      scorecardsb {
        scorecardid
        companyuuid
        author
        companyurl
        meetingdate
        completedate
        deallead
        founderstr
        founderjust
        teamstr
        teamjust
        marketstr
        marketjust
        productstr
        productjust
        bmstr
        bmjust
        dealstr
        dealjust
        toptierprob
        toptierprob2
        moveic
        noreason
        ddreason
        yesreason
        dealstamp
        completionstamp
      }
      scorecardsc {
        scorecardid
        companyuuid
        author
        companyurl
        meetingdate
        completedate
        deallead
        f1
        f2
        f3
        f4
        f5
        f6
        f7
        f8
        f9
        founderexp
        founderstr
        t1
        t2
        t3
        t4
        t5
        teamexp
        teamstr
        m1
        m2
        m3
        m4
        m5
        marketexp
        marketstr
        btype
        b2bp1
        b2bp2
        b2bp3
        b2bp4
        b2bp5
        b2bp6
        b2bp7
        b2bp8
        b2bprodexp
        b2bprodstr
        b2cp1
        b2cp2
        b2cp3
        b2cp4
        b2cp5
        b2cp6
        b2cprodexp
        b2cprodstr
        b2bbm1
        b2bbm2
        b2bbm3
        b2bbm4
        b2bbm5
        b2bbm6
        b2bbm7
        b2bbm8
        b2bbm9
        b2bbm10
        b2bbm11
        b2bbmexp
        b2bbmstr
        b2cbm1
        b2cbm2
        b2cbm3
        b2cbm4
        b2cbm5
        b2cbm6
        b2cbm7
        b2cbm8
        b2cbm9
        b2cbmexp
        b2cbmstr
        p1
        p2
        p3
        p4
        p5
        p6
        pexp
        pstr
        redflag
        icdecile
        betsize
        toptierprob
        toptierprob2
        exit10x
        dealstamp
        completionstamp
        monkey
        monkeyexp
      }
      scorecardspartner {
        scorecardid
        companyuuid
        author
        companyurl
        meetingdate
        completedate
        deallead
        f1
        f2
        f3
        f4
        f5
        f6
        f7
        f8
        f9
        founderexp
        founderstr
        t1
        t2
        t3
        t4
        t5
        teamexp
        teamstr
        m1
        m2
        m3
        m4
        m5
        marketexp
        marketstr
        btype
        b2bp1
        b2bp2
        b2bp3
        b2bp4
        b2bp5
        b2bp6
        b2bp7
        b2bp8
        b2bprodexp
        b2bprodstr
        b2cp1
        b2cp2
        b2cp3
        b2cp4
        b2cp5
        b2cp6
        b2cprodexp
        b2cprodstr
        b2bbm1
        b2bbm2
        b2bbm3
        b2bbm4
        b2bbm5
        b2bbm6
        b2bbm7
        b2bbm8
        b2bbm9
        b2bbm10
        b2bbm11
        b2bbmexp
        b2bbmstr
        b2cbm1
        b2cbm2
        b2cbm3
        b2cbm4
        b2cbm5
        b2cbm6
        b2cbm7
        b2cbm8
        b2cbm9
        b2cbmexp
        b2cbmstr
        p1
        p2
        p3
        p4
        p5
        p6
        pexp
        pstr
        redflag
        icdecile
        betsize
        toptierprob
        toptierprob2
        exit10x
        dealstamp
        completionstamp
        monkey
        monkeyexp
      }
      verticals {
        vertical
        companyuuid
      }
      headcountgrowth {
        companyuuid
        industryname
        departmentcount
        departmentgrowth12
        departmentgrowth6
        departmentgrowth3
        departmentgrowthpercentile12
        departmentgrowthpercentile6
        departmentgrowthpercentile3
        headcountdata
      }
      fundingroundspb {
        dealdate
        pbcid
        dealdateepoch
        dealid
        dealsize
        dealtype
        investors
        leadinvestors
        postmoneyvaluation
        series
      }
      scorecards {
        websitefinal
        feedbackfor
        dateofmeeting
        meetingcontext
        whichrenegades
        whofromcompany
        team
        teamstrength
        teamrationale
        market
        marketstrength
        marketrationale
        product
        productstrength
        productrationale
        businessmodel
        businessmodelstrength
        businessmodelrationale
        potentialdeal
        potentialdealstrength
        potentialdealrationale
        feedbacktopics
        moveforward
        rationaleforward
        toptierprob
        nextsteps
        author
      }
      leadinvestors {
        investor
        dealid
      }
      investors {
        investor
        dealid
      }
      webev3m
      webev6m
      webev12m
      evolutionpercentile3
      evolutionpercentile6
      evolutionpercentile12
      medianjobopeningtime
      jobcountyear
      opencount
      jobopeningpercentile
      webgrowthpercentile3
      webgrowthpercentile6
      codadocs {
        doctitle
        codaurl
      }
      googledocs {
        driveid
        foldertitle
      }
      googlesheets {
        sheetid
        sheettitle
      }
      cihcg
      cihcgpercentile
      growthpercentile12evm
      growthpercentile6evm
      growthpercentile3evm
    }
  }
`;
export const companySearch = /* GraphQL */ `
  query CompanySearch(
    $NameContains: String
    $InvestorName: [String]
    $LeadInvestorName: [String]
    $Verticals: [String]
    $NextSeries: String
    $Offset: Int
  ) {
    CompanySearch(
      NameContains: $NameContains
      InvestorName: $InvestorName
      LeadInvestorName: $LeadInvestorName
      Verticals: $Verticals
      NextSeries: $NextSeries
      Offset: $Offset
    ) {
      company {
        uuid
        websitefinal
        hashkey
        seriesmerged
        namefinal
        cburl
        pburl
        cid
        facebook
        twitter
        listringuuid
        liintuuid
        totalfunding
        acquisitionstatus
        finaldescription
        locationfinal
        linkedinfinal
        trafficgrowth6
        trafficgrowth3
        trafficpercentile6
        trafficpercentile3
        facebooklikes
        facebookfollows
        twitterfollows
        twitterfollowers
        capscore
        g2score
        reviewgrowthpercentile6
        reviewgrowthpercentile3
        semtraffic
        ahstraffic
        swtraffic
        tptotalreviewcount
        tptotalreviewscore
        tpreviewscount3
        tpreviewsscore3
        tpreviewscount6
        tpreviewsscore6
        g2totalreviewcount
        g2totalreviewscore
        g2reviewscount3
        g2reviewsscore3
        g2reviewscount6
        g2reviewsscore6
        captotalreviewcount
        captotalreviewscore
        capreviewscount3
        capreviewsscore3
        capreviewscount6
        capreviewsscore6
        g2growthpercentile6
        capgrowthpercentile6
        tpgrowthpercentile6
        g2growthpercentile3
        capgrowthpercentile3
        tpgrowthpercentile3
        scorecardsa {
          scorecardid
          companyuuid
          author
          companyurl
          meetingdate
          completedate
          advorpass
          reasonpass
          reasonadv
          adadr
          passadr
          dealsourceli
          dealsourcef
          sourcedby
          dealstamp
          completionstamp
        }
        scorecardsb {
          scorecardid
          companyuuid
          author
          companyurl
          meetingdate
          completedate
          deallead
          founderstr
          founderjust
          teamstr
          teamjust
          marketstr
          marketjust
          productstr
          productjust
          bmstr
          bmjust
          dealstr
          dealjust
          toptierprob
          toptierprob2
          moveic
          noreason
          ddreason
          yesreason
          dealstamp
          completionstamp
        }
        scorecardsc {
          scorecardid
          companyuuid
          author
          companyurl
          meetingdate
          completedate
          deallead
          f1
          f2
          f3
          f4
          f5
          f6
          f7
          f8
          f9
          founderexp
          founderstr
          t1
          t2
          t3
          t4
          t5
          teamexp
          teamstr
          m1
          m2
          m3
          m4
          m5
          marketexp
          marketstr
          btype
          b2bp1
          b2bp2
          b2bp3
          b2bp4
          b2bp5
          b2bp6
          b2bp7
          b2bp8
          b2bprodexp
          b2bprodstr
          b2cp1
          b2cp2
          b2cp3
          b2cp4
          b2cp5
          b2cp6
          b2cprodexp
          b2cprodstr
          b2bbm1
          b2bbm2
          b2bbm3
          b2bbm4
          b2bbm5
          b2bbm6
          b2bbm7
          b2bbm8
          b2bbm9
          b2bbm10
          b2bbm11
          b2bbmexp
          b2bbmstr
          b2cbm1
          b2cbm2
          b2cbm3
          b2cbm4
          b2cbm5
          b2cbm6
          b2cbm7
          b2cbm8
          b2cbm9
          b2cbmexp
          b2cbmstr
          p1
          p2
          p3
          p4
          p5
          p6
          pexp
          pstr
          redflag
          icdecile
          betsize
          toptierprob
          toptierprob2
          exit10x
          dealstamp
          completionstamp
          monkey
          monkeyexp
        }
        scorecardspartner {
          scorecardid
          companyuuid
          author
          companyurl
          meetingdate
          completedate
          deallead
          f1
          f2
          f3
          f4
          f5
          f6
          f7
          f8
          f9
          founderexp
          founderstr
          t1
          t2
          t3
          t4
          t5
          teamexp
          teamstr
          m1
          m2
          m3
          m4
          m5
          marketexp
          marketstr
          btype
          b2bp1
          b2bp2
          b2bp3
          b2bp4
          b2bp5
          b2bp6
          b2bp7
          b2bp8
          b2bprodexp
          b2bprodstr
          b2cp1
          b2cp2
          b2cp3
          b2cp4
          b2cp5
          b2cp6
          b2cprodexp
          b2cprodstr
          b2bbm1
          b2bbm2
          b2bbm3
          b2bbm4
          b2bbm5
          b2bbm6
          b2bbm7
          b2bbm8
          b2bbm9
          b2bbm10
          b2bbm11
          b2bbmexp
          b2bbmstr
          b2cbm1
          b2cbm2
          b2cbm3
          b2cbm4
          b2cbm5
          b2cbm6
          b2cbm7
          b2cbm8
          b2cbm9
          b2cbmexp
          b2cbmstr
          p1
          p2
          p3
          p4
          p5
          p6
          pexp
          pstr
          redflag
          icdecile
          betsize
          toptierprob
          toptierprob2
          exit10x
          dealstamp
          completionstamp
          monkey
          monkeyexp
        }
        verticals {
          vertical
          companyuuid
        }
        headcountgrowth {
          companyuuid
          industryname
          departmentcount
          departmentgrowth12
          departmentgrowth6
          departmentgrowth3
          departmentgrowthpercentile12
          departmentgrowthpercentile6
          departmentgrowthpercentile3
          headcountdata
        }
        fundingroundspb {
          dealdate
          pbcid
          dealdateepoch
          dealid
          dealsize
          dealtype
          investors
          leadinvestors
          postmoneyvaluation
          series
        }
        scorecards {
          websitefinal
          feedbackfor
          dateofmeeting
          meetingcontext
          whichrenegades
          whofromcompany
          team
          teamstrength
          teamrationale
          market
          marketstrength
          marketrationale
          product
          productstrength
          productrationale
          businessmodel
          businessmodelstrength
          businessmodelrationale
          potentialdeal
          potentialdealstrength
          potentialdealrationale
          feedbacktopics
          moveforward
          rationaleforward
          toptierprob
          nextsteps
          author
        }
        leadinvestors {
          investor
          dealid
        }
        investors {
          investor
          dealid
        }
        webev3m
        webev6m
        webev12m
        evolutionpercentile3
        evolutionpercentile6
        evolutionpercentile12
        medianjobopeningtime
        jobcountyear
        opencount
        jobopeningpercentile
        webgrowthpercentile3
        webgrowthpercentile6
        codadocs {
          doctitle
          codaurl
        }
        googledocs {
          driveid
          foldertitle
        }
        googlesheets {
          sheetid
          sheettitle
        }
        cihcg
        cihcgpercentile
        growthpercentile12evm
        growthpercentile6evm
        growthpercentile3evm
      }
      count {
        count
      }
    }
  }
`;
export const growthDataQuery = /* GraphQL */ `
  query GrowthDataQuery(
    $CompanyUUID: String
    $QueryType: String
    $Offset: Int
  ) {
    GrowthDataQuery(
      CompanyUUID: $CompanyUUID
      QueryType: $QueryType
      Offset: $Offset
    ) {
      pages {
        category
      }
      vendor {
        partnerid
        firstepoch
        firstdate
        lastepoch
        lastdate
        url
        companyuuid
        counterpartyurl
        companyname
        ticker
        sourceurl
        partnershiptype
      }
      partner {
        partnerid
        firstepoch
        firstdate
        lastepoch
        lastdate
        url
        companyuuid
        counterpartyurl
        companyname
        ticker
        sourceurl
        partnershiptype
      }
      integration {
        partnerid
        firstepoch
        firstdate
        lastepoch
        lastdate
        url
        companyuuid
        counterpartyurl
        companyname
        ticker
        sourceurl
        partnershiptype
      }
    }
  }
`;
export const investorSearch = /* GraphQL */ `
  query InvestorSearch($InvestorName: String) {
    InvestorSearch(InvestorName: $InvestorName) {
      investorNames {
        investor
      }
    }
  }
`;
export const searchInverstor = /* GraphQL */ `
  query SearchInverstor($search_term: String) {
    searchInverstor(search_term: $search_term) {
      investor {
        investor
        dealid
      }
    }
  }
`;
export const leadsListQuery = /* GraphQL */ `
  query LeadsListQuery {
    LeadsListQuery {
      innetwork {
        investor
        leadDate
        uuid
        websitefinal
        hashkey
        seriesmerged
        namefinal
        totalfunding
        reviewgrowthpercentile6
        reviewgrowthpercentile3
        webgrowthpercentile3
        webgrowthpercentile6
        finaldescription
        cihcg
        cihcgpercentile
        cihcgtype
        profilepicture
        personname
        liheadline
        employeeid
      }
      outnetwork {
        uuid
        websitefinal
        hashkey
        seriesmerged
        namefinal
        cburl
        pburl
        cid
        facebook
        twitter
        listringuuid
        liintuuid
        totalfunding
        acquisitionstatus
        finaldescription
        locationfinal
        linkedinfinal
        trafficgrowth6
        trafficgrowth3
        trafficpercentile6
        trafficpercentile3
        facebooklikes
        facebookfollows
        twitterfollows
        twitterfollowers
        capscore
        g2score
        reviewgrowthpercentile6
        reviewgrowthpercentile3
        semtraffic
        ahstraffic
        swtraffic
        tptotalreviewcount
        tptotalreviewscore
        tpreviewscount3
        tpreviewsscore3
        tpreviewscount6
        tpreviewsscore6
        g2totalreviewcount
        g2totalreviewscore
        g2reviewscount3
        g2reviewsscore3
        g2reviewscount6
        g2reviewsscore6
        captotalreviewcount
        captotalreviewscore
        capreviewscount3
        capreviewsscore3
        capreviewscount6
        capreviewsscore6
        g2growthpercentile6
        capgrowthpercentile6
        tpgrowthpercentile6
        g2growthpercentile3
        capgrowthpercentile3
        tpgrowthpercentile3
        scorecardsa {
          scorecardid
          companyuuid
          author
          companyurl
          meetingdate
          completedate
          advorpass
          reasonpass
          reasonadv
          adadr
          passadr
          dealsourceli
          dealsourcef
          sourcedby
          dealstamp
          completionstamp
        }
        scorecardsb {
          scorecardid
          companyuuid
          author
          companyurl
          meetingdate
          completedate
          deallead
          founderstr
          founderjust
          teamstr
          teamjust
          marketstr
          marketjust
          productstr
          productjust
          bmstr
          bmjust
          dealstr
          dealjust
          toptierprob
          toptierprob2
          moveic
          noreason
          ddreason
          yesreason
          dealstamp
          completionstamp
        }
        scorecardsc {
          scorecardid
          companyuuid
          author
          companyurl
          meetingdate
          completedate
          deallead
          f1
          f2
          f3
          f4
          f5
          f6
          f7
          f8
          f9
          founderexp
          founderstr
          t1
          t2
          t3
          t4
          t5
          teamexp
          teamstr
          m1
          m2
          m3
          m4
          m5
          marketexp
          marketstr
          btype
          b2bp1
          b2bp2
          b2bp3
          b2bp4
          b2bp5
          b2bp6
          b2bp7
          b2bp8
          b2bprodexp
          b2bprodstr
          b2cp1
          b2cp2
          b2cp3
          b2cp4
          b2cp5
          b2cp6
          b2cprodexp
          b2cprodstr
          b2bbm1
          b2bbm2
          b2bbm3
          b2bbm4
          b2bbm5
          b2bbm6
          b2bbm7
          b2bbm8
          b2bbm9
          b2bbm10
          b2bbm11
          b2bbmexp
          b2bbmstr
          b2cbm1
          b2cbm2
          b2cbm3
          b2cbm4
          b2cbm5
          b2cbm6
          b2cbm7
          b2cbm8
          b2cbm9
          b2cbmexp
          b2cbmstr
          p1
          p2
          p3
          p4
          p5
          p6
          pexp
          pstr
          redflag
          icdecile
          betsize
          toptierprob
          toptierprob2
          exit10x
          dealstamp
          completionstamp
          monkey
          monkeyexp
        }
        scorecardspartner {
          scorecardid
          companyuuid
          author
          companyurl
          meetingdate
          completedate
          deallead
          f1
          f2
          f3
          f4
          f5
          f6
          f7
          f8
          f9
          founderexp
          founderstr
          t1
          t2
          t3
          t4
          t5
          teamexp
          teamstr
          m1
          m2
          m3
          m4
          m5
          marketexp
          marketstr
          btype
          b2bp1
          b2bp2
          b2bp3
          b2bp4
          b2bp5
          b2bp6
          b2bp7
          b2bp8
          b2bprodexp
          b2bprodstr
          b2cp1
          b2cp2
          b2cp3
          b2cp4
          b2cp5
          b2cp6
          b2cprodexp
          b2cprodstr
          b2bbm1
          b2bbm2
          b2bbm3
          b2bbm4
          b2bbm5
          b2bbm6
          b2bbm7
          b2bbm8
          b2bbm9
          b2bbm10
          b2bbm11
          b2bbmexp
          b2bbmstr
          b2cbm1
          b2cbm2
          b2cbm3
          b2cbm4
          b2cbm5
          b2cbm6
          b2cbm7
          b2cbm8
          b2cbm9
          b2cbmexp
          b2cbmstr
          p1
          p2
          p3
          p4
          p5
          p6
          pexp
          pstr
          redflag
          icdecile
          betsize
          toptierprob
          toptierprob2
          exit10x
          dealstamp
          completionstamp
          monkey
          monkeyexp
        }
        verticals {
          vertical
          companyuuid
        }
        headcountgrowth {
          companyuuid
          industryname
          departmentcount
          departmentgrowth12
          departmentgrowth6
          departmentgrowth3
          departmentgrowthpercentile12
          departmentgrowthpercentile6
          departmentgrowthpercentile3
          headcountdata
        }
        fundingroundspb {
          dealdate
          pbcid
          dealdateepoch
          dealid
          dealsize
          dealtype
          investors
          leadinvestors
          postmoneyvaluation
          series
        }
        scorecards {
          websitefinal
          feedbackfor
          dateofmeeting
          meetingcontext
          whichrenegades
          whofromcompany
          team
          teamstrength
          teamrationale
          market
          marketstrength
          marketrationale
          product
          productstrength
          productrationale
          businessmodel
          businessmodelstrength
          businessmodelrationale
          potentialdeal
          potentialdealstrength
          potentialdealrationale
          feedbacktopics
          moveforward
          rationaleforward
          toptierprob
          nextsteps
          author
        }
        leadinvestors {
          investor
          dealid
        }
        investors {
          investor
          dealid
        }
        webev3m
        webev6m
        webev12m
        evolutionpercentile3
        evolutionpercentile6
        evolutionpercentile12
        medianjobopeningtime
        jobcountyear
        opencount
        jobopeningpercentile
        webgrowthpercentile3
        webgrowthpercentile6
        codadocs {
          doctitle
          codaurl
        }
        googledocs {
          driveid
          foldertitle
        }
        googlesheets {
          sheetid
          sheettitle
        }
        cihcg
        cihcgpercentile
        growthpercentile12evm
        growthpercentile6evm
        growthpercentile3evm
      }
    }
  }
`;
export const getCompanyFavorite = /* GraphQL */ `
  query GetCompanyFavorite($id: ID!) {
    getCompanyFavorite(id: $id) {
      id
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCompanyFavorites = /* GraphQL */ `
  query ListCompanyFavorites(
    $filter: ModelCompanyFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyFavorites(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getSavedSearch = /* GraphQL */ `
  query GetSavedSearch($id: ID!) {
    getSavedSearch(id: $id) {
      id
      name
      searchJSON
      lastViewed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSavedSearchs = /* GraphQL */ `
  query ListSavedSearchs(
    $filter: ModelSavedSearchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSavedSearchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        searchJSON
        lastViewed
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getWorkflow = /* GraphQL */ `
  query GetWorkflow($id: ID!) {
    getWorkflow(id: $id) {
      id
      name
      chartJSON
      creationDate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listWorkflows = /* GraphQL */ `
  query ListWorkflows(
    $filter: ModelWorkflowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkflows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        chartJSON
        creationDate
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const companyName = /* GraphQL */ `
  query CompanyName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CompanyName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
