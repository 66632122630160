import { Box, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(theme => createStyles({
  boxRoot: {
    paddingTop:"0px",
    marginTop:"0px"
  },
  
}));
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const classes = useStyles();

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3} className={classes.boxRoot}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  export default TabPanel