import { Grid, MenuItem, Select, Typography } from '@mui/material';
import { makeStyles  , createStyles} from '@mui/styles';
import { HandleGrowth } from 'helpers';
import React from 'react';
import { CompanyData } from 'types';
import GrowthIndexGraph from '../GrowthIndexGraph';
import GrowthTable from '../GrowthTable';

const useStyles = makeStyles(theme => createStyles({
    root: {
        width: "100%"
    },
    ratingCard: {
        textAlign: "center"
    },
    opaqueReveal: {
        background: "grey",
        height: "500px",
        textAlign: "center"
    },
    revealText: {
        marginTop: "230px",
        color: "white",
        fontSize: "20px",
    },
    iconStyle: {
        color: "white",
        marginBottom: "5px"
    },
    selectCard: {
        marginLeft: "24px",
        paddingLeft: "8px",
        paddingRight: "8px"
    }
}));


const formatHeadcount = (headCounts:any, industry:any) => {
    var monthStart = new Date()
    monthStart.setDate(1)
    monthStart.setHours(0)
    monthStart.setMinutes(0)
    monthStart.setSeconds(0)
    monthStart.setMilliseconds(0)
    for (const headCount of headCounts) {
        if (headCount.industryname === industry) {
            const outList:any = []
            const trimList = headCount.headcountdata.slice(0, 12)
            for (var i = 0; i < trimList.length; i++) {
                const dateOld = new Date(monthStart.getTime())
                dateOld.setMonth(dateOld.getMonth() - i)
                outList.push({
                    x: dateOld.toISOString().replace(/T.*/, ''),
                    y: trimList[i]
                })
            }
            outList.reverse()
            return [{
                id: industry,
                data: outList
            }]
        }
    }
}
interface  teamAnalysisProps {
    data: CompanyData,
}


function TeamAnalysis(props:teamAnalysisProps) {

    const { data} = props;

    const [state, setState] = React.useState({
        headcountType: "All"
    });
    const classes = useStyles();

    const handleChange = (event:any) => {
        if (event.target.name === "headcountType") {
            setState({ ...state, [event.target.name]: event.target.value });
        }
    };
    const headcountGrowth = data && data.headcountgrowth ? formatHeadcount(data.headcountgrowth, state.headcountType) : null
   
    const headcountSelect = data && data.headcountgrowth ? <Select
        value={state.headcountType}
        onChange={handleChange}
        name="headcountType"
        className={classes.selectCard}
    > 
        {data.headcountgrowth.map((elem)=> {
            return <MenuItem value={elem.industryname}>{elem.industryname}</MenuItem>

        })}
    </Select> : ""

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography gutterBottom variant="h4" component="h2">
                        Team Analysis
              </Typography>
                </Grid>
                {data && data.opencount?
                <Grid item xs={12}>
                    <GrowthTable
                        data={[data]}
                        headers={HandleGrowth.jobOpeningVolumeHeader}
                        parentName={"Job Openings"}

                    />
                </Grid>

                : ""
                }
                {data && data.medianjobopeningtime?
                <Grid item xs={12}>
                    <GrowthTable
                        data={[data]}
                        headers={HandleGrowth.jobOpeningHeader}
                        parentName={"Job Opening Duration (1 Year)"}

                    />
                </Grid>

                : ""
                }
                {data.headcountgrowth && data.headcountgrowth.length > 0 && headcountGrowth ?
                    <Grid item xs={12}>
                        <GrowthIndexGraph inputData={headcountGrowth} title={<div> Headcount Growth {headcountSelect}</div>} legendY={"Employee Count"} />
                    </Grid> : ""
                }
            </Grid>
        </div>
    );
}

export default TeamAnalysis