import React from "react";
import { Route } from "react-router-dom";
import ErrorBoundary from "components/ErrorBoundary";

interface PropTypes {
  component: typeof React.Component;
  layout: typeof React.Component;
  path: string;
}

const RouteWithLayout = (props: PropTypes) => {
  const { layout: Layout, component: Component, path, ...rest } = props;

  return (
    <Route
      path={path}
      {...rest}
      render={matchProps => (
        <ErrorBoundary>
          <Layout>
            <Component {...matchProps} />
          </Layout>
        </ErrorBoundary>
      )}
    />
  );
};

export default RouteWithLayout;
