import React, { useEffect } from "react";
import { Snackbar, SnackbarCloseReason, Theme } from "@mui/material";
import { Alert as MuiAlert } from "@mui/lab";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2)
      }
    }
  })
);

interface PropTypes {
  message:React.ReactNode,
  isOpen:boolean
}

const SnackBarCustom = (props:PropTypes) => {
  const { message, isOpen } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = (event:Event| React.SyntheticEvent<any, Event>, reason:SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <MuiAlert elevation={6} variant="filled" onClose={()=> setOpen(false)} severity="success">
          {message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default SnackBarCustom;
