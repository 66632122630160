import { useQuery } from '@apollo/react-hooks';
import { Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { CompanyListBody } from 'components';
import gql from 'graphql-tag';
import { companySearch } from 'graphql/queries';
import { SearchHelper } from 'helpers';
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { SearchSideBar } from './components';
import {Theme} from '@mui/material/styles';





const queryString = require('qs');

const useStyles = makeStyles((theme:Theme) => createStyles({
  root: {
    width: '100%',
    padding: theme.spacing(3),

  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  details: {
    alignItems: 'center',
    width: '100%',
  },
  textField: {
    width: '100%',
    marginTop: '16px'
  },
  labelRoot: {
    fontSize: '16px'
  },
  mainHeading: {
    textAlign: "center"
  },
  noMargin:{
    marginTop:"0px !important",
    paddingTop:"0px !important"
  },
  paginationCenter:{
    display: "table",   
    margin: "0 auto",
    paddingTop:"20px",
    paddingBottom:"20px"
  },
  searchSize:{
    width:"100%",
    textAlign:"right",
    paddingRight:"20px",
    paddingTop:"10px",
    paddingBottom:"10px"
  },
  sortByText:{
    width: "fit-content",
    display: "inline-block",
    padding: "10px"
  },
  sortSelect:{
    marginLeft:"10px"
  },
  skeletonStyle:{
    width:"100%",
    height:"250px"
  },
  hidingGrid:{
    padding:"0px !important"
  }
}));

const handleSearchInput = (locationString:any )=>{
    const searchInput = queryString.parse(locationString,{ arrayFormat: 'indices',strictNullHandling: true, ignoreQueryPrefix: true });
  if(searchInput.pageNum) searchInput.pageNum = parseInt(searchInput.pageNum)

  return {...SearchHelper.getDefaultInput(), 
                          ...searchInput}
}
type Searchprops = {
  location:{
    hash: string,
    key: string,
    pathname: string,
    search: string,
    state:string,
},
pageNum:number

}

const Search = (props:Searchprops) => {
  const classes = useStyles();
  const { location } = props;
  const history = useHistory()

  const [searchData, setSearchData] = useState<Searchprops>(handleSearchInput(location.search))

  const { loading, data } = useQuery(gql(companySearch), {
    variables: SearchHelper.transformSearchData(searchData),
  });
  /*const { error: favorite_error, loading: favorite_loading, data: favorites } = useQuery(gql(listCompanyFavorites), {
    variables: {
        "limit": 1000,
    },
  });*/

  const onChange = (id:any,value:any) =>{
    var searchState = {}
    if (id === "pageNum"){
      searchState = {...searchData,pageNum:value}
    }else {
      searchState = {...searchData,pageNum:1,[id]: value}
    }
      var searchString = queryString.stringify(searchState,{ arrayFormat: 'indices',strictNullHandling: true })
      history.push({
        pathname:"/search",
        search: searchString
      })
      setSearchData(prevState => ({
        ...prevState,
        [id]: value,
    }));      
    
  }


  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
      <Grid item sm={4} md={3} lg={3} xl={2}  className={classes.hidingGrid}></Grid>

        <Grid item xs={12} sm={8} md={6} lg={6} xl={6} className={classes.mainHeading}>
          <Typography variant="h3" component="h2">
            Search Companies
          </Typography>
        </Grid>
      <Grid item md={3} xl={4} className={classes.hidingGrid}></Grid>
      <Grid item sm={4} md={3} lg={3} xl={2}  className={classes.hidingGrid}></Grid>

<Grid item xs={12} sm={8} md={6} lg={6} xl={6} className={classes.mainHeading}>
   {/* <SearchSave searchString={queryString.stringify(searchData,{ arrayFormat: 'indices',strictNullHandling: true })}></SearchSave>*/}
  </Grid>
<Grid item md={3} xl={4} className={classes.hidingGrid}></Grid>
        <Grid item xs={12} sm={4} md={3} lg={3} xl={2}>
          <SearchSideBar searchData={searchData} setSearchData={setSearchData} onChange={onChange}/>
        </Grid>
      
        <Grid item xs={12} sm={8} md={6} lg={6} xl={6} className={classes.noMargin}>
        
        <CompanyListBody loading={loading} data={data ? data.CompanySearch : []} page={searchData.pageNum} /*sort={searchData.sortType}*/ onChange={onChange} //favorites={favorites}
        ></CompanyListBody>
          

        </Grid>
      </Grid>
    </div>
  );
};

export default Search;
