import InputIcon from '@mui/icons-material/Input';
import MenuIcon from '@mui/icons-material/Menu';

import { AppBar, Hidden, IconButton, Menu, MenuItem, Theme, Toolbar } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
const ITEM_HEIGHT = 48;

interface PropTypes {
    className?: string,
    onSidebarOpen?: () => void
}

const useStyles = makeStyles<Theme>(theme => createStyles({
  root: {
    boxShadow: 'none',
    backgroundColor:"#FFF!important"
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  imageClass:{
    height: "34px"
  }
}));


async function signOut() {
  try {
      await Auth.signOut();
  } catch (error) {
      console.log('error signing out: ', error);
  }
}


const Topbar = (props:PropTypes) => {
  const { className, onSidebarOpen } = props;
  const [anchorEl, setAnchorEl] = React.useState<Element|null>(null);
  const open = Boolean(anchorEl);

  

  const handleClick = (event:React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  //const [notifications] = useState([]);

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            className= {classes.imageClass}
            alt="Logo"
            src="/images/logos/header.png"
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        {/* <Hidden mdDown> */}
          <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <InputIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
          <MenuItem key={"SignOut"} onClick={signOut}>
            {"Sign Out"}
          </MenuItem>
        
      </Menu>
    </div>
        {/* </Hidden> */}
        <Hidden lgUp>
          <IconButton
            color="primary"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
