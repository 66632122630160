import {
  Avatar, Button, Card, CardActions, CardContent, CardHeader, Divider,
  Grid, TextField, Theme
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useUserContextAPI } from 'components/UserContext';
import React, { SyntheticEvent, useState } from 'react';

const useStyles = makeStyles<Theme>(() => createStyles({
  root: {},
  textField: {
    width: '100%',
    marginTop: '8px'
  },
  labelRoot: {
    fontSize: '16px'
  },
  input: {
    display: 'none',
  },
  details: {
    display: 'flex'
  },
  avatar: {
    height: 132,
    width: 120,
    flexShrink: 0,
    flexGrow: 0,
    marginBottom: 12
  },
}));

interface PropTypes {
  className?: string,
}

const AccountDetails = (props: PropTypes) => {
  const { className } = props;

  const classes = useStyles();

  const { values, avatar, setAccountInfo, setProfilePicture } = useUserContextAPI();
  const [profileData, setprofileData] = useState(values);

  const onProcessFile = (e: SyntheticEvent<HTMLInputElement>): void => {
    e.preventDefault();
    if (e.currentTarget.files) setProfilePicture(e.currentTarget.files[0])
  }

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value, name } = event.target
    setprofileData({
      ...profileData,
      [name]: value
    });
  };

  const saveDetails = (e: SyntheticEvent) => {
    e.preventDefault()
    setAccountInfo({
      ...profileData
    })
  }

  return (
    <Card
      className={clsx(classes.root, className)}
    >

      <CardHeader
        title="Edit Profile"
      />
      <Divider />
      <CardContent>

        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={3}
          >
            <Avatar
              className={classes.avatar}
              src={avatar}
            />
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              onChange={onProcessFile}
              multiple
              type="file"
            />
            <label htmlFor="contained-button-file">
              <Button color="primary" component="span">
                Upload
              </Button>
            </label>
          </Grid>
          <Grid
            item
            xs={8}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={6}
              >

                <TextField
                  className={classes.textField}
                  variant="standard"
                  label="First Name"
                  id="firstName"
                  name="firstName"
                  value={profileData.firstName}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      root: classes.labelRoot
                    }
                  }}
                />
              </Grid>


              <Grid
                item
                xs={6}
              >
                <TextField
                  className={classes.textField}
                  variant="standard"
                  label="Last Name"
                  id="lastName"
                  name="lastName"
                  value={profileData.lastName}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      root: classes.labelRoot
                    }
                  }}
                />
              </Grid>


              <Grid
                item
                xs={12}
              >
                <TextField
                  className={classes.textField}
                  value={profileData.title}
                  variant="standard"
                  label="Title"
                  id="title"
                  name="title"
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      root: classes.labelRoot
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          color="primary"
          variant="contained"
          onClick={saveDetails}
        >
          Save details
        </Button>
      </CardActions>
    </Card>
  );
};

export default AccountDetails;
