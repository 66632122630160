import { useQuery } from '@apollo/react-hooks';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SearchIcon from '@mui/icons-material/Search';
import { TabPanel } from "components"
import { Grid, Tab, Tabs } from '@mui/material';
import { createStyles, makeStyles } from "@mui/styles";
import gql from 'graphql-tag';
import { leadsListQuery } from 'graphql/queries';
import React from 'react';
import { LeadsList } from "./components";
import {Theme} from '@mui/material/styles';

const useStyles = makeStyles((theme:Theme) => createStyles({
    root: {
        width: "100%",
        padding: theme.spacing(3),

    },
    tabBarRoot: {
        flexGrow: 1,
        display: 'flex',
    },
    center: {
        textAlign: "center"
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        minWidth: "350px",
        backgroundColor: theme.palette.background.paper,
        height: "fit-content",
        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        marginTop:"40px"
    },
    tabBody: {
        width: "100%"
    },
    maxWidth:{
        maxWidth:"100%"
    },
    paperRoot:{
        height: "fit-content"
    },
    mainHeading:{
        textAlign:"center",
        width:"100%",
        minWidth: 650,
        paddingBottom:"4px"
    },
}));

const TabBar = (props:any) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event:any, newValue:any) => {
        setValue(newValue);
    };
    const {loading, data } = useQuery(gql(leadsListQuery));
    
    return (
        <div className={classes.root}>
    
            <Grid container spacing={2}>
                <Grid item xs={12} >
                <Grid container >
                <Grid item xs={12} sm={12} md={10} lg={8}>

                    </Grid>
                </Grid>
                </Grid>

                <Grid item xs={12} >

                    <div className={classes.tabBarRoot}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="icon tabs example"
                            orientation="vertical"
                            variant="scrollable"
                            className={classes.tabs}

                        >
                            <Tab className={classes.maxWidth} icon={<FavoriteIcon />} label="In Network Leads" />
                            <Tab className={classes.maxWidth} icon={<SearchIcon />} label="Out of Network Leads" />
                        </Tabs>
                        <TabPanel value={value} className={classes.tabBody} index={0}>
                        <LeadsList type={"InNetwork"} data={data ? data.LeadsListQuery.innetwork : []} loading={loading}/>                        
                        </TabPanel>
                        <TabPanel value={value} className={classes.tabBody} index={1}>
                        <LeadsList type={"OutNetwork"} data={data ? data.LeadsListQuery.outnetwork : []} loading={loading}/>
                        </TabPanel>

                    </div>
                </Grid>
            </Grid>

        </div>
    );
}

export default TabBar