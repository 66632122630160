import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { CompanyTableColumns, ObjectLiteral } from "types";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: "100%"
    }
  })
);

interface PropType<T> {
    data:T[],
    headers:CompanyTableColumns<T>[],
    parentName:string
}

const GrowthTable = <T extends ObjectLiteral>(props:PropType<T>) => {
  const { data, headers, parentName } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table  aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={headers.length}>
                    {parentName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  {headers.map(header => {
                    const width = Math.floor(100 / headers.length) + "%";
                    return (
                      <TableCell align="center" style={{ width: width }}>
                        {header.name}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row , index) => {
                  return (
                    <TableRow>
                      {headers.map((header,childIndex) => {
                        const width = Math.floor(100 / headers.length) + "%";

                        return (
                          <TableCell key={`${header.id}-${index}-${childIndex}`} align="center" style={{ width: width }}>
                            {header.format ? header.format(row[header.id]) : row[header.id]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default GrowthTable;
