import { useQuery } from "@apollo/react-hooks";
import { Grid, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import gql from "graphql-tag";
import { listSavedSearchs } from "graphql/queries";
import React, { useState } from "react";
import { SearchTable } from "./components";

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      width: "100%"
    },
    mainHeading: {
      textAlign: "center",
      width: "100%",
      minWidth: 650,
      paddingBottom: "4px"
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary
    },

    noMargin: {
      marginTop: "0px !important",
      paddingTop: "0px !important"
    },

    skeletonStyle: {
      width: "100%",
      height: "250px"
    },
    hidingGrid: {
      padding: "0px !important"
    }
  })
);

const SavedSearches = () => {
  const classes = useStyles();
  const { loading, data } = useQuery(gql(listSavedSearchs), {
    variables: {
      limit: 1000
    }
  });
  const [page, setPage] = useState<number>(0);

  const handleChangePage = (event:any, newPage:number) => {
    setPage(newPage);
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.noMargin}>
        <Grid item xs={12} lg={10} className={classes.noMargin}>
          <Typography variant="h3" component="h2" className={classes.mainHeading}>
            My Saved Searches
          </Typography>
        </Grid>

        <Grid item xs={12} lg={10} className={classes.noMargin}>
          {!loading ? <SearchTable tableLength={data.listSavedSearchs.items.length} data={data.listSavedSearchs.items.slice(page * 10, (page + 1) * 10)} handleChangePage={handleChangePage} page={page} /> : ""}
        </Grid>
      </Grid>
    </div>
  );
};

export default SavedSearches;
