import React from "react";
import { Grid, Paper, Table, TableCell, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
//import { CompanyTableColumns, ObjectLiteral } from "types";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: "100%"
    }
  })
);

interface PropTypes {
  data: any;
  columns: any;
  onRowClick?: Function;
}

const RepeatTables = (props: PropTypes) => {
  const { data, columns, onRowClick, ...rest } = props;
  const classes = useStyles();

  const dataMap: any = {};
  for (const headerElem of columns) {
    const { id, name, format } = headerElem;
    dataMap[id] = {
      name,
      format,
      data: []
    };
    for (const dataElem of data) {
      var newData = dataElem[headerElem.id] ? dataElem[headerElem.id] : " ";
      dataMap[id].data.push(newData);
    }
  }

  return (
    <div className={classes.root}>
      <Grid container>
        {Object.keys(dataMap).map(column => {
          return (
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" colSpan={data.length}>
                        {dataMap[column].name}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      {...rest}
                      onClick={e => {
                        if (onRowClick) onRowClick(dataMap[column].id);
                      }}
                      tabIndex={-1}
                      key={dataMap[column].id}
                    >
                      {dataMap[column].data.map((row: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined) => {
                        const width = Math.floor(100 / data.length) + "%";
                        return (
                          <TableCell key={column} align="center" style={{ width: width }}>
                            {dataMap[column].format ? dataMap[column].format(row) : row}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default RepeatTables;
