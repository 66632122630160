import { Link, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import ShowMore from 'components/ShowMore';
import React from 'react';
import { EmployeeData } from 'types';
import ExperienceCard from '../ExperienceCard';

const useStyles = makeStyles<Theme>(theme => createStyles({
    titleHeading: {
        fontSize: theme.typography.pxToRem(15),
        marginBottom: "8px"
    },
    iconTitleHeading: {
        color: theme.palette.text.secondary,
        marginBottom: "8px"
    },
    textIconWrapper: {
        display: "inline-block",
        marginRight: "30px"
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15)
    },
    fullWidth: {
        "width": "100%"
    },
    paperRoot: {
        width: "100%",
        marginBottom: theme.spacing(2),
        textAlign: "center"

    },
    linkText: {
        fontSize: theme.typography.pxToRem(14),
        paddingRight: "5px",
        color: theme.palette.info.main
    },
    chipStyle: {
        fontSize: theme.typography.pxToRem(14),
        marginRight: "5px",
        marginBottom: "5px",
    },
    largeAvatar: {
        width: "150px",
        height: "150px",
        display: "inline-block"
    },
    iconClass: {
        paddingRight: "5px",
        color: theme.palette.text.secondary
    },
    displayRight: {
        textAlign: "right"
    }
}));

interface PropTypes {
    data:EmployeeData
}

const Jobs = (props:PropTypes) => {
    const { data } = props;
    const classes = useStyles();
    const sortedJobs = data["jobs"]
    sortedJobs.sort(function(a, b) {
        return parseInt(b.startepoch) - parseInt(a.startepoch);
    });
    return (
        <div className={classes.fullWidth}>
                    <Typography gutterBottom  variant="h4" component="h2">
                        Experience
            </Typography>
            <ShowMore data=
                    {sortedJobs.map(job => {
                        var date = job.startdate && job.startdate !=="-" && job.enddate && job.enddate !== "-" ? " - " : ""
                        if (job.startdate && job.startdate !=="-") {
                            date = job.startdate + date
                        }
                        if (job.enddate && job.enddate !== "-") {
                            date += job.enddate
                        }
                        var tags = job.companytags
                        if(job.rank >= 7){
                            tags.push("Company Size: Very Large")
                        } else if(job.rank >= 5){
                            tags.push("Company Size: Large")
                        } else if (job.rank >= 4){
                            tags.push("Company Size: Medium")
                        }
                        return (

                            <ExperienceCard data={{
                                header: job.companyrole,
                                subHeader: job.companylink && job.companylink !== "-" ? <Link color="inherit" href={job.companylink}>{job.company}</Link> : job.company,
                                subTitle1: date,
                                subTitle2: job.location,
                                description: job.description,
                                image: job.imagelink && job.imagelink !== "-" ? job.imagelink : "/images/default-corporate-image.jpg",
                                tags: job.companytags
                            }} />)
                    })} trimIndex={4}/>
        </div>
    );

};

export default Jobs;
