import { FormControl, Grid, MenuItem, Select, SelectChangeEvent, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { CodaDocs } from "types";

const useStyles = makeStyles<Theme>(theme => createStyles({
    root: {
      width: "100%"
    },
    ratingCard: {
      textAlign: "center"
    },
    opaqueReveal: {
      background: "grey",
      height: "100%",
      textAlign: "center"
    },
    revealText: {
      marginTop: "230px",
      color: "white",
      fontSize: "20px"
    },
    iconStyle: {
      color: "white",
      marginBottom: "5px"
    },
    selectCard: {
      marginLeft: "24px",
      paddingLeft: "8px",
      paddingRight: "8px"
    },
    codaRoot: {
      width: "100%",
      height: "1000px",
      borderWidth: "0px"
    }
  })
);

interface PropType {
    data:CodaDocs[]
}

function DealBrief(props:PropType) {
  const { data } = props;

  const classes = useStyles();

  const [state, setState] = React.useState({
    companyDocId: data[0].codaurl
  });

  const handleChange = (event:SelectChangeEvent) => {
    if (event.target.name === "companyDocId") {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={4} md={3}>
          <Typography gutterBottom  variant="h4" component="h2">
            Coda Documents:
          </Typography>
        </Grid>

        <Grid item xs={6} md={2} className={classes.gridRight}>
          <FormControl className={classes.formControl}>
            <Select value={state.companyDocId} onChange={handleChange} name="companyDocId" className={classes.selectCard}>
              {data.map(elem => {
                return <MenuItem value={elem.codaurl}>{elem.doctitle}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary" component="p">
            These file are read only. Any changes made in the dashboard will not be saved.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {/* eslint-disable-next-line */}
          <iframe sandbox="allow-scripts allow-same-origin allow-popups" className={classes.codaRoot} src={state.companyDocId}></iframe>
        </Grid>
      </Grid>
    </div>
  );
}

export default DealBrief;
