import SearchIcon from "@mui/icons-material/Search";
import { Input, Paper, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      borderRadius: "4px",
      alignItems: "center",
      padding: theme.spacing(1),
      display: "flex",
      flexBasis: 420
    },
    icon: {
      marginRight: theme.spacing(1),
      color: theme.palette.text.secondary
    },
    input: {
      flexGrow: 1,
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "-0.05px"
    }
  })
);
//eslint-disable-next-line
interface PropTypes {
  className?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  style: React.CSSProperties;
}

const SearchInput = (props: PropTypes) => {
  const { className, onChange, style, ...rest } = props;

  const classes = useStyles();

  return (
    <Paper {...rest} className={clsx(classes.root, className)} style={style}>
      <SearchIcon className={classes.icon} />
      <Input {...rest} className={classes.input} disableUnderline onChange={onChange} />
    </Paper>
  );
};


export default SearchInput;
