/* eslint-disable */
import React, { useState, useEffect } from 'react';
import UserContext from "components/UserContext/UserContext"
import  { Auth, Storage } from 'aws-amplify';
import { UserProfileDetails } from 'types/user';

interface PropTypes {
    children:React.ReactNode
}

const profileDetails:UserProfileDetails = {
    firstName: '',
    lastName: '',
    title: '',
};
const UserContextProvider = (props:PropTypes) => {
    const [values, setProfile] = useState<UserProfileDetails>( profileDetails );
    const [avatar ,setAvatar] = useState<string>('/images/avatars/NoProfile.png')
    const { children } = props;


    const setAccountInfo = async ({firstName, lastName, title}:UserProfileDetails) => {
        const user = await Auth.currentAuthenticatedUser()
        //eslint-disable-next-line
        const result = await Auth.updateUserAttributes(
            user,
            {
                "custom:firstName": firstName,
                "custom:lastName": lastName,
                "custom:title": title
            })
            //eslint-disable-next-line
            setProfile({
                ...values,
                ["firstName"]: firstName,
                ["lastName"]: lastName,
                ["title"]: title
            });
            
    }

    const getAccountInfo = async () => {
        try {
            const currentUserInfo = await Auth.currentUserInfo()
            const firstName = currentUserInfo.attributes['custom:firstName']
            const lastName = currentUserInfo.attributes['custom:lastName']
            const title = currentUserInfo.attributes['custom:title']
            const username = currentUserInfo.username
            setProfile({
                ...values,
                ["userName"]: username,
                ["firstName"]: firstName,
                ["lastName"]: lastName,
                ["title"]: title
            });
        } catch (err) {
            console.log('error fetching user info: ', err);
        }
    }

    useEffect(() => {
        refreshData()
      }, []);
    
    const refreshData = async () => {
        await getProfilePicture()
        await getAccountInfo()
    }
    const getProfilePicture = async () => {
        await new Promise((resolve, reject) => {
            Storage.get("profilePicture.png")
            .then((url) => {
                var request = new Request(url.toString());

                fetch(request).then(function (response) {
                    if (response.status === 200) {
                        setAvatar(url.toString());
                    }
                    resolve(response)
                });
            })
            .catch(err => resolve(err));
        });
    };

    const setProfilePicture = (file:File) => {
        let reader = new FileReader();
        try {
            reader.readAsDataURL(file);
        } catch (err) {
            console.log(err);
        }
        reader.onloadend = () => {
            reader.result && setAvatar(reader.result?.toString());
        };

        Storage.put("profilePicture.png", file, {
            contentType: "image/png"
        })
            .then(result => console.log(result))
            .catch(err => console.log(err));
    };

    return (
        <UserContext.Provider value = {{values, avatar, setAccountInfo,setProfilePicture}}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContextProvider;
