/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink, NavLinkProps } from 'react-router-dom';
import clsx from 'clsx';

import { makeStyles , createStyles } from '@mui/styles';
import { List, ListItem, Button, colors, Theme } from '@mui/material';
import {Typography , Tooltip} from '@mui/material';
import { Pages } from 'types';

interface PropsType<Pages>{
  pages:Pages[],
  className?:string,

}

const useStyles = makeStyles<Theme>(theme => createStyles({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: "center"
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    minWidth: "0px"
  },
  icon: {
    // color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  textColor:{
    color: "white"
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const CustomRouterLink = forwardRef<HTMLDivElement , NavLinkProps>((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = (props:PropsType<Pages>) => {
  const { pages, className } = props;

  const classes = useStyles();

  return (
    <List
      className={clsx(classes.root, className)}
    >
      {pages.map(page => (
        <ListItem
          className={classes.item}
          disableGutters
          key={page.title}
        >
          <Tooltip title={
            <Typography variant="body1" className={classes.textColor} >{page.title}</Typography>
          } aria-label="add" placement="right">

            <Button
              activeClassName={classes.active}
              className={classes.button}
              component={CustomRouterLink}
              to={page.href}
            >
              <div className={classes.icon}>{page.icon}</div>
            </Button>
          </Tooltip>
        </ListItem>
      ))}
    </List>
  );
};



export default SidebarNav;
