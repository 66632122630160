import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Avatar, Card, CardActionArea, CardContent, CardHeader, Grid, IconButton, Link, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import ShowMoreText from 'components/ShowMoreText';
import React from 'react';
import { Link as ReactLink } from 'react-router-dom';

const useStyles = makeStyles<Theme>((theme) => createStyles({
    rootCell: {
        maxHeight: "500px"
    },
    root: {
        width: "100%",
    },
    iconWrapper: {
        width: "100%",
        textAlign: "right"
    },
    cardHeader: {
        alignItems: "end",
        paddingBottom: "5px"
    },
    cardContentStyle: {
        paddingBottom: "0px !important",
        paddingTop: "0px !important",

    },
    avatar: {
        width: "75px",
        height: "75px",
        display: "inline-block"
    },
    indentRow: {
        paddingLeft: "91px"
    },
    typeSize: {
        fontSize: "15px"
    },
    textSpacingWrap: {
        whiteSpace: "pre-wrap"
    },
    chipStyle: {
        fontSize: theme.typography.pxToRem(14),
        marginRight: "5px",
        marginBottom: "5px",
        color: theme.palette.info.main,
        borderColor: theme.palette.info.main
        //backgroundColor: theme.palette.info.main,
        //color: theme.palette.white
    },
    chipTopWrapper: {
        marginTop: "5px"
    },
    pchip: {
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        textAlign: "center"
    },
}));

interface PropType {
    companyName:string | null,
    companyImage:string | null,
    description:string | null,
    companyID:string,
    seriesmerged:string|null,
    totalfunding: number|null
}

const CompanyCard = (props:PropType) => {
    const { companyName, companyImage, description, companyID, seriesmerged, totalfunding } = props;

    const classes = useStyles();
    const onCardClick = (event:React.MouseEvent) => {
        event.preventDefault()
        event.stopPropagation();
    }
    // const CustomLink = props => <ReactLink to={"/companies/" + companyID} {...props} />;

    return (
        <Card className={classes.root} elevation={2} square={true}>
            <CardActionArea component={ReactLink} to={"/companies/" + companyID}>

                <CardHeader className={classes.cardHeader} avatar={<Avatar className={classes.avatar} src={companyImage??""} >
                    <img className={classes.avatar} src="/images/default-corporate-image.jpg" alt="default-corporate" />
                </Avatar>
                } title={<Typography variant="h5" component="h2">
                    <Link color="inherit"
                    //component={CustomLink}
                    //onClick={onCardClick}
                    >{companyName}</Link>
                </Typography>} subheader={<div>
                    {(seriesmerged && seriesmerged !== "") && <Typography variant="body2" color="textSecondary" component="p">
                        {"Next Series : " + seriesmerged}
                    </Typography>}

                    {(totalfunding) && <Typography variant="body2" color="textSecondary" component="p" className={classes.textSpacingWrap}>
                        Total Funding :  $  {totalfunding}  million
                    </Typography>}
                </div>} />



                <Grid container>
                    <Grid item xs={12} className={classes.indentRow}>
                        <CardContent className={classes.cardContentStyle}>

                            {/* {description && <Typography>{description}</Typography> != "-" ?  : ""} */}
                            {description && <Typography><ShowMoreText data={description} trimIndex={300}></ShowMoreText> </Typography>}

                        </CardContent>
                    </Grid>

                    <Grid item xs={6} >
                        {/* <FavoriteButton isFavorite={isFavorite} userID={userID} id={companyID}></FavoriteButton> */}
                    </Grid>
                    <Grid item xs={6} >
                        <div className={classes.iconWrapper}>
                            <IconButton onClick={onCardClick}>

                                <NavigateNextIcon />
                            </IconButton>
                        </div>
                    </Grid>

                </Grid>

            </CardActionArea>

        </Card>
    );
};
export default CompanyCard;
