import { useLazyQuery } from "@apollo/react-hooks";
import Add from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Avatar, Box, Button, Chip, Grid, IconButton, Link, Stack, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { GridColDef } from "@mui/x-data-grid";
import gql from "graphql-tag";
import { getPortfolioList } from "graphql/queries";
import { isEmpty } from "lodash";
import React from "react";
import Moment from "react-moment";
import { toast } from "react-toastify";
import { Investors, portfolioData, WeeklyLead } from "types";
import { AddEdiPortfolioFrom } from "./components";
import { RenegadeProcess } from "./components/RenegradeProcess";
import { WeeklyLeads } from "./components/WeeklyLeedsNew/WeeklyLeads";

/**
 * @description Lead list table column definition list
 */
const leadcolumns: GridColDef[] = [
  {
    field: "companyname",
    headerName: "Company Name",
    minWidth: 200,
    flex: 0.5,
    renderCell: params => (
      <Stack direction={"row"} spacing={1} alignItems="center">
        <Avatar
          sx={{ width: "24px", height: "24px" }}
          src={"//logo.clearbit.com/" + params.row.companyurl}
          variant="rounded"
          sizes="small"
          imgProps={{
            onError: (e: React.SyntheticEvent<HTMLImageElement>) => (e.currentTarget.src = "/images/default-corporate-image.jpg")
          }}
        />
        <Typography variant="subtitle1" sx={{ fontSize: "11px" }} component={Link} href={"/companies/" + params.row.uuid} target="_blank">
          {params.row.companyname}
        </Typography>
      </Stack>
    )
  },
  {
    field: "dategenerated",
    headerName: "Date Generated",
    minWidth: 130,
    flex: 0.5,
    type: "string",
    renderCell: params => (
      <Typography>
        <Moment format="L">{params.row.dategenerated}</Moment>
      </Typography>
    )
  },
  {
    field: "totalraised",
    headerName: "Total Raised(in $ million)",
    minWidth: 150,
    type: "number",
    align: "center",
    flex: 0.24,
    renderCell: params => <Typography>{params.row.totalraised}</Typography>
    // valueGetter: (params: GridValueGetterParams) => `$ ${params.row.totalraised} million`
  },
  {
    field: "lastround",
    headerName: "Last Round",
    type: "string",
    minWidth: 120,
    flex: 0.24,
    renderCell: params => <Typography>{params.row.lastround}</Typography>
  },
  {
    field: "lastrounddate",
    headerName: "Last Round Date",
    type: "date",
    width: 160,
    flex: 0.3,
    renderCell: params => (
      <Typography>
        <Moment format="L">{params.row.lastrounddate}</Moment>
      </Typography>
    )
  },
  {
    field: "investors",
    headerName: "Investors",
    type: "number",
    minWidth: 150,
    flex: 0.25,
    renderCell: params => <Typography>{params.row.investors}</Typography>
  },
  {
    field: "interesting",
    headerName: "Interesting?",
    type: "boolean",
    editable: true,
    width: 130
  },
  {
    field: "employeegrowth",
    headerName: "Employee Growth",
    type: "number",
    minWidth: 130,
    flex: 0.35,
    renderCell: params => <Typography>{params.row.employeegrowth}</Typography>
  }
];

/**
 * @description Portfolio table column definition list
 */
const portfolioColumns = (handleSaveClick: Function): GridColDef[] => [
  {
    field: "companyname",
    headerName: "Company Name",
    width: 200,
    renderCell: params => (
      <Stack direction={"row"} spacing={1} alignItems="center">
        <Avatar
          sx={{ width: "24px", height: "24px" }}
          src={"//logo.clearbit.com/" + params.row.companyurl}
          variant="rounded"
          sizes="small"
          imgProps={{
            onError: (e: React.SyntheticEvent<HTMLImageElement>) => (e.currentTarget.src = "/images/default-corporate-image.jpg")
          }}
        />
        {params.row.uuid ? (
          <Typography variant="subtitle1" sx={{ fontSize: "11px" }} component={Link} href={"/companies/" + params.row.uuid} target="_blank">
            {params.row.companyname}
          </Typography>
        ) : (
          <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
            {params.row.companyname}
          </Typography>
        )}
      </Stack>
    )
  },
  {
    field: "initialinvestment",
    headerName: "Initial Investment",
    minWidth: 120,
    flex: 0.2,
    type: "date",
    renderCell: params => (
      <Typography>
        <Moment format="L">{params.row.initialinvestment}</Moment>
      </Typography>
    )
  },
  {
    field: "cost",
    headerName: "Cost (In $ million)",
    width: 130,
    // editable: true,
    type: "number",
    align: "center"
    // valueGetter: (params: GridValueGetterParams) => `$ ${params.row.cost} million`
  },
  {
    field: "fmv",
    headerName: "FMV(in $ million)",
    // editable: true,
    type: "number",
    width: 150,
    align: "center"
    // valueGetter: (params: GridValueGetterParams) => `$ ${params.row.fmv} million`
  },
  {
    field: "lastround",
    headerName: "Last Round",
    type: "string",
    width: 120
  },
  {
    field: "coinvestors",
    headerName: "Co-Investor",
    sortable: false,
    // editable: true,
    renderCell: params => (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          maxHeight: "100px",
          overflowY: "auto"
        }}
      >
        {params.row.coinvestors && params.row.coinvestors.map((item: Investors, index: number) => <Chip size="small" key={`co-inves${index}`} sx={{ margin: "2.5px 2.5px 2.5px 0" }} label={item.investor} variant="outlined" />)}
      </Box>
    ),
    minWidth: 370,
    flex: 0.85
  },
  {
    field: "ceo",
    headerName: "CEO",
    // align:"center",
    width: 140,
    renderCell: params => {
      if (params.row.ceo?.linkedinUrl)
        return (
          <Typography variant="subtitle1" component={Link} href={params.row.ceo?.linkedinUrl} target="blank">
            {params.row.ceo?.name}
          </Typography>
        );
      return <Typography variant="subtitle1">{params.row.ceo?.name}</Typography>;
    }
  },
  {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    width: 100,
    cellClassName: "actions",
    renderCell: params => {
      return (
        <IconButton onClick={() => handleSaveClick(isEmpty(params.row.uuid) ? params.row.companyname : params.row.uuid)}>
          <EditIcon />
        </IconButton>
      );
    }
  }
];

/***
 *@description Dashboard component for landing UI after login
 */
const Dashboard = () => {
  //Api call
  // const [queryWeeklyLeads, { data: weeklyLeadsData, loading: weeklyLeadsLoading }] = useLazyQuery<{ weeklyLeadsListQuery: { count: number; result: WeeklyLead[] } }>(gql(weeklyLeadsListQuery));
  const [getPortfolio, { data: getPortfolioListResponse, loading: portfolioLoading, error: getPortfolioListError }] = useLazyQuery<{ getPortfolioList: { result: portfolioData[]; count: number; message: string } }>(gql(getPortfolioList));

  //data state
  const [tableData, setData] = React.useState<{
    weeklyCount: number;
    portfolioCount: number;
    weeklyData: WeeklyLead[];
    portfolioData: portfolioData[];
  }>({
    weeklyCount: 0,
    portfolioCount: 0,
    weeklyData: [],
    portfolioData: []
  });

  // Hook for respond after successful data fetch from graphql-api
  // useEffect(() => {
  //   if (weeklyLeadsData?.weeklyLeadsListQuery?.result && weeklyLeadsData?.weeklyLeadsListQuery?.count)
  //     setData(old => ({
  //       ...old,
  //       weeklyData: weeklyLeadsData?.weeklyLeadsListQuery.result.map(d => ({ ...d, id: d.uuid })),
  //       weeklyCount: weeklyLeadsData?.weeklyLeadsListQuery.count
  //     }));
  // }, [weeklyLeadsData]);

  React.useEffect(() => {
    // console.log(getPortfolioListResponse);
    if (getPortfolioListResponse?.getPortfolioList.result && getPortfolioListResponse?.getPortfolioList.count)
      setData(old => ({
        ...old,
        portfolioCount: getPortfolioListResponse?.getPortfolioList.count,
        // @ts-ignore
        portfolioData: getPortfolioListResponse?.getPortfolioList.result.map(d => ({ ...d, id: d.uuid }))
      }));

    if (getPortfolioListResponse?.getPortfolioList.message) toast.info(getPortfolioListResponse?.getPortfolioList.message);
  }, [getPortfolioListResponse]);

  //Popover for add new data from in Our portfolio
  const [portfolioFormStatus, setPortfolioFormStatus] = React.useState<{
    id: string;
    mode: "add" | "edit";
    open: boolean;
  }>({
    id: "",
    mode: "add",
    open: false
  });

  /**
   * @description Add new button click handler in the Portfolio component
   */
  const handleAddNewPortfolioData = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPortfolioFormStatus(prev => ({
      ...prev,
      mode: "add",
      open: true
    }));
  };

  /**
   * @description Add new portfolio form popover handler
   */
  const addNewDataPopoverCloseHandler = () => {
    setPortfolioFormStatus({
      mode: "add",
      open: false,
      id: ""
    });
  };

  const addNewPortfolioId = portfolioFormStatus ? "add-new-portfolio-item-form" : undefined;

  //handle Edit click
  const handleEditClick = (id: string) => {
    setPortfolioFormStatus({
      id,
      mode: "edit",
      open: true
    });
  };

  //handle add edit form submit result
  const handleResult = (data: portfolioData[], count: number) => {
    setData(prevState => ({
      ...prevState,
      portfolioCount: count,
      portfolioData: [...data.map(d => ({ ...d, id: d.uuid }))]
    }));
  };

  //Error handlers
  React.useEffect(() => {
    if (getPortfolioListError) toast.error(getPortfolioListError.message);
  }, [getPortfolioListError]);

  return (
    <React.Fragment>
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xl={3} lg={12} md={12} sm={12}>
          <RenegadeProcess />
        </Grid>
        <Grid item xl={9} lg={12} md={12} sm={12}>
          <WeeklyLeads totalCount={tableData.weeklyCount} title="Weekly Leads" columns={leadcolumns}  rows={[]}  />
          <WeeklyLeads
            totalCount={tableData.portfolioCount}
            title="Our Portfolio"
            columns={portfolioColumns(handleEditClick)}
            loading={portfolioLoading}
            rows={tableData.portfolioData.sort()}
            fetchDataForPage={getPortfolio}
            headingAction={
              <Button startIcon={<Add />} size="small" variant="outlined" onClick={handleAddNewPortfolioData}>
                Add new
              </Button>
            }
          />
        </Grid>
      </Grid>
      <Dialog
        id={addNewPortfolioId}
        open={portfolioFormStatus.open}
        //</React.Fragment>onClose={addNewDataPopoverCloseHandler}
      >
        <DialogTitle>Add new portfolio company</DialogTitle>
        <AddEdiPortfolioFrom mode={portfolioFormStatus.mode} editData={!isEmpty(portfolioFormStatus.id) ? tableData.portfolioData.find(d => d.uuid === portfolioFormStatus.id || d.companyname === portfolioFormStatus.id) : undefined} onClose={addNewDataPopoverCloseHandler} onResult={handleResult} />
      </Dialog>
    </React.Fragment>
  );
};

export default Dashboard;
