/* eslint-disable */
import DateFnsUtils from '@date-io/date-fns';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DatePicker as KeyboardDatePicker, LocalizationProvider as MuiPickersUtilsProvider  } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Accordion as ExpansionPanel, AccordionDetails as ExpansionPanelDetails, AccordionSummary as ExpansionPanelSummary, Autocomplete, Checkbox, FormControlLabel, Grid, Slider, TextField, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import MultipartItem from '../MultipartItem';





const useStyles = makeStyles<Theme>((theme) => createStyles({
    root: {
        width: '100%',
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    details: {
        alignItems: 'center',
        width: '100%',
    },
    textField: {
        width: '100%',
        marginTop: '16px'
    },
    textFieldSmall: {
        width: '100%',
        marginTop: '8px'
    },
    labelRoot: {
        fontSize: '19px'
    },
    labelRootExternal: {
        transform: "translate(0, 1.5px) scale(0.75)",
        transformOrigin: "top left",
        fontSize: "19px",
        marginTop: '16px'
    },
    labelRootSmall: {
        fontSize: '17px'
    },
    labelRootExternalSmall: {
        transform: "translate(0, 1.5px) scale(0.75)",
        transformOrigin: "top left",
        fontSize: "17px",
        marginTop: '8px',
        textAlign: "left"
    },
    expansionPanelStyle: {
        root: {
            boxShadow: "none"
        }
    },
    indentItems: {
        marginLeft: "40px",
        marginRight: "40px",
        textAlign: "center"
    },
    divider: {
        backgroundColor: "#494A4E",
        marginTop: "8px",
        marginBottom: "8px"
    },
    buttonStyle: {
        marginBottom: "8px"
    },
    chipStyle: {
        fontSize: theme.typography.pxToRem(12),
        marginRight: "5px",
        marginBottom: "5px",
    },
}));
const marks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 25,
        label: '25',
    },
    {
        value: 50,
        label: '50',
    },
    {
        value: 75,
        label: '75',
    },
    {
        value: 100,
        label: '100',
    },
];

const marksShort = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 5,
        label: '5',
    },
    {
        value: 10,
        label: '10',
    },
    {
        value: 15,
        label: '15',
    },
    {
        value: 20,
        label: '20',
    },
];

interface PropTypes {
    className?:string,
    onChange:Function,
    title?:string,
    items:any[],
    values?:any,
    isOpen?:boolean,
    onAddItem?:Function,
    errorsStates?:Function,
    tags?:[],
    onGenerateOutputWrapper?:Function,
    errorStates?:any
}

const SearchSideBar = (props:PropTypes) => {
    const classes = useStyles();
    const { onChange, title, items, values, isOpen, onAddItem, errorStates, tags, onGenerateOutputWrapper } = props;

    return (
        <div className={classes.root}>
            <MuiPickersUtilsProvider dateAdapter={AdapterDateFns}>

                <ExpansionPanel elevation={0} defaultExpanded={isOpen}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header">
                        <Typography variant="h5" component="h2">
                            {title}
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.details}>
                        <Grid container spacing={1}>
                            {items.map(item => {
                                if (item.type === "DATE") {
                                    return (<Grid item xs={12}> <KeyboardDatePicker
                                        inputFormat="MM/dd/yyyy"
                                        //margin="normal"
                                        //id={item.id}
                                        label={item.label}
                                        clearable={true}
                                        value={values[item.id]}
                                        className={classes.textField}
                                        onChange={(value) => onChange(item.id, value)}
                                        OpenPickerButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        InputAdornmentProps={{
                                            //shrink: true,
                                            classes: {
                                                root: classes.labelRoot
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                        {item.checkBox && 
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values[item.checkBox.id]}
                                                    onChange={(event) => onChange(item.checkBox.id, event.target.checked)}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                            }
                                            label={item.checkBox.label}
                                            className={classes.labelRoot}
                                        />}
                                    </Grid>)
                                } else if (item.type === "MULTI") {
                                    return (<Grid item xs={12}>
                                        <Autocomplete
                                            multiple={item.isMultiple}
                                            options={item.data}
                                            onChange={(e, v) => onChange(item.id, v)}
                                            id={item.id}
                                            value={values[item.id]}
                                            getOptionLabel={(option) => option}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className={classes.textField}
                                                    value={ item.autoCompleteValue ?  item.autoCompleteValue : undefined  }
                                                    onChange={ item.onAutoCompleteChange ? (e) => item.onAutoCompleteChange(e.target.value) : undefined }

                                                    variant="standard"
                                                    label={item.label}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        classes: {
                                                            root: classes.labelRoot
                                                        }
                                                    }}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password',
                                                    }}
                                                />
                                            )}
                                        /> </Grid>
                                    )
                                } else if (item.type === "FREE") {
                                    return (<Grid item xs={12}>
                                        <Autocomplete
                                            multiple
                                            freeSolo
                                            autoSelect
                                            options={[]}
                                            onChange={(e, v) => onChange(item.id, v)}
                                            id={item.id}
                                            value={values[item.id]}
                                            getOptionLabel={(option) => option}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className={classes.textField}
                                                    variant="standard"
                                                    label={item.label}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        classes: {
                                                            root: classes.labelRoot
                                                        }
                                                    }}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password',
                                                    }}
                                                />
                                            )}
                                        /> </Grid>
                                    )
                                } else if (item.type === "SLIDER") {
                                    return (<Grid item xs={12}>
                                        <Typography className={classes.labelRootExternal} >
                                            {item.label}
                                        </Typography>
                                        <Slider
                                            //value={values[item.id]}
                                            //onChange={(e, v) => onChange(item.id, v)}
                                            onChangeCommitted={(e, v) => onChange(item.id, v)}
                                            defaultValue={values[item.id]}
                                            valueLabelDisplay="auto"
                                            marks={item.short ? marksShort : marks}
                                            max={item.short ? 20 : 100}
                                        />
                                    </Grid>
                                    )
                                } else if (item.type === "SLIDER_SELECT") {
                                    return (
                                        <Grid item xs={12}>
                                            {item.Select3 ? <MultipartItem id={item.id} label={item.label} Select1={item.Select} Select2={item.Select2} Select3={item.Select3} Slide={item.Slide} generateOutput={item.generateOutput} generateTags={item.generateTags} value={values[item.id]} onValueChange={onChange} /> :
                                                <MultipartItem id={item.id} label={item.label} Select1={item.Select} Select2={item.Select2} Slide={item.Slide} generateTags={item.generateTags} generateOutput={item.generateOutput} value={values[item.id]} onValueChange={onChange} />}
                                        </Grid>)
                                } else if (item.type === "CHECKBOX") {
                                    return (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values[item.id]}
                                                    onChange={(event) => onChange(item.id, event.target.checked)}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                            }
                                            label={item.label}
                                            className={classes.labelRoot}
                                        />)
                                } else {
                                    return (
                                        <Grid item xs={12}>

                                            <TextField
                                                className={classes.textField}
                                                variant="standard"
                                                id={item.id}
                                                value={values[item.id]}
                                                onChange={e => onChange(e.target.id, e.target.value)}
                                                label={item.label}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        root: classes.labelRoot
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    )
                                }
                            })}
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </MuiPickersUtilsProvider>
        </div>
    );
}

SearchSideBar.defaultProps = {
    tags: []
}
export default SearchSideBar