import { TableBody,  TableRow } from "@mui/material";
import {  CompanyTableColumns, ObjectLiteral } from "types";
import React from "react";

interface PropType<T> {
  data: T[],
  columns: CompanyTableColumns<T>[],
  renderRecords:(d:T, column:CompanyTableColumns<T>) =>  React.ReactNode,
  onRowClick?: (data:T) => void;
}

const CustomTableBody = <T extends ObjectLiteral>(props: PropType<T>) => {
  
  const { data, columns, onRowClick , renderRecords } = props;

  return (
    <TableBody>
      {data &&
        data.map((d, index) => {
          return (
            <TableRow
              onClick={() => onRowClick && onRowClick(d)}
              tabIndex={-1}
              key={index}
            >
              {columns.map(column => renderRecords(d , column))}
            </TableRow>
          );
        })}
    </TableBody>
  );
};

export default CustomTableBody;
