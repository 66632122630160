import { Link, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import ShowMore from "components/ShowMore";
import React from "react";
import { EmployeeData } from "types";
import ExpandingSection from "../ExpandingSection";
import ExperienceCard from "../ExperienceCard";

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    titleHeading: {
      fontSize: theme.typography.pxToRem(15),
      marginBottom: "8px"
    },
    iconTitleHeading: {
      color: theme.palette.text.secondary,
      marginBottom: "8px"
    },
    textIconWrapper: {
      display: "inline-block",
      marginRight: "30px"
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15)
    },
    fullWidth: {
      width: "100%"
    },
    paperRoot: {
      width: "100%",
      marginBottom: theme.spacing(2),
      textAlign: "center"
    },
    linkText: {
      fontSize: theme.typography.pxToRem(14),
      paddingRight: "5px",
      color: theme.palette.info.main
    },
    chipStyle: {
      fontSize: theme.typography.pxToRem(14),
      marginRight: "5px",
      marginBottom: "5px"
    },
    largeAvatar: {
      width: "150px",
      height: "150px",
      display: "inline-block"
    },
    iconClass: {
      paddingRight: "5px",
      color: theme.palette.text.secondary
    },
    displayRight: {
      textAlign: "right"
    }
  })
);

interface PropType {
  className?: string;
  data: EmployeeData;
}

const Publications = (props: PropType) => {
  const { data } = props;
  const classes = useStyles();
  const publicationData = (
    <ShowMore
      data={data["publications"].map(publication => {
        return (
          <ExperienceCard
            data={{
              header:
                publication.publicationlink && publication.publicationlink !== "-" ? (
                  <Link color="inherit" href={publication.publicationlink}>
                    {publication.title}
                  </Link>
                ) : (
                  publication.title
                ),
              subHeader: publication.publisher,
              subTitle1: publication.publicationdate,
              description: publication.description
            }}
          />
        );
      })}
      trimIndex={3}
    />
  );
  return (
    <div className={classes.fullWidth}>
      <ExpandingSection title="Publications" itemIndex={"title"} data={data["publications"]} fullBody={publicationData} />
    </div>
  );
};

export default Publications;
