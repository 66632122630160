import { useQuery } from "@apollo/react-hooks";
import { Grid, Skeleton, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import gql from "graphql-tag";
import { companyUUIDQuery } from "graphql/queries";
import React from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { PageState } from "types";
import CompanyHeader from "./components/CompanyHeader";
import TabBar from "./components/TabBar";

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      width: "100%"
    },
    content: {
      marginBottom: theme.spacing(2)
    },
    rootSpinner: {
      left: "50%",
      position: "fixed"
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`
    },
    skeletonStyle: {
      marginLeft: "15%",
      marginRight: "15%"
    },
    circleSkeleton: {
      marginLeft: "15%"
    },
    wrapper: {
      width: "100%",
      textAlign: "right"
    },
    gridNoMargin: {
      marginBottom: "0px",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginTop: "0px"
    }
  })
);

const queryString = require("qs");

const Company = () => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();

  const routeInput = queryString.parse(location.search, {
    arrayFormat: "indices",
    strictNullHandling: true,
    ignoreQueryPrefix: true
  });
  const pageValue = routeInput.pathValue ? parseInt(routeInput.pathValue) : 0;
  const seniority = routeInput.seniority ? routeInput.seniority : "All";
  const currentEmployees = routeInput.currentEmployees
    ? parseInt(routeInput.currentEmployees)
    : 0;
  const offsetEmployee = routeInput.offsetEmployee
    ? parseInt(routeInput.offsetEmployee)
    : 0;

  const classes = useStyles();

  const { loading, data } = useQuery(gql(companyUUIDQuery), {
    variables: {
      CompanyUUID: id
    }
  });

  const onPageChange = (pageState: PageState) => {
    var searchString = queryString.stringify(pageState, {
      arrayFormat: "indices",
      strictNullHandling: true
    });
    history.push({
      pathname: "/companies/" + id,
      search: searchString
    });
  };
  if (
    (!loading && data === undefined) ||
    (!loading && data.CompanyUUIDQuery.hashkey != null)
  ) {
    return <Redirect to="/not-found" />;
  }
  const header = !loading ? (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.gridNoMargin}></Grid>
      <Grid item xs={12}>
        {loading ? (
          <div>
            <Skeleton
              className={classes.circleSkeleton}
              variant="circular"
              width={200}
              height={200}
            />
            <Skeleton
              variant="circular"
              className={classes.skeletonStyle}
              height={200}
            />
          </div>
        ) : (
          <CompanyHeader data={data.CompanyUUIDQuery}></CompanyHeader>
        )}
      </Grid>

      <Grid item xs={12}>
        {loading ? (
          <div></div>
        ) : (
          <TabBar
            data={data.CompanyUUIDQuery}
            value={pageValue}
            seniority={seniority}
            offsetEmployee={offsetEmployee}
            currentEmployees={currentEmployees}
            handleChange={onPageChange}
          ></TabBar>
        )}
      </Grid>
    </Grid>
  ) : (
    <div></div>
  );

  return <div className={classes.root}>{header}</div>;
};

export default Company;
