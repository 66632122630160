import { makeStyles, createStyles } from "@mui/styles";
import { Avatar, Card, CardActionArea, CardContent, CardHeader, Grid, Theme, Typography } from "@mui/material";
import React from "react";
import { Link as ReactLink } from "react-router-dom";

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      width: "100%"
    },
    avatar: {
      height: "auto",
      width: "100%",
      maxWidth: "100px"
    },
    iconWrapper: {
      width: "100%",
      textAlign: "right"
    },
    cardContentStyle: {
      paddingBottom: "0px !important",
      paddingTop: "0px !important"
    },
    smallAvatar: {
      height: "40px",
      width: "40px"
    },
    fillerDiv: {
      height: "53px"
    }
  })
);

interface PropsType {
  employeeName:string,
  employeeImage:string,
  employeeTitle:string,
  employeeTags:React.ReactNode,
  employeeStartDate:string,
  employeeEndDate:string,
  employeeID:string,
}

function EmployeeCard(props:PropsType) {
  const { employeeName, employeeTitle, employeeTags, employeeStartDate, employeeEndDate, employeeImage, employeeID } = props;

  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={2}>
      <CardActionArea component={ReactLink} to={`/employees/${employeeID}`}>
        <CardHeader
          avatar={
            <Avatar alt="No Image" src={employeeImage && employeeImage !== "-" ? employeeImage : "/images/avatars/NoProfile.png"}>
              {<img className={classes.smallAvatar} src="/images/avatars/NoProfile.png" alt="NoProfile" />}
            </Avatar>
          }
          title={
            <Typography variant="h5" component="h2">
              {employeeName}
            </Typography>
          }
          subheader={
            <div>
              <Typography variant="body2" color="textSecondary" component="p">
                {employeeTitle}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {employeeStartDate && employeeStartDate !== "Present" ? "Start Date: " + employeeStartDate : "No Start or End Date"}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {employeeEndDate && employeeEndDate !== "Present" ? "End Date: " + employeeEndDate : ""}
              </Typography>
            </div>
          }
        />

        <Grid container>
          <Grid item xs={12}>
            <CardContent className={classes.cardContentStyle}>{employeeTags}</CardContent>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}

export default EmployeeCard;
