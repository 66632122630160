import { FormControl, Grid, MenuItem, Select, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    width: "100%",
    padding: theme.spacing(3)
  },
  mainHeading: {
    textAlign: "center"
  },
  hidingGrid: {
    padding: "0px !important"
  },
  iframeRoot: {
    width: "100%",
    height: "1000px",
    borderWidth: "0px"
  },
  formControl: {
    width: "100%",
    marginTop: "8px"
  }

}));


const InvestmentForms = (props:any) => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    formId: "https://docs.google.com/forms/d/e/1FAIpQLSccy9txrsARbsNarH0FXxOXIGDfU22mePlxIVQ75bQDCRzB6g/viewform?embedded=true"
  });

  const handleChange = (event:any) => {
    if (event.target.name === "formId") {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={3} sm={3} className={classes.hidingGrid}></Grid>

        <Grid item xs={6} className={classes.mainHeading}>
          <Typography variant="h3" component="h2">
            Investment Forms
          </Typography>
        </Grid>
        <Grid item xs={3} md={2} className={classes.hidingGrid}>
          <FormControl className={classes.formControl}>
            <Select value={state.formId} onChange={handleChange} name="formId" className={classes.selectCard}>
              <MenuItem value={"https://docs.google.com/forms/d/e/1FAIpQLSccy9txrsARbsNarH0FXxOXIGDfU22mePlxIVQ75bQDCRzB6g/viewform?embedded=true"}>Scorecard A</MenuItem>
              <MenuItem value={"https://docs.google.com/forms/d/e/1FAIpQLSfnuGMdUYJyBvX9_LmefReE-aWOvFa1C38kqezKQxp6OR-y7g/viewform?embedded=true"}>Scorecard B</MenuItem>
              <MenuItem value={"https://docs.google.com/forms/d/e/1FAIpQLSc1TX4AZeD9UEpiiAB-TMFhRNVvqbGcueb4ussdXJRe6Pj5lQ/viewform?embedded=true"}>Scorecard C</MenuItem>
              <MenuItem value={"https://docs.google.com/forms/d/e/1FAIpQLSc0pDocuRTwStqDHNMMOwRQEa2hNkiF6dIpfHjpm5s0UsuV-w/viewform?embedded=true"}>Portfolio Review Partner Scorecard</MenuItem>
              <MenuItem value={"https://docs.google.com/forms/d/e/1FAIpQLSfQrtslQlfSuYjY5G0niQWEswqzUgVaKa2v3oWymAXuFg89mQ/viewform?embedded=true"}>Monday Morning Form</MenuItem>
              <MenuItem value={"https://docs.google.com/forms/d/e/1FAIpQLSeZVCJeROivP1mW4TmlDNWN-_uO4nAzhJFGSnb8wR9MenhiSQ/viewform?embedded=true"}>Portfolio Stack Ranking Form</MenuItem>
              <MenuItem value={"https://docs.google.com/forms/d/e/1FAIpQLSfB7NodfoTJ3U6GjtXDDGVejNgayoeOdR0Hs6fWMVxB7JImSQ/viewform?embedded=true"}>Portfolio Expected Value Form</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {/* eslint-disable-next-line */}
          <iframe sandbox="allow-forms allow-scripts allow-same-origin allow-popups" className={classes.iframeRoot} src={state.formId}></iframe>
        </Grid>
      </Grid>
    </div>
  );
};

export default InvestmentForms;
