import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SearchIcon from '@mui/icons-material/Search';
import { Divider, Drawer, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import { Pages } from 'types';
import { Profile, SidebarNav } from './components';

interface PropTypes{
  className?: string,
  onClose?: () => void,
  open: boolean,
  variant: "permanent"|"persistent"|"temporary"
};


const useStyles = makeStyles<Theme>(theme => createStyles({
  drawer: {
    width: 80,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const SidebarMini = (props:PropTypes) => {
  const { open, variant, onClose, className } = props;

  const classes = useStyles();

  const pages:Pages[] = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />,

    },
    {
      title: 'Search',
      href: '/Search',
      icon: <SearchIcon />
    },
    {
      title: 'Investment Forms',
      href: '/investmentforms',
      icon: <FormatListBulletedIcon />
    },
    {
      title: 'Weekly Leads',
      href: '/leads',
      icon: <AssessmentIcon />
    },
    {
      title: 'Account',
      href: '/account',
      icon: <AccountBoxIcon />
    },
    // {
    //   title: 'Editable View',
    //   href: '/editable-view',
    //   icon: <TableViewIcon />
    // },
    // {
    //   title:"Kanban View",
    //   href:"/kanban-view",
    //   icon:<BallotIcon />
    // }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};


export default SidebarMini;
