import { Grid, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

import { AccountDetails, ChangePassword } from "./components";


const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      padding: theme.spacing(4)
    }
  })
);

const Settings = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={8} md={8} xl={8} xs={12}>
          <AccountDetails />
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={12}>
          {/* {currentUserEmail && wlEmailsArray?.includes(currentUserEmail) ? (
            <Button component={Link} to={"admin-operation"} size="large">
              Admin Operation
            </Button>
          ) : null} */}
        </Grid>
        <Grid item lg={6} md={6} xl={6} xs={12}>
          <ChangePassword></ChangePassword>
        </Grid>
      </Grid>
    </div>
  );
};

export default Settings;
