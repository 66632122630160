import FavoriteIcon from "@mui/icons-material/Favorite";
import SearchIcon from "@mui/icons-material/Search";
import { TabPanel } from "components";
import { Grid, Tab, Tabs, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { Favorites, SavedSearches } from "./components";

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      width: "100%",
      padding: theme.spacing(3)
    },
    tabBarRoot: {
      flexGrow: 1,
      display: "flex"
    },
    center: {
      textAlign: "center"
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
      minWidth: "350px",
      backgroundColor: theme.palette.background.paper,
      height: "fit-content",
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
      marginTop: "40px"
    },
    tabBody: {
      width: "100%"
    },
    maxWidth: {
      maxWidth: "100%"
    },
    paperRoot: {
      height: "fit-content"
    },
    mainHeading: {
      textAlign: "center",
      width: "100%",
      minWidth: 650,
      paddingBottom: "4px"
    }
  })
);
const TabBar = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState<number>(0);

  const handleChange = (event:any , newValue:number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classes.tabBarRoot}>
            <Tabs value={value} onChange={handleChange} variant="fullWidth" indicatorColor="primary" textColor="primary" aria-label="icon tabs example" orientation="vertical" className={classes.tabs}>
              <Tab className={classes.maxWidth} icon={<FavoriteIcon />} label="COMPANY FAVORITES" />
              <Tab className={classes.maxWidth} icon={<SearchIcon />} label="SAVED SEARCHES" />
            </Tabs>
            <TabPanel value={value} className={classes.tabBody} index={0}>
              <Favorites />
            </TabPanel>
            <TabPanel value={value} className={classes.tabBody} index={1}>
              <SavedSearches />
            </TabPanel>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default TabBar;
