import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme:Theme) => createStyles({
    root: {
        width:"100%",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    labelRoot: { 
        fontSize: '16px'
      },
  }));

const SearchSort = (props:any) => {
  const classes = useStyles();

  const { value, onChange } = props; 

  return (
      <div className={classes.root}>
          <FormControl className={classes.formControl}>
        <InputLabel className= {classes.labelRoot} id="sort-label">Sort By</InputLabel>
          <Select
          labelId="sort-label"
          id="sort"
          color="primary"
          onChange = {e => onChange("sort", e.target.value)}
          value = {value}
        >
          <MenuItem value={"relevance"}><em>Relevance</em></MenuItem>
          <MenuItem value={"alphabetical"}><em>Alphabetical</em></MenuItem>
          <MenuItem value={"creation_new"}><em>Newest</em></MenuItem>
          <MenuItem value={"creation_old"}><em>Oldest</em></MenuItem>

        </Select>
        </FormControl>
        </div>
  );
}
export default SearchSort;

